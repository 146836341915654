<div class="calendar container mx-auto" [ngClass]="frontMode ? 'bg-white px-6 py-4 rounded-xl shadow-sm border-2 border-lightGray2': ''">
  <div class="text-center font-[400] text-darkGray text-lg mb-3" [ngClass]="(frontMode || quadrantMode) && changeMonth ? 'flex justify-between' : 'flex gap-2 justify-center items-center'">
    <button (click)="prevMonth.emit()" *ngIf="changeMonth" class="text-primary">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
    </button>
    <p class="flex gap-4">
      <span><span>{{ monthNames[month-1] }} </span><span >{{year}}</span></span>
      <ng-content select="[afterMonth]"></ng-content>
    </p>
    <button (click)="nextMonth.emit()" *ngIf="changeMonth" class="text-primary">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
    </button>
  </div>
  <div class="week-days grid grid-cols-7 gap-0 mb-4" [ngClass]="frontMode ? 'border-b border-lightGray pb-2' : ''">
    <div *ngFor="let day of weekDays" class="day-name text-center text-lightGray text-sm font-bold" [ngClass]="frontMode ? 'font-[400]' : 'font-bold'">{{ day }}</div>
  </div>
  <app-loader size="large" *ngIf="loading"></app-loader>
  <div  *ngIf="!loading" class="weeks mt-2 grid grid-cols-7" [ngClass]="frontMode ? 'gap-2' : quadrantMode ? 'border-t border-l border-lightGray2' : 'gap-0'">
    <div *ngFor="let week of days" class="week contents">
      <ng-container  *ngFor="let day of week">
        <div *ngIf="!quadrantMode"
          [ngClass]="frontMode && day.dayOfMonth != 0 ? 'min-w-8 max-w-12 h-12 md:min-w-10 md:max-w-14 md:h-14 border border-lightGray rounded-md text-left px-2 py-1 flex flex-col bg-lightGreen text-sm md:text-lg font-[300]' : 'text-center p-1 text-sm'"
          [class.cursor-pointer]="selectDaysMode && !blockedDates.includes(day.fullDate)"
          (click)="selectDaysMode && !blockedDates.includes(day.fullDate) && day.dayOfMonth != 0 ? onSelectDay(day.fullDate) : null"
          class="day"
          [style.background-color]="currentSelection.includes(day.fullDate) ? selectedColor :day.dayOfMonth !== 0 && isDayInSection(day)"
          [class.text-white]="currentSelection.includes(day.fullDate)"
          [class.text-darkGray]="!currentSelection.includes(day.fullDate)"
        >
          {{ day.dayOfMonth !== 0 ? day.dayOfMonth : '' }}
          <!--<span *ngIf="frontMode && day.dayOfMonth !== 0" class="text-[10px] font-normal">46,50€</span>-->
        </div>


        <div *ngIf="quadrantMode"
             [class.cursor-pointer]="selectDaysMode && !blockedDates.includes(day.fullDate)"
             [ngClass]="selectDaysMode && day.dayOfMonth !== 0 ? 'hover:bg-lightBlue' : ''"
             [class.bg-lightBlue]="currentSelection.includes(day.fullDate)"
             (click)="selectDaysMode ? onSelectDay(day.fullDate) : null"
             class="w-full py-4 px-3 relative border-b border-r border-lightGray2 min-h-24">
          <div *ngIf="day.dayOfMonth !== 0" [style.border-color]="lineColor(day)" [class.bg-bg2]="getReduction(day.fullDate)" class="absolute border-b-4 top-0.5 right-1 min-w-6 text-center text-xs p-1">
            {{ day.dayOfMonth !== 0 ? day.dayOfMonth : '' }}
          </div>
          <div *ngIf="day.dayOfMonth !== 0" class="flex gap-1 justify-start text-xs">
            <span class="bg-primary text-white rounded-full p-1 min-w-6 text-center">{{getBooked(day.fullDate)}}</span>
            <span  class="bg-green text-white rounded-full p-1 min-w-6 text-center">{{getFree(day.fullDate)}}</span>
            <!--<span>{{}}</span>-->
          </div>
          <div *ngIf="day.dayOfMonth !== 0" class="px-1 text-xs mt-2">
            <p *ngFor="let h of getList(day.fullDate)" class="text-darkGray mb-1"><span class="text-primary">{{h.count}}</span> {{h.name}}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
