import { Directive } from '@angular/core';

@Directive({
  selector: '[appFormLabel]',
  host: {
    'class': 'block mb-2  font-[400] text-primary',
  },
  standalone: true
})
export class FormLabelDirective {

  constructor() { }

}
