<div class="text-darkGray w-full text-lg border border-lightGray shadow-sm rounded-2xl p-4">
  <div class="flex justify-between items-center">
    <div class="flex justify-center items-center gap-4">
      <div class="flex justify-center rounded-md p-1 bg-lightGray2">
        <img class="w-7" [src]="state.logo ? state.logo.original_url : '/assets/img/not-found.png'" [alt]="state.name">
      </div>
      <p class="text-2xl font-bold text-darkGray">{{state.name}}</p>
      <button (click)="inputImage.click()" class="rounded-md bg-lightGray2 px-2 py-1">
      <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="text-darkGray w-4"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 8h.01" /><path d="M12.5 21h-6.5a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6.5" /><path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l3.5 3.5" /><path d="M14 14l1 -1c.679 -.653 1.473 -.829 2.214 -.526" /><path d="M19 22v-6" /><path d="M22 19l-3 -3l-3 3" /></svg>
    </button>
      <input [(ngModel)]="selectedFile" (change)="uploadimage($event.target)" type="file" class="hidden" #inputImage>
    </div>
   <!-- <button appNextOutlined class="w-fit">Seleccionar festivos</button>-->
  </div>
  <div class="grid grid-cols-2 gap-y-4 gap-x-6 mt-2">
    <div class="flex flex-col gap-y-4">
      <app-blocked-dates *ngFor="let section of state.quadrant_lines"  [quadrantLinesBlocked]="section"></app-blocked-dates>
    </div>
    <app-community-blocked-form #blockedForm (onSubmitSend)="saveBlockedDates($event)"></app-community-blocked-form>
  </div>


</div>
