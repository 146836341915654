<div>
  <app-generic-table class="col-span-1 order-2 xl:order-1 xl:col-span-2"
                     #genericTableComponent
                     [loading]="loading"
                     [tableHeaders]="tableHeaders"
                     (changeSorts)="onChangeSorts($event)"
                     (clickAction)="onClickAction($event)"
                     [tableActions]="tableActions"
                     [showDirectAction]="true"
                     [items]="services">

    <app-table-header-wrapper GTHeader >
      <!--<app-user-table-filters [perPageOptions]="[50,100,150,200]" (onChangePerPage)="perPage = $event; loadReservations()" [perPage]="perPage" [tableHeaders]="tableHeaders" [filters]="filters" (onChangeFilters)="onChangeFilters($event)"></app-user-table-filters>-->
      <app-table-filters
        [loading]="loadingFilters"
        [perPageOptions]="[50,100,150,200]"
        [perPage]="perPage"
        (onChangeFilters)="onChangeFilters($event)"
        [tableHeaders]="tableHeaders">

      </app-table-filters>
    </app-table-header-wrapper>
  </app-generic-table>
</div>


<ng-container *ngIf="editService && editForm">
  <div (click)="closeEditModal()" class="fixed inset-0 bg-black bg-opacity-35 animate-fade-in cursor-pointer z-40"></div>
  <div (click)="closeEditModal()" class="fixed inset-0 flex items-center justify-center px-8 h-full z-50">
    <div (click)="$event.stopPropagation()" class="bg-white p-8 rounded-lg shadow-lg w-full max-w-2xl animate-fade-in-up">

      <form [formGroup]="editForm" (ngSubmit)="update()" class="mt-8 animate-fade-in">
        <div class="w-full mx-auto flex flex-col gap-4">
          <div>
            <label appFormLabel>Nombre del Servicio *</label>
            <input appFormInput type="text" formControlName="name">
            <span *ngIf="editForm.get('name')?.touched && editForm.get('name')?.invalid" appFormError>
              El nombre del tipo de vehiculo es requerido
            </span>
          </div>

          <div>
            <label appFormLabel>Tipo *</label>
            <select appFormInput formControlName="type">
              <option value="open">ABIERTO</option>
              <option value="message">MENSAJE</option>
              <option value="locked">BLOQUEADO</option>
            </select>
          </div>

          <div *ngIf="editForm.get('type')?.value == 'message'">
            <label appFormLabel>Mensaje</label>
            <textarea rows="5" appFormInput formControlName="no_open_message"></textarea>
          </div>

          <div class="w-full flex justify-between">
            <button (click)="closeEditModal()" type="button" class="bg-red-500 text-white shadow-sm p-2 px-4 rounded-2xl">
              Cancelar
            </button>
            <button [disabled]="loadingEdit" type="submit" class="bg-primary text-white shadow-sm p-2 px-4 rounded-2xl text flex items-center gap-4">
              Editar <app-loader *ngIf="loadingEdit" size="extra-small"></app-loader>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

