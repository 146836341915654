import { Directive } from '@angular/core';

@Directive({
  selector: '[appFormInputOutlined]',
  host: {
    'class': ' border border-primary focus:ring-primary focus:shadow-md text-darkGray p-2 px-6 rounded-2xl text-sm font-light w-96 flex items-center flex-row gap-4 duration-300'
  },
  standalone: true
})
export class FormInputOutlinedDirective {

  constructor() { }

}
