import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Reservation} from "../../../models/Reservation";
import {Response} from "../../../models/Response";
import {Station} from "../../../models/Station";
import {environment} from "../../../../environments/environment";
import {Service} from "../../../models/Service";
import {Vehicle} from "../../../models/Vehicle";
import {Fuel} from "../../../models/Fuel";
import {State} from "../../../models/State";
import {AvailableDates} from "../../../models/AvailableDates";
import {AvailableHoursInfo} from "../../../models/AvailableHours";

@Injectable({
  providedIn: 'root'
})
export class FrontService {

  constructor(private http: HttpClient) {}

  // STEP 1
  checkReservation(data: any): Observable<Response<null|Reservation>> {
    return this.http.post<Response<null|Reservation>>(`${environment.API_URL}/reservations/search`,data);
  }
// STEP 2
  listServices(): Observable<Response<Service[]>> {
    return this.http.get<Response<Service[]>>(`${environment.API_URL}/reservations/services`);
  }

  listVehicles(service_id: number) {
    return this.http.get<Response<Vehicle[]>>(`${environment.API_URL}/reservations/vehicles/${service_id}`);
  }

  listFuels(service_id: number, vehicle_id: number) {
    return this.http.get<Response<Fuel[]>>(`${environment.API_URL}/reservations/fuels/${service_id}/${vehicle_id}`);
  }

  // STEP 3
  listStates(service_id: number, vehicle_id: number, fuel_id: number) {
    return this.http.get<Response<State[]>>(`${environment.API_URL}/reservations/states/${service_id}/${vehicle_id}/${fuel_id}` );
  }

  listStations(service_id: number, vehicle_id: number, fuel_id: number, state_id: number) {
    return this.http.get<Response<Station[]>>(`${environment.API_URL}/reservations/stations/${service_id}/${vehicle_id}/${fuel_id}/${state_id}` );
  }

  // STEP 4
  listAvailableDates(month: number, year: number, service_id: number, vehicle_id: number, fuel_id: number, station_id: number) {
    return this.http.get<Response<AvailableDates[]>>(`${environment.API_URL}/reservations/available-dates/${month}/${year}/${service_id}/${vehicle_id}/${fuel_id}/${station_id}` );
  }
  // TODO: ¿Por dónde se le pasa la fecha del día que quieres ver las horas disponibles?
  listAvailableHours(date: string, service_id: number, vehicle_id: number, fuel_id: number, station_id: number) {
    return this.http.get<Response<AvailableHoursInfo>>(`${environment.API_URL}/reservations/available-hours/${date}/${service_id}/${vehicle_id}/${fuel_id}/${station_id}` );
  }

  // STEP 5

  // STEP 6

  // STEP 7
  createReservation(data: any): Observable<any> {
    return this.http.post<any>(`${environment.API_URL}/reservations/confirm`, data);
  }
  getReservation(id:string):Observable<Response<Reservation>>{
    return this.http.get<Response<Reservation>>(`${environment.API_URL}/reservations/find/${id}`);
  }

  getPrice(){
    return this.http.get<any>(`http://85.187.56.108:5081/inspeccion/tarifa?matricula=5276FWP&tipoVehiculo=3&combustible=2&estacion=1605&servicio=1&motivo=P`);
  }
}
