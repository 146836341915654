import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Step1} from "../../../../../models/Front/ReservationForm";
import {DatePipe, NgIf, NgTemplateOutlet} from "@angular/common";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {selectStep1} from "../../../../../store/reservationForm/stepper.selector";
import {updateStep1} from "../../../../../store/reservationForm/stepper.actions";
import {Reservation} from "../../../../../models/Reservation";
import {CardShadowComponent} from "../../utils/card-shadow/card-shadow.component";
import {OutlineButtonDirective} from "../../../../../shared/directives/outline/outline-button.directive";
import {OutlineInputDirective} from "../../../../../shared/directives/outline/outline-input.directive";
import {FormErrorDirective} from "../../../../../shared/directives/forms/form-error.directive";
import {LoaderComponent} from "../../../../../shared/components/loader/loader.component";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";
import {NextButtonDirective} from "../../../../../shared/directives/buttons/next.directive";
import {FrontService} from "../../../../../core/services/front/front.service";
import {environment} from "../../../../../../environments/environment";
import {DialogModule} from "primeng/dialog";
import {ToastrService} from "ngx-toastr";
import {ExistingReservationCardComponent} from "../../existing-reservation-card/existing-reservation-card.component";

@Component({
  selector: 'app-step1',
  standalone: true,
  imports: [
    ReactiveFormsModule, NgIf, DatePipe, NgTemplateOutlet, CardShadowComponent, OutlineButtonDirective, OutlineInputDirective, FormErrorDirective, LoaderComponent, FrontBackDirective, NextButtonDirective, DialogModule, ExistingReservationCardComponent
  ],
  templateUrl: './step1.component.html',
  styleUrl: './step1.component.css'
})
export class Step1Component implements OnInit{
  step1$: Observable<Step1 | undefined>;
  step1Form: FormGroup;
  @Output() stepCompleted = new EventEmitter<void>();
  existingReservation?:Reservation;
  loading = false;

  visible: boolean = false;
  error: string|null = null;

  registrationAlert: boolean=false;
  exitingReservationAlert: boolean=false;
  existingReservationAlert2: boolean=false;

  constructor(private store: Store, private frontService:FrontService, private toast: ToastrService) {
    this.step1$ = this.store.select(selectStep1);

    this.step1Form = new FormGroup({
      registration_number: new FormControl(environment.production ? '' :'8349DVS', [Validators.required/*, Validators.maxLength(7), Validators.minLength(7)*/])
    });
  }

  ngOnInit() {
    this.step1$.subscribe(step1 => {
      if (step1) {
        this.step1Form.patchValue(step1);
      }
    });
  }

  public update() {
    this.loading = true;
    this.error = null;
    if (this.step1Form.valid) {
      let data:any = {
        registration_number:this.step1Form.get('registration_number')?.value
      }
      this.frontService.checkReservation(data).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.existingReservation=response.data;
              const step1: Step1 = this.step1Form.value;
              this.store.dispatch(updateStep1({ step1 }));
              this.exitingReservationAlert=true;

            } else {
              const step1: Step1 = this.step1Form.value;
              this.store.dispatch(updateStep1({ step1 }));
              this.stepCompleted.emit();
            }
            this.loading = false;
          },
          error: error => {
            this.error = error.error.message;
            // console.error(error.error.message);
            this.loading = false;
          }
        }
      );
    } else {
      this.loading = false;
      this.step1Form.markAllAsTouched();
    }
  }

  public returnRegistration(){
    this.existingReservation=undefined;
    this.registrationAlert = false;
    this.exitingReservationAlert = false;
    this.existingReservationAlert2 = false;
  }

  toggleDialog() {
    this.visible = !this.visible;
  }

}
