<div class="mb-2 w-full border-2 border-lightGray2 shadow-sm px-4 py-2 rounded-2xl" *ngIf="quadrantLinesBlocked">
  <div class="flex flex-col justify-between w-full">
    <p class="text-primary font-bold mb-2 flex gap-2" *ngIf="!editMode" >{{ quadrantLinesBlocked.name }}
      <button (click)="editMode = true" class="text-orange-400">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
      </button>
      <button (click)="deleteQuadrantLine()" class="text-red-400">
        <svg  *ngIf="loading != quadrantLinesBlocked.id+'_id'" xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
        <app-loader *ngIf="loading == quadrantLinesBlocked.id+'_id'"  size="extra-small"></app-loader>
      </button>
    </p>
    <div *ngIf="editMode" class="flex items-center flex-col gap-1 mb-2 max-w-full">
      <input [(ngModel)]="quadrantName" type="text" class="text-sm p-1 rounded-full border border-lightGray2 max-w-full" />
      <div class="flex justify-items-stretch gap-2">
        <button (click)="deleteQuadrantLine()" class="bg-red-400 text-white z-40 rounded-full p-1 w-7 h-7 flex items-center justify-center">
          <svg (click)="quadrantName = quadrantLinesBlocked.name; editMode = false;"  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-4"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
        </button>
        <button (click)="updateName()" class="z-40 text-white bg-green rounded-full p-1 w-7 h-7 flex items-center justify-center">
       <span *ngIf="loading != 'editName'" class="">
       <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10" /></svg>
       </span>
          <app-loader *ngIf="loading == 'editName'"  size="extra-small"></app-loader>
        </button>
      </div>
    </div>
    <div class="mb-4 flex flex-col gap-y-2">
      <div class="mt-2 flex items-center gap-2">
        <input [(ngModel)]="addDateStr" type="date" class="text-sm p-1 rounded-full text-darkGray border border-lightGray2" />
        <button (click)="addDate()" class="text-darkGray bg-lightGray2 rounded-full w-6 h-6 flex items-center justify-center">
          <span  *ngIf="loading != 'addDate'">+</span>
          <app-loader *ngIf="loading == 'addDate'"  size="extra-small"></app-loader>
        </button>
      </div>
      <div class="flex items-center justify-start gap-2 flex-wrap">
      <span class="text-darkGray bg-lightGray2 text-sm py-1 px-2 border gap-2 border-lightGray2 rounded-2xl flex items-center" *ngFor="let date of quadrantLinesBlocked.info">
        {{date | date:'dd/MM/yy'}}
        <button class="text-red-400" (click)="remove(date)">
          <svg *ngIf="loading != date"  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-4"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
          <app-loader *ngIf="loading == date"  size="extra-small"></app-loader>
        </button>
      </span>

      </div>
    </div>

    <!--<button  (click)="festivePanel.toggle($event)" class="bg-primary hover:bg-darkPrimary text-white rounded-full p-1">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
      <p-overlayPanel #festivePanel>
        <ul>
          <li *ngFor="let date of quadrantLinesBlocked.info">
            {{date | date:'dd/MM/Y'}}
          </li>
        </ul>
      </p-overlayPanel>
    </button>-->

  </div>
  <div class="flex flex-col gap-y-2 mb-1">
    <div class="mt-2 flex items-center gap-2">
      <input [(ngModel)]="addTimeInit" type="time" class="text-sm p-1 rounded-full border border-lightGray2" />
      <span>-</span>
      <input [(ngModel)]="addTimeFinish" type="time" class="text-sm p-1 rounded-full border border-lightGray2" />
      <button (click)="addTime()" class="text-darkGray bg-lightGray2 rounded-full w-6 h-6 flex items-center justify-center">
        <span  *ngIf="loading != 'addTime'">+</span>
        <app-loader *ngIf="loading == 'addTime'"  size="extra-small"></app-loader>
      </button>
    </div>
    <div class="flex items-center justify-start gap-2 flex-wrap" *ngIf="quadrantLinesBlocked.blocked">
      <span class="text-darkGray bg-lightGray2 text-sm py-1 px-2 border border-lightGray2 rounded-2xl flex gap-2 items-center " *ngFor="let blocked of quadrantLinesBlocked.blocked">
        de {{ blocked.init }} a {{ blocked.finish }} <button (click)="deleteBlocked(blocked.id)" class="text-red-400">
        <svg  *ngIf="loading != quadrantLinesBlocked.id+'_b'" xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-4"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
        <app-loader *ngIf="loading == quadrantLinesBlocked.id+'_b'"  size="extra-small"></app-loader>
      </button>
      </span>
    </div>
  </div>
</div>

<p-confirmDialog #cancel>
  <ng-template pTemplate="headless" let-message>
    <div class="flex flex-col items-center p-5 surface-overlay border-round bg-white rounded-2xl">
      <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem">
        <i class="pi pi-question text-5xl"></i>
      </div>
      <span class="font-bold text-2xl block mb-2 mt-4">
                {{ message.header }}
            </span>
      <p class="mb-0">{{ message.message }}</p>
      <div class="flex items-center gap-2 mt-4">
        <button
          (click)="cancel.accept()"
          class="bg-primary text-white p-2 bg-opacity-95 rounded-md hover:bg-opacity-90">
          Aceptar
        </button>
        <button
          pButton
          label="Cancelar"
          (click)="cancel.reject()"
          class="bg-primary bg-opacity-0 p-2 hover:bg-opacity-10 text-primary border border-primary">
        </button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
