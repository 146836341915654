import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDay',
  standalone: true
})
export class CustomDayPipe implements PipeTransform {

  private days: string[] = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];

  transform(value: Date | string | number): string {
    let date: Date;
    if (value instanceof Date) {
      date = value;
    } else if (typeof value === 'string' || typeof value === 'number') {
      date = new Date(value);
    } else {
      return '';
    }

    const dayIndex = date.getDay();
    return this.days[dayIndex];
  }

}
