import { Directive } from '@angular/core';

@Directive({
  selector: '[appNextOutlined]',
  host: {
    'class': ' border duration-300 border-primary rounded-2xl hover:bg-darkPrimary hover:text-white px-4 py-2 text-primary items-center flex flex-row justify-center text-sm gap-x-2 text-lg font-light'
  },
  standalone: true
})
export class NextOutlinedButtonDirective {

  constructor() { }

}
