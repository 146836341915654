<div class="w-full mt-4">
  <p class="text-darkGray text-sm mb-2">Tipo de inspección y datos del vehículo</p>

  <div class="grid grid-cols-2 gap-x-6 gap-y-4">
    <div *ngIf="(!step2 || (step2 && step2.subStep>=1)) && !loading" class="flex flex-col gap-y-2 animate-fade-in" >
      <!--<label class="text-darkGray">Tipo de inspección</label>-->
      <select id appFormInputBlueNoFull [(ngModel)]="selectedService" (change)="onSelectService()" [ngModelOptions]="{standalone: true}">
        <option disabled [ngValue]="undefined">Selecciona un tipo de inspección</option>
        <option *ngFor="let service of services" [ngValue]="service" [selected]="selectedService?.id===service.id ? service : ''"> {{service.name}} </option>
      </select>
    </div>
    <div class="flex items-center flex-col gap-y-2 my-auto" *ngIf="(!step2 || (step2 && step2.subStep>=1)) && loading">
      <app-loader size="small"></app-loader>
    </div>
    <div *ngIf="step2 &&  step2.subStep>=2 && !loadingVehicles" class="flex flex-col gap-y-2 animate-fade-in">
     <!-- <label class="text-darkGray" >Tipo de vehículo</label>-->
      <select appFormInputBlueNoFull [(ngModel)]="selectedVehicle" (change)="onSelectVehicle()">
        <option disabled [ngValue]="undefined">Selecciona un tipo de vehículo</option>
        <option *ngFor="let vehicle of vehicles" [ngValue]="vehicle"> {{vehicle.name}}</option>
      </select>
    </div>
    <div class="flex items-center flex-col gap-y-2 my-auto" *ngIf="step2 &&  step2.subStep>=2 && loadingVehicles">
      <app-loader size="small"></app-loader>
    </div>
    <div *ngIf="step2 &&  step2.subStep>=3 && !loadingFuels" class="flex flex-col gap-y-2 animate-fade-in">
     <!-- <label class="text-darkGray">Tipo de combustible</label>-->
      <select appFormInputBlueNoFull [(ngModel)]="selectedFuel" (change)="onSelectFuel()">
        <option disabled [ngValue]="undefined">Selecciona un tipo de combustible</option>
        <option *ngFor="let fuel of fuels" [ngValue]="fuel"> {{fuel.name}}</option>
      </select>
    </div>
    <div class="flex items-center flex-col gap-y-2 my-auto" *ngIf="step2 &&  step2.subStep>=3 && loadingFuels">
      <app-loader size="small"></app-loader>
    </div>
  </div>

</div>



