import { Component } from '@angular/core';
import {GenericTableComponent} from "../../../../shared/components/generic-table/generic-table.component";
import {TableFiltersComponent} from "../../../../shared/components/generic-table/table-filters/table-filters.component";
import {
  TableHeaderWrapperComponent
} from "../../../../shared/components/generic-table/table-header-wrapper/table-header-wrapper.component";
import {TableHeader} from "../../../../models/Table/TableHeader";
import {Filter} from "../../../../models/Table/Filter";
import {Paginator} from "../../../../models/Paginator";
import {Reservation} from "../../../../models/Reservation";
import {ReservationService} from "../../../../core/services/reservations/reservation.service";
import {SortColumn} from "../../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {forkJoin} from "rxjs";
import {AuthFormInputDirective} from "../../../../shared/directives/auth/auth-form-input.directive";
import {NgIf} from "@angular/common";
import {InputSwitchModule} from "primeng/inputswitch";
import {FormsModule} from "@angular/forms";
import {NgxSpinnerComponent} from "ngx-spinner";
import {RouterOutlet} from "@angular/router";
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {VehiclesService} from "../../../../core/services/vehicles/vehicles.service";
import {StationService} from "../../../../core/services/stations/station.service";
import {ServicesService} from "../../../../core/services/services/services.service";
import {TableActions} from "../../../../models/Table/TableActions";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmationService} from "primeng/api";
import {ToastrService} from "ngx-toastr";
import {FrontService} from "../../../../core/services/front/front.service";
import {ReservationGesService} from "../../../../core/services/reservations/reservation-ges.service";
import {DialogModule} from "primeng/dialog";
import {
  ExistingReservationCardComponent
} from "../../../front/components/existing-reservation-card/existing-reservation-card.component";

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [
    AuthFormInputDirective,
    NgIf,
    InputSwitchModule,
    FormsModule,
    NgxSpinnerComponent,
    RouterOutlet,
    TableHeaderWrapperComponent,
    TableFiltersComponent,
    GenericTableComponent,
    DialogModule,
    ExistingReservationCardComponent,
    // ConfirmDialogModule
  ],
  // providers: [ConfirmationService],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.css'
})
export class CalendarComponent {

  perPage: number = 50;
  loading = false;
  loadingFilters = false;
  displayEditDialog: boolean = false;
  selectedReservation?:Reservation;
  editReservation:boolean = true;

  tableHeaders: TableHeader[] = [
    {
      name: 'ID',
      active: true,
      td_type: 'text',
      td_key: 'id',
      sort: false,
    },
    {
      name: 'MATRÍCULA',
      active: true,
      td_type: 'text',
      td_key: 'registration_number',
      sort: false,
    },
    {
      name: 'TELÉFONO',
      active: true,
      td_type: 'text',
      td_key: 'phone',
      sort: false,
    },
    {
      name: 'SERVICIO',
      td_type: 'text',
      active: true,
      td_key: 'service',
      table: 'services',
      td_sub_key: 'name',
      sort: false,
    },
    {
      name: 'TIPO VEHÍCULO',
      td_type: 'text',
      td_key: 'vehicle',
      active: true,
      table: 'vehicles',
      td_sub_key: 'name',
      sort: true,
    },
    {
      name: 'FECHA CITA',
      td_type: 'date',
      active: true,
      td_key: 'date',
      format: 'dd/MM/Y',
      sort: true,
    },
    {
      name: 'HORA',
      td_type: 'text',
      td_key: 'hour',
      active: true,
      table: 'hours',
      td_sub_key: 'hour',
      sort: true,
    },
    {
      name: 'ESTADO',
      active: true,
      td_type: 'boolean-click',
      td_key: 'state',
      sort: false,
    },
    {
      name: 'OPERADOR',
      td_type: 'text',
      td_key: 'user',
      active: true,
      table: 'users',
      td_sub_key: 'name',
      sort: false,
    },
    {
      name: 'FECHA CREACIÓN',
      td_type: 'date',
      active: false,
      td_key: 'created_at',
      format: 'dd/MM/Y HH:mm',
      sort: true,
    },
    {
      name: 'FECHA MODIFCACIÓN',
      td_type: 'date',
      active: false,
      td_key: 'updated_at',
      format: 'dd/MM/Y HH:mm',
      sort: true,
    }
  ]

  tableActions: TableActions[] = [
    {
      icon: '<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M10 10l4 4m0 -4l-4 4" /></svg>',
      name: 'Cancelar',
      key: 'cancel',
      color: '#FF0000',
      confirm: true,
      confirmTitle: 'Cancelar reserva',
      confirmSubTitle: '¿Estás seguro de que deseas cancelar esta reserva?',
    },
    {
      icon: '<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>',
      name: 'Editar',
      key: 'edit',
      color: '#FF8A4C',
      confirm: false,
    }
  ];

  tableFilters: Filter[] = []

  displayNames: { [key: string]: string } = {
    date: 'Fecha',
    registration_number: 'Término',
    station_id: 'Estación',
    service_id: 'Servicio',
    vehicle_id: 'Vehiculo',
    state: 'Estado',
    'registration_number|phone': "Término"
  };

  today:Date = new Date();

  reservations: Paginator<Reservation> | null = null;

  constructor(private reservationService: ReservationService, private breadcrumbService: BreadcrumbService,
              private vehiclesService: VehiclesService, private stationService: StationService,
              private serviceService: ServicesService, private toastr:ToastrService,
              private reservationGesService:ReservationGesService,
              /*private confirmationService: ConfirmationService*/) {
    this.filters = [
      {
        column:"date",
        format:"dd/MM/Y",
        operator: "between",
        parsed: "date-arr",
        value:[this.today,this.today]
      }
    ]
    this.loadReservations();
    this.loadElements();
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Agenda',
      items: [{
        name: 'Listado',
      }]
    })
  }

  sorts: SortColumn[] = [];
  filters: ActiveFilter[] = [];

  loadReservations(){
    this.loading = true;
    this.reservationService.list(this.sorts, this.filters, this.perPage).subscribe({
      next: data => {
        if (data.data) {
          this.reservations = data.data;
        } else {
          this.reservations = null;
        }
        this.loading = false;
      },
      error: err => {
        this.loading = false;
      },
    })
  }

  loadElements(){
    this.loadingFilters = true;
    forkJoin({
      stations: this.stationService.list(),
      services: this.serviceService.list(),
      vehicles: this.vehiclesService.list()
    }).subscribe({
      next: (results) => {
        this.tableFilters.push({
          key: 'station_id',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: results.stations.data ?? [],
          placeholder: 'Estación',
          parsed:"relationship-arr",
          relation_column: 'name',
          relation_filter_column: 'id'
        });
        this.tableFilters.push({
          key: 'service_id',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: results.services.data ?? [],
          placeholder: 'Servicio',
          parsed:"relationship-arr",
          relation_column: 'name',
          relation_filter_column: 'id'
        });
        this.tableFilters.push({
          key: 'vehicle_id',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: results.vehicles.data ?? [],
          placeholder: 'Vehículo',
          parsed:"relationship-arr",
          relation_column: 'name',
          relation_filter_column: 'id'
        })
        this.tableFilters.push({
          key: 'registration_number|phone',
          type: 'search',
          operator: 'like',
          placeholder: 'Buscar...'
        })
        this.tableFilters.push({
          key: 'date',
          type: 'date-between',
          parsed: 'date-arr',
          operator: 'between',
          placeholder: 'Fecha'
        });

        this.tableFilters.push({
          key: 'state',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: [{name: "Pasadas", state: 1}, {name: "No pasadas", state: 0}],
          placeholder: 'Estado',
          parsed: "array",
          relation_column: 'name',
          relation_filter_column: 'state'
        })


        this.loadingFilters = false;
      },
      error: (error) => {
        this.loadingFilters = false;
        // Manejar errores aquí
        console.error('Error al llamar a los observables:', error);
      }
    });
  }

  onChangeSorts(sorts: SortColumn[]): void {
    this.sorts = sorts;
    this.loadReservations();
  }


  onChangeFilters(filters: ActiveFilter[]){
    this.filters = filters;
    this.loadReservations();
  }

  onBooleanClick(data: {header: TableHeader, item: Reservation, index: number}) {
/*    console.log(data.header);
    console.log(data.item);
    console.log(data.index);*/
    this.reservationService.updateState(data.item.id).subscribe({
      next: (data) => {
        console.log(data)
        if (data.data) {
          console.log(data.data);
        }
      },
      error: error => {
        this.toastr.error('Ha habido un error al editar la reserva.');
      }
    });
  }

  // Método para eliminar la reserva del paginador
  removeReservationFromPaginator(reservationId: number): void {
    if (this.reservations && this.reservations.data) {
      this.reservations.data = this.reservations.data.filter(reservation => reservation.id !== reservationId);
    }
  }

  onClickAction(data: {action: TableActions, item: Reservation, index: number}) {
    console.log(data);
    if(data.action.key === 'cancel'){
      this.reservationGesService.cancel(data.item.id, null).subscribe({
        next:(response) => {
          if(response.success){
            this.removeReservationFromPaginator(data.item.id);
            this.toastr.success(response.message)
          } else {
            this.toastr.error(response.message);
          }
        },
        error: (err)=>{
          this.toastr.error(err.error.message);
      }
      });
    }
    if(data.action.key === 'edit'){
      this.openEditDialog(data.item);
    }
    /*this.confirmationService.confirm({
      target: data.event.target as EventTarget,
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass:"p-button-danger p-button-text",
      rejectButtonStyleClass:"p-button-text p-button-text",
      acceptIcon:"none",
      rejectIcon:"none",

      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted' });
      },
      reject: () => {
        // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });*/
  }

  openEditDialog(reservation: Reservation) {
    this.selectedReservation = reservation; // Asigna la reserva seleccionada
    this.displayEditDialog = true; // Muestra el diálogo
  }


  onEditConfirm() {
    // Lógica para confirmar los cambios de edición
    this.displayEditDialog = false; // Cierra el diálogo después de confirmar
    this.toastr.success('Cambios guardados correctamente.');
  }
}
