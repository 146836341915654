import {Component, EventEmitter, Output} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {FormErrorDirective} from "../../../../../../shared/directives/forms/form-error.directive";
import {FormInputDirective} from "../../../../../../shared/directives/forms/form-input.directive";
import {FormLabelDirective} from "../../../../../../shared/directives/forms/form-label.directive";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {NextOutlinedButtonDirective} from "../../../../../../shared/directives/buttons/next-outlined.directive";

@Component({
  selector: 'app-community-blocked-form',
  standalone: true,
    imports: [
        FormErrorDirective,
        FormInputDirective,
        FormLabelDirective,
        NgForOf,
        NgIf,
        ReactiveFormsModule,
        NextOutlinedButtonDirective,
        FormsModule,
        DatePipe
    ],
  templateUrl: './community-blocked-form.component.html',
  styleUrl: './community-blocked-form.component.css'
})
export class CommunityBlockedFormComponent {
  scheduleForm: FormGroup;
  dateSelected:string = '';
  dateSelectedAlert:boolean = false;
  initHourSelected:string='';
  finishHourSelected:string='';
  hoursSelectedAlert:boolean = false;
  @Output() onSubmitSend: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder) {
    this.scheduleForm = this.fb.group({
      name: ['', Validators.required],
      blocked: this.fb.array([]),
      dates: this.fb.array([]) // FormArray para las fechas// Inicializa el FormArray vacío
    });
  }

  // Getter para acceder fácilmente al FormArray
  get blocked(): FormArray {
    return this.scheduleForm.get('blocked') as FormArray;
  }

  // Método para crear un nuevo FormGroup para un horario
  createSchedule(): FormGroup {
    return this.fb.group({
      init: [this.initHourSelected, Validators.required],
      finish: [this.finishHourSelected, Validators.required]
    });
  }

  // Método para agregar un nuevo horario
  addSchedule(): void {
    if(this.initHourSelected && this.finishHourSelected){
      this.hoursSelectedAlert=false;
      this.blocked.push(this.createSchedule());
      this.initHourSelected = '';
      this.finishHourSelected = '';
    } else if(!this.initHourSelected || !this.finishHourSelected){
      this.hoursSelectedAlert=true;
    }

  }

  // Método para eliminar un horario específico
  removeSchedule(index: number): void {
    this.blocked.removeAt(index);
  }

  // Método para manejar la presentación del formulario
  onSubmit(): void {
    console.log(this.scheduleForm.value)
    this.scheduleForm.markAllAsTouched();
    if (this.scheduleForm.valid) {
      this.onSubmitSend.emit(this.scheduleForm.value);
      // Aquí puedes enviar el formulario o manejar los datos como necesites
    }
  }

  get dates(): FormArray {
    return this.scheduleForm.get('dates') as FormArray;
  }

// Método para crear un nuevo FormGroup para una fecha
  createDate(): FormControl {

    return this.fb.control(this.dateSelected, Validators.required);
  }

// Método para agregar una nueva fecha
  addDate(): void {
    if(this.dateSelected){
      this.dateSelectedAlert=false;
      this.dates.push(this.createDate());
    } else {
      this.dateSelectedAlert=true;
    }

    this.dateSelected='';
  }

// Método para eliminar una fecha específica
  removeDate(index: number): void {
    this.dates.removeAt(index);
  }

  reset() {
    // Vaciar el FormArray "blocked"
    this.blocked.clear();

    // Vaciar el FormArray "dates"
    this.dates.clear();

    // Restablecer el formulario
    this.scheduleForm.reset();

    // Marcar el formulario como intocado
    this.scheduleForm.markAsUntouched();
  }
}
