import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-export',
  standalone: true,
  imports: [
    OverlayPanelModule,
    NgIf
  ],
  templateUrl: './export.component.html',
  styleUrl: './export.component.css'
})
export class ExportComponent {
  @Input() excludes: string[] = [];
  @Output() onExportClick: EventEmitter<'json'|'csv'|'text'> = new EventEmitter();

  show(type: string){
    return this.excludes.indexOf(type) === -1;
  }
}
