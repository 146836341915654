import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {StationService} from "../../../../core/services/stations/station.service";
import {ServicesService} from "../../../../core/services/services/services.service";
import {VehiclesService} from "../../../../core/services/vehicles/vehicles.service";
import {SummaryComponent} from "./components/summary/summary.component";
import {AppointmentFormComponent} from "./components/appointment-form/appointment-form.component";
import {OtherStationsComponent} from "./components/other-stations/other-stations.component";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectCurrentStep} from "../../../../store/reservationForm/stepper.selector";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-new-appointment',
  standalone: true,
  imports: [
    RouterOutlet,
    SummaryComponent,
    AppointmentFormComponent,
    OtherStationsComponent,
    NgIf
  ],
  templateUrl: './new-appointment.component.html',
  styleUrl: './new-appointment.component.css'
})
export class NewAppointmentComponent {

  currentStep$: Observable<number>;
  currentStep: number=1;

  constructor(private breadcrumbService: BreadcrumbService, private stationService: StationService, private serviceService: ServicesService, private vehicleService: VehiclesService,private store:Store) {
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Dasboard',
      items: [{
        name: 'Nueva cita',
      }]
    });
    this.currentStep$ = this.store.pipe(select(selectCurrentStep));
    this.currentStep$.subscribe(step => {
      this.currentStep = step;
    });
  }


}
