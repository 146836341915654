import {Component, Input, OnInit} from '@angular/core';
import {QuadrantLine} from "../../../models/QuadrantLine";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {BlockedService} from "../../../core/services/blocked/blocked.service";
import {LoaderComponent} from "../loader/loader.component";
import {ButtonDirective} from "primeng/button";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmationService} from "primeng/api";
import {FormsModule} from "@angular/forms";
import {data} from "autoprefixer";

@Component({
  selector: 'app-blocked-dates',
  standalone: true,
  imports: [
    DatePipe,
    NgForOf,
    NgIf,
    OverlayPanelModule,
    LoaderComponent,
    ButtonDirective,
    ConfirmDialogModule,
    FormsModule
  ],
  providers: [ConfirmationService],
  templateUrl: './blocked-dates.component.html',
  styleUrl: './blocked-dates.component.css'
})
export class BlockedDatesComponent implements  OnInit {
  @Input() quadrantLinesBlocked!: QuadrantLine|null;

  addDateStr: string = "";
  addTimeInit: string = "";
  addTimeFinish: string = "";

  quadrantName = "";
  editMode = false;

  loading = "";

  constructor(private blockedService: BlockedService, private confirmationService: ConfirmationService) {
  }

  ngOnInit() {
    this.quadrantName = this.quadrantLinesBlocked!.name;
  }

  addDate(){
    this.loading = "addDate";
    // console.log(this.addDateStr);
    this.blockedService.addDate(this.quadrantLinesBlocked!.id, this.addDateStr).subscribe({
      next:(response)=>{
        if (response.data) {
          this.quadrantLinesBlocked = response.data;
        }
        this.loading = "";
        this.addDateStr = "";
      },
      error:(error)=>{
        console.log(error);
        this.loading = "";
      }
    })
  }

  addTime(){
    this.loading = "addTime";
    this.blockedService.addBlocked(this.quadrantLinesBlocked!.id, {init: this.addTimeInit, finish: this.addTimeFinish}).subscribe({
      next:(response)=>{
        if (response.data) {
          this.quadrantLinesBlocked = response.data;
        }
        this.loading = "";
        this.addTimeInit = "";
        this.addTimeFinish = "";
      },
      error:(error)=>{
        console.log(error);
        this.loading = "";
      }
    })
  }

  updateName(){
    this.loading = "editName";
    this.blockedService.changeName(this.quadrantLinesBlocked!.id, {name: this.quadrantName}).subscribe({
      next: (response) => {
        if (response.data) {
          this.quadrantLinesBlocked = response.data;
          this.editMode = false;
          this.quadrantName = response.data?.name;
        }
        this.loading = "";
      },
      error: (error) => {
        console.log(error);
        this.loading = "";
      }
    });
  }

  deleteQuadrantLine(){
    this.loading = this.quadrantLinesBlocked!.id+"_id";
    this.confirmationService.confirm({
      header: 'Confirmar borrado de bloqueo',
      message:'¿Estas seguro de querer eliminar el bloqueo '+this.quadrantLinesBlocked!.name+'?',
      accept: () => {
        this.blockedService.deleteLine(this.quadrantLinesBlocked!.id).subscribe({
          next:(response)=>{
            this.quadrantLinesBlocked = null;
            this.loading = "";
          },
          error:(error)=>{
            console.log(error);
            this.loading = "";
          }
        })
      },
      reject: () => {
        this.loading = "";
      }
    });
  }

  deleteBlocked(blockedId:number){
    this.loading = blockedId+'_b';
    this.confirmationService.confirm({
      header: 'Confirmar borrado de rango de bloqueo',
      message:'¿Estas seguro de querer eliminar este rango de '+this.quadrantLinesBlocked!.name+'?',
      accept: () => {
        this.blockedService.deleteBlocked(blockedId).subscribe({
          next:(response)=>{
            if (response.data) {
              console.log(response.data);
              this.quadrantLinesBlocked = response.data;
            }
            this.loading = "";
          },
          error:(error)=>{
            console.log(error);
            this.loading = "";
          }
        })
      },
      reject: () => {
        this.loading = "";
      }
    });
  }

  remove(date: string) {
    this.loading = date;
    this.confirmationService.confirm({
      header: 'Confirmar borrado de fecha',
      message:'¿Estas seguro de querer eliminar la fecha del bloqueo '+this.quadrantLinesBlocked!.name+'?',
      accept: () => {
        this.blockedService.deleteDate(this.quadrantLinesBlocked!.id, date).subscribe({
          next:(response)=>{
            if (response.data) {
              this.quadrantLinesBlocked = response.data;
            }
            this.loading = "";
          },
          error:(error)=>{
            console.log(error);
            this.loading = "";
          }
        })
      },
      reject: () => {
        this.loading = "";
      }
    });

  }
}
