import { Component } from '@angular/core';
import {BookFormService} from "../../../../../../../../core/services/book-form.service";
import {updateStep, updateStep6} from "../../../../../../../../store/reservationForm/stepper.actions";
import {Step5, Step6} from "../../../../../../../../models/Front/ReservationForm";
import {FrontBackDirective} from "../../../../../../../../shared/directives/buttons/front.back.directive";
import {LoaderComponent} from "../../../../../../../../shared/components/loader/loader.component";
import {PaymentMethod} from "../../../../../../../../models/PaymentMethod";
import {Station} from "../../../../../../../../models/Station";
import {NgForOf, NgIf} from "@angular/common";
import {
  FormInputBlueNoFullDirective
} from "../../../../../../../../shared/directives/forms/form-input-blue-no-full.directive";
import {FormsModule} from "@angular/forms";
import {NextSquareButtonDirective} from "../../../../../../../../shared/directives/buttons/next-square.directive";

@Component({
  selector: 'app-step6',
  standalone: true,
  imports: [
    FrontBackDirective,
    LoaderComponent,
    NgIf,
    FormInputBlueNoFullDirective,
    NgForOf,
    FormsModule,
    NextSquareButtonDirective
  ],
  templateUrl: './step6.component.html',
  styleUrl: './step6.component.css'
})
export class Step6Component {

  selectedPayment:PaymentMethod | undefined;
  selectedStation?:Station;
  loading: boolean = false;

  constructor(private bookFormService: BookFormService)  {
    this.bookFormService.reservationForm$.subscribe({
      next: data => {
        this.selectedStation = data.steps.step3?.station;
      }
    })
  }

  goBack(){
    this.bookFormService.setCurrentStep(5);
  }

  next(){
    let step6:Step6 = {
      payment:this.selectedPayment
    }
    this.bookFormService.setStep6(step6);
  }

  onSelectPayment(){

  }


}
