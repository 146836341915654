import { Component } from '@angular/core';
import {AsideService, AsideState} from "../../../../core/services/aside-service/aside-service.service";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {User} from "../../../../models/User";
import {Router, RouterLink} from "@angular/router";
import {AuthService} from "../../../../core/services/auth/auth.service";
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {Breadcrumb} from "../../../../models/Breadcrumb";

@Component({
  selector: 'app-dashboard-navbar',
  standalone: true,
  imports: [
    NgIf,
    NgOptimizedImage,
    NgForOf,
    RouterLink
  ],
  templateUrl: './dashboard-navbar.component.html',
  styleUrl: './dashboard-navbar.component.css'
})
export class DashboardNavbarComponent {
  asideState!: AsideState;
  user: User | null = null;
  breadcrumbs: Breadcrumb | null = null;
  constructor(public asideService: AsideService,
              public authStateService: AuthStateService,
              private router: Router,
              private authService: AuthService,
              private breadcrumbService: BreadcrumbService) {
      this.asideService.asideState$.subscribe({
        next: (a: AsideState) => {
          this.asideState = a;
        }
      })
    this.authStateService.currentUser.subscribe(data => {
      this.user = data;
    });
      this.breadcrumbService.breadcrumb.subscribe(data => {
        this.breadcrumbs = data;
      })
  }

  logOut(){
    this.authService.logOut().subscribe({
      next: value => {
        this.authStateService.setUser(null);
        this.router.navigate(['/login'])
      },error: () => {
        this.authStateService.setUser(null);
        this.router.navigate(['/login'])
      }
    })
    // TODO: LOGOUT

  }

}
