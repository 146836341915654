import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {Vehicle} from "../../../models/Vehicle";
import {environment} from "../../../../environments/environment";
import {Service} from "../../../models/Service";
import {Reservation} from "../../../models/Reservation";
import {Paginator} from "../../../models/Paginator";
import {SortColumn} from "../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";
import {QuadrantLine} from "../../../models/QuadrantLine";

@Injectable({
  providedIn: 'root'
})
export class BlockedService {

  constructor(private http: HttpClient) { }

  deleteDate(quadrantLineId: number, date: string): Observable<Response<QuadrantLine>> {
    return this.http.delete<Response<QuadrantLine>>(`${environment.API_URL}/blocked/remove/date/${quadrantLineId}/${date}`)
  }

  deleteBlocked(blockedId: number): Observable<Response<QuadrantLine>> {
    return this.http.delete<Response<QuadrantLine>>(`${environment.API_URL}/blocked/remove/blocked/${blockedId}`)
  }

  deleteLine(quadrantLineId: number): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${environment.API_URL}/blocked/remove/line/${quadrantLineId}`)
  }

  addDate(quadrantLineId: number, date: string): Observable<Response<QuadrantLine>> {
    return this.http.patch<Response<QuadrantLine>>(`${environment.API_URL}/blocked/add/date/${quadrantLineId}/${date}`, {});
  }

  addBlocked(quadrantLineId: number, info: any): Observable<Response<QuadrantLine>> {
    return this.http.patch<Response<QuadrantLine>>(`${environment.API_URL}/blocked/add/blocked/${quadrantLineId}`, info);
  }

  changeName(quadrantLineId: number, info: any): Observable<Response<QuadrantLine>> {
    return this.http.patch<Response<QuadrantLine>>(`${environment.API_URL}/blocked/edit/line/${quadrantLineId}`, info);
  }
}
