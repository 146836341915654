import { Directive } from '@angular/core';

@Directive({
  selector: '[appOutlineInput]',
  host: {
    'class': 'border border-darkPrimary rounded-full px-4 py-4 text-darkGray text-sm font-light'
  },
  standalone: true
})
export class OutlineInputDirective {

  constructor() { }

}
