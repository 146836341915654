<section class="bg-bg2 h-screen animate-fade-in">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
    <a href="#" class="flex items-center mb-8 text-2xl font-semibold text-gray-900 animate-fade-in-down">
      <img class="w-30 mr-2" src="/assets/img/logo.svg" alt="logo">
    </a>
    <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-lg xl:p-0 animate-fade-in-up">
      <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <p class="text-darkGray text-center">Cambio de contraseña</p>
        <form class="space-y-4 md:space-y-6">
          <div>
            <label for="password" appAuthFormLabel>Cobtraseña</label>
            <input [(ngModel)]="password" type="password" name="email" id="password" appAuthFormInput placeholder="*********" required>
          </div>
          <div>
            <label for="password_confirmation" appAuthFormLabel>Confirmación contraseña</label>
            <input [(ngModel)]="password_confirmation" type="password" name="email" id="password_confirmation" appAuthFormInput placeholder="*********" required>
          </div>
         <!-- <div *ngIf="successMessage" class="p-4 mb-4 text-sm text-darkGray rounded-lg bg-green" role="alert">
            {{ successMessage }}
          </div>
          <div *ngIf="errorMessage" class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
            {{ errorMessage }}
          </div>-->
          <button (click)="resetPassword()" type="submit" class="w-full disabled:bg-darkGray text-white bg-primary hover:opacity-85 focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
            Recuperar
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
