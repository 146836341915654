import { Directive } from '@angular/core';

@Directive({
  selector: '[appAuthFormLabel]',
  host: {
    'class': 'block mb-2 text-sm font-medium text-darkGray',
  },
  standalone: true
})
export class AuthFormLabelDirective {

  constructor() { }

}
