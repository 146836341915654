import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {StationService} from "../../../../core/services/stations/station.service";
import {ServicesService} from "../../../../core/services/services/services.service";
import {VehiclesService} from "../../../../core/services/vehicles/vehicles.service";
import {SummaryComponent} from "./components/summary/summary.component";
import {AppointmentFormComponent} from "./components/appointment-form/appointment-form.component";
import {OtherStationsComponent} from "./components/other-stations/other-stations.component";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectCurrentStep, selectStep2, selectStep3} from "../../../../store/reservationForm/stepper.selector";
import {NgIf} from "@angular/common";
import {Station} from "../../../../models/Station";
import {FrontService} from "../../../../core/services/front/front.service";
import {Summaryv2Component} from "./components/summaryv2/summaryv2.component";
import {AppointmentFormV2Component} from "./components/appointment-form-v2/appointment-form-v2.component";
import {BookFormService} from "../../../../core/services/book-form.service";

@Component({
  selector: 'app-new-appointment',
  standalone: true,
  imports: [
    RouterOutlet,
    SummaryComponent,
    AppointmentFormComponent,
    OtherStationsComponent,
    NgIf,
    Summaryv2Component,
    AppointmentFormV2Component
  ],
  templateUrl: './new-appointment.component.html',
  styleUrl: './new-appointment.component.css'
})
export class NewAppointmentComponent {


  currentStep: number =1 ;

  isProfessional: boolean = false;

  stations: Station[] = [];

  loadingStations: boolean = false;

  selectedStation: Station|null = null;

  constructor(private breadcrumbService: BreadcrumbService, private bookFormService: BookFormService,) {
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Dasboard',
      items: [{
        name: 'Nueva cita',
      }]
    });

    this.bookFormService.reservationForm$.subscribe({
      next: (result) => {
        this.currentStep = result.step;
        if (result.steps.step3?.station) {
          this.selectedStation = result.steps.step3.station;
        }
      }
    })
  }

  setStations(stations: Station[]){
    this.stations = stations;
  }
  setProfessional(stae: boolean) {
    this.isProfessional = stae;
    this.bookFormService.reset(undefined, this.isProfessional);
  }

}
