<div class="w-full">

  <ng-template #plate>
    <p class="text-xl text-darkGray">¿Cuál es tu matrícula?</p>
  </ng-template>

  <ng-template #matriculaFormatos>
    <div (click)="toggleDialog()" class="flex flex-row items-center text-xs mt-4 gap-x-2 cursor-pointer">
      <div class="cursor-pointer"><p class="rounded-full border border-primary text-primary w-4 h-4 text-center">?</p></div>
      <p class="text-darkGray">Formatos de matrícula</p>
    </div>
  </ng-template>

  <div (click)="toggleDialog()" class="absolute inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50" *ngIf="visible">
    <div (click)="$event.stopPropagation()" class="max-w-md w-full bg-white rounded-2xl px-4 py-4 mx-auto animate-fade-in-up animate-delay-100">

      <button (click)="toggleDialog()" class="rounded-full absolute -top-1.5 -right-1.5 bg-black text-white p-1">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
      </button>

      <p class="text-left text-darkGray text-xs mb-2 mt-2">Sin guiones ni espacios.</p>
      <div class="w-full gap-14 text-darkGray">
        <table class="min-w-full bg-white rounded-lg">
          <thead>
          <tr class="text-left">
            <th class="px-6 py-3 font-bold text-sm text-darkGray">PATRÓN</th>
            <th class="px-6 py-3 font-bold text-sm text-darkGray">EJEMPLO</th>
          </tr>
          </thead>
          <tbody>
          <tr class="text-left">
            <td class="px-6 pb-4">
              <ul class="list-disc list-inside space-y-1 text-sm text-darkGray">
                <li>LNNNNLL</li>
                <li>NNNNLLL</li>
                <li>PPNNNNLL</li>
                <li>PPNNNNNN</li>
                <li>LNNNNLLL</li>
                <li>LNNNNN</li>
                <li>LLNNNNL</li>
                <li>LNNNNL</li>
                <li>LNNNNNN</li>
                <li>LLNNNN</li>
                <li>LLNNNNN</li>
                <li>LNNNNNL</li>
                <li>LLLNNNNN</li>
                <li>LLNNNNNLL</li>
                <li>LLNNNNNL</li>
              </ul>
            </td>

            <td class="px-6 pb-4">
              <ul class="list-disc list-inside space-y-1 text-sm text-darkGray">
                <li>A1234BC</li>
                <li>1234DCD</li>
                <li>BA1234VD</li>
                <li>GR123456</li>
                <li>A1234CDR</li>
                <li>A12345</li>
                <li>AB1234C</li>
                <li>A1234B</li>
                <li>A123456</li>
                <li>AB1234</li>
                <li>AB12345</li>
                <li>A12345B</li>
                <li>MMA03332</li>
                <li>BA38842VE</li>
                <li>AB01744R</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <p class="text-left text-darkGray text-xs mt-2"><span class="font-bold">"P"</span> : Siglas de la provincia</p>
      <p class="text-left text-darkGray text-xs mt-1"><span class="font-bold">"L"</span>: Carácter alfabético válido</p>
      <p class="text-left text-darkGray text-xs mt-1"><span class="font-bold">"N"</span> : Carácter numérico</p>
    </div>
  </div>


  <div *ngIf="!existingReservation && !oldReservation" class="animate-fade-in-down">
    <p class="text-primary text-2xl mb-6 text-center">Reserva, gestiona o anula tu cita</p>
    <app-card-shadow>
      <ng-container *ngTemplateOutlet="plate"></ng-container>
      <form [formGroup]="step1Form" class="w-full flex flex-col items-center">
        <input formControlName="registration_number" class="outline-none border p-2 md:px-4 md:py-4 rounded-full text-center w-60 md:w-96 mt-2" type="text" placeholder="0000AAA">
        <div class="my-1" *ngIf="step1Form.get('registration_number')?.invalid && step1Form.get('registration_number')?.touched" appFormError> Matrícula no valida</div>
        <div class="my-1" *ngIf="error" appFormError> {{ error }}</div>
        <ng-container *ngTemplateOutlet="matriculaFormatos"></ng-container>
        <div class="max-w-48">
          <button [disabled]="loading" type="button" (click)="next()" appNextButton class="mt-3">
            <span>Continuar</span>
            <span>
            <svg *ngIf="!loading"  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M15 16l4 -4" /><path d="M15 8l4 4" /></svg>
            <app-loader size="extra-small" *ngIf="loading"></app-loader>
          </span>
          </button>
        </div>
      </form>
    </app-card-shadow>
  </div>

  <app-existing-reservation-card (onUpdateReservation)="existingReservation = $event" (onCancelReservation)="existingReservation=$event" *ngIf="existingReservation" [existingReservation]="existingReservation"
                                 (onReturnClick)="back()">
    <ng-container *ngTemplateOutlet="plate"></ng-container>
    <ng-container *ngTemplateOutlet="matriculaFormatos"></ng-container>
  </app-existing-reservation-card>

  <app-old-reservation *ngIf="oldReservation" [oldReservation]="oldReservation" (onReturnClick)="back()"></app-old-reservation>





</div>



