import {Component, OnInit} from '@angular/core';
import {CardShadowComponent} from "../../utils/card-shadow/card-shadow.component";
import {CalendarComponent} from "../../../../dashboard/pages/calendar/calendar.component";
import {MonthCalendarComponent} from "../../../../../shared/components/month-calendar/month-calendar.component";
import {FrontService} from "../../../../../core/services/front/front.service";
import {Store} from "@ngrx/store";
import {selectStep2, selectStep3, selectStep4} from "../../../../../store/reservationForm/stepper.selector";
import {Observable} from "rxjs";
import {Step2, Step3, Step4} from "../../../../../models/Front/ReservationForm";
import {AvailableHour} from "../../../../../models/AvailableHours";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";
import {NextButtonDirective} from "../../../../../shared/directives/buttons/next.directive";
import {updateStep, updateStep3, updateStep4} from "../../../../../store/reservationForm/stepper.actions";
import {Service} from "../../../../../models/Service";
import {Vehicle} from "../../../../../models/Vehicle";
import {Fuel} from "../../../../../models/Fuel";
import {Station} from "../../../../../models/Station";
import {LoaderComponent} from "../../../../../shared/components/loader/loader.component";

@Component({
  selector: 'app-step4',
  standalone: true,
  imports: [
    CardShadowComponent,
    CalendarComponent,
    MonthCalendarComponent,
    NgForOf,
    NgClass,
    FrontBackDirective,
    NextButtonDirective,
    NgIf,
    LoaderComponent
  ],
  templateUrl: './step4.component.html',
  styleUrl: './step4.component.css'
})
export class Step4Component implements OnInit{
  step2$: Observable<Step2 | undefined>;
  step3$: Observable<Step3|undefined>;
  step4$: Observable<Step4 | undefined>;

  step2?: Step2;
  step3?: Step3;

  showMessage: string|null = null;

  blockedDates: string[] = [];



  month = new Date().getMonth() + 1;
  year = new Date().getFullYear();

  selectedDate: string|undefined;
  selectedHour: AvailableHour|undefined;
  hours: AvailableHour[] = [];

  loadingHours: boolean = false;
  loadingDates: boolean = false;

  constructor(private frontService: FrontService,private store: Store) {
    this.step2$ = this.store.select(selectStep2);
    this.step3$ = this.store.select(selectStep3);
    this.step4$ = this.store.select(selectStep4);
   // this.frontService.listAvailableDates(this.month. this.year);
    this.step2$.subscribe(step2 => {
      if (step2) {
        this.step2 = step2;
      }
    });
    this.step3$.subscribe(step3 => {
      this.step3 = step3;
    });
    this.step4$.subscribe(step4 =>{
      this.selectedDate=step4?.date;
      this.selectedHour=undefined;
    })

    this.loadDates();
  }

  ngOnInit() {
    let step4:Step4 = {
      date:this.selectedDate,
      hour:undefined,
    }
    this.store.dispatch(updateStep4({step4}))
   this.loadDates();
    if (this.step2 && this.step3 && this.selectedDate) {
      this.listAvailableHours(this.selectedDate, this.step2.service, this.step2.vehicle, this.step2.fuel, this.step3.station)
    }
  }

  loadDates(){
    if (this.step2 && this.step3) {
      this.listAvailableDates(this.month, this.year, this.step2.service, this.step2.vehicle, this.step2.fuel, this.step3.station);
    }
  }

  onSelectedDay(date: string){
    this.selectedDate = date;

    this.selectedHour = undefined;

    if (this.step2 && this.step3) {
      this.listAvailableHours(date, this.step2.service, this.step2.vehicle, this.step2.fuel, this.step3.station);
    }

  }

  goBack(){
    this.store.dispatch(updateStep({ step: 3 }));
  }

  public nextStep() {
    this.store.dispatch(updateStep({ step: 5 }));
  }

  public update() {
    const step4: Step4 = {
      hour: this.selectedHour,
      date: this.selectedDate,
    };
    this.store.dispatch(updateStep4({step4}));
      // this.stepCompleted.emit();

      this.nextStep();
  }

  nextMonth() {
    // Incrementa el mes actual
    this.month++;

    // Verifica si el mes excede diciembre
    if (this.month > 12) {
      this.month = 1;  // Reinicia el mes a enero
      this.year++;     // Incrementa el año
    }

    this.loadDates();
  }

  /**
   * Retrocede al mes anterior si no se baja del mes 1.
   */
  prevMonth() {
    // Decrementa el mes actual
    this.month--;

    // Verifica si el mes es menor a enero
    if (this.month < 1) {
      this.month = 12; // Ajusta el mes a diciembre
      this.year--;     // Decrementa el año
    }

    this.loadDates();
  }

  listAvailableHours(date: string, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined) {
    this.loadingHours = true;
    this.frontService.listAvailableHours(date, service!.id, vehicle!.id, fuel!.id, station!.id).subscribe({
      next: (response) => {
        if (response.data) {
          this.hours = response.data.hours;
        }
        this.loadingHours = false;
      }, error: err => {
        this.loadingHours = false;
      }
    })
  }

  listAvailableDates(month: number, year: number, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined){
    this.loadingDates = true;
    this.frontService.listAvailableDates(month, year, service!.id, vehicle!.id, fuel!.id, station!.id).subscribe({
      next: (response) => {
        if (response.data && response.success) {
          response.data.forEach(item => {
            if (item.available == 0) {
              this.blockedDates.push(item.date);
            }
            this.loadingDates = false;
          });
        } else if (response.data && !response.success) {
          // @ts-ignore
          this.showMessage = response.data;
        }
      },
      error: err => {
        this.loadingDates = false;
      }
    })
  }

  onSelectHour(hour: AvailableHour){
    this.selectedHour = hour;
  }

  protected readonly Store = Store;
}
