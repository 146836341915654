import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface AsideState {
  type: 'full' | 'icons',
  collapsed: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class AsideService {
  private asideStateSubject: BehaviorSubject<AsideState>;
  public asideState$: Observable<AsideState>;

  private movileMenuOpenSubject: BehaviorSubject<boolean>;
  public movileMenuOpenState$: Observable<boolean>;

  private localStorageKey = 'asideState';

  constructor() {
    // Intenta cargar el estado desde localStorage, si existe
    const initialState = JSON.parse(localStorage.getItem(this.localStorageKey) || '{}') as AsideState;

    // Si no hay estado en localStorage, usa un valor por defecto
    this.asideStateSubject = new BehaviorSubject<AsideState>({
      type: initialState.type || 'full',
      collapsed: initialState.collapsed || false
    });
    this.asideState$ = this.asideStateSubject.asObservable();

    this.movileMenuOpenSubject = new BehaviorSubject<boolean>(false);
    this.movileMenuOpenState$ = this.movileMenuOpenSubject.asObservable();
  }

  private updateLocalStorage(state: AsideState) {
    localStorage.setItem(this.localStorageKey, JSON.stringify(state));
  }

  setIconsMenu() {
    const newState: AsideState = { type: 'icons', collapsed: this.asideStateSubject.value.collapsed };
    this.asideStateSubject.next(newState);
    this.updateLocalStorage(newState);
  }

  setFullMenu() {
    const newState: AsideState = { type: 'full', collapsed: this.asideStateSubject.value.collapsed };
    this.asideStateSubject.next(newState);
    this.updateLocalStorage(newState);
  }

  toggleIconsFullMenu() {
    if (this.asideStateSubject.value.type == 'full') {
      this.setIconsMenu();
    } else {
      this.setFullMenu();
    }
  }

  openMobileMenu(){
    this.movileMenuOpenSubject.next(true);
  }
  closeMobileMenu(){
    this.movileMenuOpenSubject.next(false);
  }
}
