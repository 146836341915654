import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {DatePipe, NgForOf, NgIf, NgSwitch, NgSwitchCase} from "@angular/common";

@Component({
  selector: 'app-active-filters',
  standalone: true,
  imports: [
    DatePipe,
    NgForOf,
    NgIf,
    NgSwitchCase,
    NgSwitch
  ],
  templateUrl: './active-filters.component.html',
})
export class ActiveFiltersComponent {
  @Input() filters: ActiveFilter[] = [];
  @Input() displayNames!: { [key: string]: string };

  @Output() onRemoveFilter = new EventEmitter<{pos: number, filter: ActiveFilter}>();
  @Output() onClearFilters = new EventEmitter<void>();


  getName(value: any, column: string){
    return value[column];
  }
}
