import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {DashboardSafeZoneComponent} from "./components/dashboard-safe-zone/dashboard-safe-zone.component";
import {DashboardNavbarComponent} from "./components/dashboard-navbar/dashboard-navbar.component";
import {DashboardAsideComponent} from "./components/dashboard-aside/dashboard-aside.component";
import {NgTemplateOutlet} from "@angular/common";
import {NgxSpinnerComponent} from "ngx-spinner";

@Component({
  selector: 'app-dashboard-layout',
  standalone: true,
    imports: [
        DashboardSafeZoneComponent,
        DashboardNavbarComponent,
        DashboardAsideComponent,
        NgTemplateOutlet,
        NgxSpinnerComponent
    ],
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.css'
})
export class DashboardLayoutComponent {

}
