<button class="w-full bg-primary text-white hover:shadow rounded-md p-1 px-2" (click)="export.toggle($event)">Exportar</button>

<p-overlayPanel #export>
  <ul class="flex flex-col gap-2">
    <li  *ngIf="show('json')" class="cursor-pointer hover:bg-lightGray2 text-darkGray p-2"  (click)="onExportClick.emit('json'); export.toggle($event)">
      JSON
    </li>
    <li  *ngIf="show('csv')" class="cursor-pointer hover:bg-lightGray2 text-darkGray p-2"   (click)="onExportClick.emit('csv'); export.toggle($event)">
      CSV
    </li>
    <li  *ngIf="show('text')" class="cursor-pointer hover:bg-lightGray2 text-darkGray p-2"   (click)="onExportClick.emit('text'); export.toggle($event)">
      TEXTO
    </li>
  </ul>
</p-overlayPanel>
