import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {Vehicle} from "../../../models/Vehicle";
import {environment} from "../../../../environments/environment";
import {Service} from "../../../models/Service";
import {Reservation} from "../../../models/Reservation";
import {Paginator} from "../../../models/Paginator";
import {SortColumn} from "../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private http: HttpClient) { }

  listpPaginate(sorts: SortColumn[] = [], filters: ActiveFilter[] = [], perPage = 50): Observable<Response<Paginator<Service>>>{
    return this.http.get<Response<Paginator<Service>>>(`${environment.API_URL}/services/list-paginate/${perPage}`)
  }

  list(): Observable<Response<Service[]>>{
    return this.http.get<Response<Service[]>>(`${environment.API_URL}/services/list`)
  }

  update(serviceId: number, data: any): Observable<Response<Service>>{
    const formData: FormData = new FormData();

    // Agregar datos al FormData
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    return this.http.post<Response<Service>>(`${environment.API_URL}/services/update/${serviceId}`, formData)
  }
}
