
<div *ngIf="user">

  <div *ngIf="user.roles[0] != 'operador'" class="w-full">
    <div  class="grid grid-cols-1 md:grid-cols-6 gap-8">
      <div class="bg-bg2 px-4 py-2 md:col-span-5 rounded-lg shadow">
        <div class="flex justify-center items-center gap-x-16">
          <img src="/assets/img/ilustracion.png" class="h-44" alt="">
          <div>
            <p class="text-primary text-xl font-bold">Bienvenido al gestor de estaciones de Itevebasa</p>
            <span class="text-darkGray text-sm">Aquí podrás gestionar tus estaciones, servicios y las citas de tus usuarios.</span>
            <div class="mt-4 flex gap-4">
              <a routerLink="/dashboard/estaciones/nueva" *ngIf="canCreateStations" appNextButton>Nueva estación</a>
              <a  routerLink="/dashboard/nueva-cita" *ngIf="canAddNewBooks" class="bg-green2 text-green border border-green py-2 px-8 rounded-2xl hover:shadow">Nueva cita</a>
            </div>
          </div>
        </div>
      </div>
      <div class="md:col-span-1 bg-bg2 px-4 py-2 rounded-lg shadow">
        <div class="my-auto flex items-center w-full h-full justify-center" *ngIf="loadingInfo">
          <app-loader size="large"></app-loader>
        </div>
        <div class="border-b border-lightGray2 pb-2" *ngIf="!loadingInfo">
          <span class="text-darkGray text-xs">Año actual</span>
          <p class="text-primary">Total reservas</p>
          <p class="text-darkGray text-2xl font-bold">{{ total | number }}</p>
        </div>
        <div class="grid grid-cols-2"  *ngIf="!loadingInfo">
          <div class="flex flex-col justify-start">
            <span class="text-darkGray text-sm">Pasadas</span>
            <span class="text-darkGray font-bold">{{ total_true | number }}</span>
          </div>
          <div class="flex flex-col justify-start">
            <span class="text-darkGray text-sm">Pendientes</span>
            <span class="text-primary">{{ total_false  | number }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-12">
      <span class="text-xs text-darkGray">Año actual</span>
      <p class="text-primary text-xl font-bold">Resumen por estación</p>
      <div class="w-full mt-4">
        <app-generic-table [tableHeaders]="tableHeaders" [items2]="data" [loading]="loadingInfo"></app-generic-table>
      </div>

    </div>


  </div>


  <div *ngIf="user.roles[0] == 'operador'">
    <app-calendar></app-calendar>
  </div>


</div>
