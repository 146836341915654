<div class="w-full block lg:flex">

  <div [ngClass]="!showSummary ? 'w-full lg:w-full' : 'w-full lg:w-5/6'" class="duration-300">
    <app-stepper></app-stepper>
    <div *ngIf="user" class="w-full bg-yellow text-darkGray text-center py-1">
      <p *ngIf="user.profile">Estás logueado como: {{ user.profile.email }} - {{ user.roles }} - <a routerLink="/dashboard">Dashboard</a></p>
    </div>
    <div [class.max-w-7xl]="(currentStep == 4)" [class.max-w-4xl]="currentStep == 7"
         [class.max-w-xl]="(currentStep != 4 && currentStep != 7)" class="w-full mx-auto mt-12 lg:mt-24">
      <app-form></app-form>
    </div>
    <button *ngIf="currentStep > 1 && currentStep < 7" (click)="toggleSummary()"
            class="absolute top-10 right-10  hover:shadow-2xl duration-300">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
           stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
           class="w-6 h-6 text-darkGray">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M15 15h2"/>
        <path d="M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z"/>
        <path d="M11 12h6"/>
        <path d="M13 9h4"/>
      </svg>
    </button>
  </div>

  <div [ngClass]="!showSummary ? 'w-0' : 'w-1/6'" class="duration-300">
    <app-summary [ngClass]="{'translate-x-0': showSummary, 'translate-x-full': !showSummary}"
                 class="fixed top-0 right-0 w-80 h-screen bg-gray-100 shadow-xl transition-transform duration-300 py-8 px-6"></app-summary>
  </div>

</div>



<!--
<div class="min-h-screen bg-bg2 flex flex-col lg:hidden">
  <app-stepper></app-stepper>
  <div *ngIf="user" class="w-full bg-yellow text-darkGray text-center py-1">
    <p *ngIf="user.profile">Estás logueado como: {{ user.profile.email }} - {{ user.roles }} - <a routerLink="/dashboard">Dashboard</a></p>
  </div>
  <div [class.max-w-7xl]="(currentStep == 4)" [class.max-w-4xl]="currentStep == 7"
       [class.max-w-xl]="(currentStep != 4 && currentStep != 7)" class="w-full mx-auto mt-24">
    <app-form></app-form>
  </div>
  <button *ngIf="currentStep > 1 && currentStep < 7" (click)="toggleSummary()"
          class="absolute top-10 right-10 hover:shadow-2xl duration-300">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
         stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
         class="w-6 h-6 text-darkGray">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M15 15h2"/>
      <path d="M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z"/>
      <path d="M11 12h6"/>
      <path d="M13 9h4"/>
    </svg>
  </button>
  <app-summary [ngClass]="{'translate-x-0': showSummary, 'translate-x-full': !showSummary}"
               class="fixed top-0 right-0 w-80 h-screen bg-gray-100 shadow-xl transition-transform duration-300 py-8 px-6"></app-summary>


</div>-->
