<div>
  <app-generic-table class="col-span-1 order-2 xl:order-1 xl:col-span-2"
                     [loading]="loading"
                     [tableHeaders]="tableHeaders"
                     (changeSorts)="onChangeSorts($event)"
                     [items]="fuels">

    <app-table-header-wrapper GTHeader >
      <!--<app-user-table-filters [perPageOptions]="[50,100,150,200]" (onChangePerPage)="perPage = $event; loadReservations()" [perPage]="perPage" [tableHeaders]="tableHeaders" [filters]="filters" (onChangeFilters)="onChangeFilters($event)"></app-user-table-filters>-->
      <app-table-filters
        [loading]="loadingFilters"
        [perPageOptions]="[50,100,150,200]"
        [perPage]="perPage"
        (onChangeFilters)="onChangeFilters($event)"
        [tableHeaders]="tableHeaders">

      </app-table-filters>
    </app-table-header-wrapper>
  </app-generic-table>
</div>
