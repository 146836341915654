import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Reservation} from "../../../../models/Reservation";
import {CardShadowComponent} from "../utils/card-shadow/card-shadow.component";
import {DatePipe, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {FrontBackDirective} from "../../../../shared/directives/buttons/front.back.directive";
import {OutlineButtonDirective} from "../../../../shared/directives/outline/outline-button.directive";
import {OutlineInputDirective} from "../../../../shared/directives/outline/outline-input.directive";
import {ReservationGesService} from "../../../../core/services/reservations/reservation-ges.service";
import {environment} from "../../../../../environments/environment";
import {FormsModule} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {FormInputBlueNoFullDirective} from "../../../../shared/directives/forms/form-input-blue-no-full.directive";
import {State} from "../../../../models/State";
import {Station} from "../../../../models/Station";
import {FrontService} from "../../../../core/services/front/front.service";
import {FormSelectSquareDirective} from "../../../../shared/directives/forms/form-select-square.directive";
import {MonthCalendarComponent} from "../../../../shared/components/month-calendar/month-calendar.component";
import {NextSquareButtonDirective} from "../../../../shared/directives/buttons/next-square.directive";
import {Service} from "../../../../models/Service";
import {Vehicle} from "../../../../models/Vehicle";
import {Fuel} from "../../../../models/Fuel";
import {AvailableHour} from "../../../../models/AvailableHours";
import {NextButtonDirective} from "../../../../shared/directives/buttons/next.directive";
import {NextOutlinedButtonDirective} from "../../../../shared/directives/buttons/next-outlined.directive";
import {forkJoin} from "rxjs";
import {ReservationService} from "../../../../core/services/reservations/reservation.service";
import {Quadrant} from "../../../../models/Quadrant";
import {StationService} from "../../../../core/services/stations/station.service";
@Component({
  selector: 'app-existing-reservation-card',
  standalone: true,
  imports: [
    CardShadowComponent,
    DatePipe,
    FrontBackDirective,
    NgIf,
    NgTemplateOutlet,
    OutlineButtonDirective,
    OutlineInputDirective,
    FormsModule,
    LoaderComponent,
    FormInputBlueNoFullDirective,
    NgForOf,
    FormSelectSquareDirective,
    MonthCalendarComponent,
    NextSquareButtonDirective,
    NextButtonDirective,
    NextOutlinedButtonDirective
  ],
  templateUrl: './existing-reservation-card.component.html',
  styleUrl: './existing-reservation-card.component.css'
})
export class ExistingReservationCardComponent implements OnInit {
  @Input() frontMode:boolean=true;
  @Input() editView:boolean=false;
  @Input() existingReservation!:Reservation;
  @Output() onReturnClick = new EventEmitter();
  @Output() onEditReservation:EventEmitter<boolean> = new EventEmitter()
  @Output() onCloseEditView:EventEmitter<boolean> = new EventEmitter()
  @Output() onUpdateReservation = new EventEmitter<Reservation>();
  @Output() onCancelReservation = new EventEmitter<undefined>;
  selectHour:boolean=false;
  email: string = "";
  loadingSms: boolean = false;
  loadingEmail: boolean = false;
  loadingCancel: boolean = false;
  loadingReset:boolean = false;
  loading:boolean = false;
  loadingDates:boolean=false;
  loadingHours:boolean=false;
  selectedService?:Service;
  selectedVehicle?:Vehicle;
  selectedFuel?:Fuel;
  selectedState?: State;
  selectedStation?: Station;
  quadrants?:Quadrant[];
  initQuadrant?:Quadrant;
  selectedQuadrant?:Quadrant;
  month = new Date().getMonth() + 1;
  year = new Date().getFullYear();
  selectedDate: string|undefined;
  selectedHour: AvailableHour|undefined;
  hours: AvailableHour[] = [];
  @Input() editReservation:boolean = false;
  @Input() admin:boolean = false;
  services?:Service[];
  vehicles?:Vehicle[];
  fuels?:Fuel[];
  states?: State[];
  stations?: Station[];
  blockedDates: string[] = [];

  confirmed: boolean = false;
  @Input() phoneConfirm: string = "";

  constructor(private stationService:StationService,private reservationService:ReservationService,private reservationGesService:ReservationGesService, private toastrService: ToastrService, private frontService:FrontService) {

  }

  isSelectedSameAsExisting(){
    if(this.selectHour){
      return this.existingReservation.service_id === this.selectedService?.id &&
        this.existingReservation.vehicle_id === this.selectedVehicle?.id &&
        this.existingReservation.fuel_id === this.selectedFuel?.id &&
        this.existingReservation.station_id === this.selectedStation?.id &&
        this.existingReservation.date === this.selectedDate &&
        this.existingReservation.station.state_id === this.selectedState?.id &&
        this.selectedHour?.id===this.existingReservation.hour.id
    } else {
      return this.existingReservation.service_id === this.selectedService?.id &&
        this.existingReservation.vehicle_id === this.selectedVehicle?.id &&
        this.existingReservation.fuel_id === this.selectedFuel?.id &&
        this.existingReservation.station_id === this.selectedStation?.id &&
        this.existingReservation.date === this.selectedDate &&
        this.existingReservation.station.state_id === this.selectedState?.id
    }

  }



  ngOnInit() {
    this.email = this.existingReservation ? this.existingReservation.email ?? '' : '';
    if(this.editView){
      this.goToEdit();
    }
  }

  initLoadServices(){
    this.frontService.listServices().subscribe(
      {
        next: (response) => {
          if (response.data) {
            this.services = response.data;
            this.setSelectedService();
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }


  private setSelectedService() {
    if (this.services) {
      // Busca la estación en la lista de estaciones que coincida con la de la reserva existente
      this.selectedService = this.services.find(service => service.id === this.existingReservation.service_id);
    }
  }

  initLoadVehicles() {
    this.loading = true;
    if(this.existingReservation){
      this.frontService.listVehicles(this.existingReservation.service_id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.vehicles=response.data;
              this.setSelectedVehicle();
            }
            this.loading = false;
          },
          error: err => {
            console.log(err);
            this.loading = false;
          }
        }
      );
    }
  }

  loadVehicles() {
    this.loading = true;
    this.loadFuels()
    if(this.selectedService){
      this.frontService.listVehicles(this.selectedService.id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.vehicles=response.data;
              console.log(this.vehicles);
              if(!this.vehicles.some(v => v.id === this.selectedVehicle?.id)){
                this.selectedVehicle = undefined;
              } else {
                this.selectedVehicle = this.vehicles.find(v => v.id === this.selectedVehicle?.id)
              }
            }
            this.loading = false;
          },
          error: err => {
            console.log(err);
            this.loading = false;
          }
        }
      );
    }
  }

  private setSelectedVehicle() {
    if (this.vehicles) {
      // Busca la estación en la lista de estaciones que coincida con la de la reserva existente
      this.selectedVehicle = this.vehicles.find(vehicle => vehicle.id === this.existingReservation.vehicle_id);
    }
  }

  initLoadFuels(){
    this.loading = true;
    if(this.existingReservation){
      this.frontService.listFuels(this.existingReservation.service_id,this.existingReservation.vehicle_id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.fuels=response.data;
              this.setSelectedFuel();
            }
            this.loading = false;
          },
          error: err => {
            this.loading = false;
          }
        }
      );
    }
  }

  loadFuels(){
    this.loading = true;
    this.loadStates();
    if(this.selectedService && this.selectedVehicle){
      this.frontService.listFuels(this.selectedService.id,this.selectedVehicle.id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.fuels=response.data;
              console.log(this.fuels);
              console.log(this.selectedFuel);

              if(!this.fuels.some(f=>f.id === this.selectedFuel?.id)){
                this.selectedFuel = undefined;
              } else {
                this.selectedFuel = this.fuels.find(f => f.id === this.selectedFuel?.id);
              }
            }
            this.loading = false;
          },
          error: err => {
            this.loading = false;
          }
        }
      );
    }
  }

  private setSelectedFuel() {
    if (this.fuels) {
      // Busca la estación en la lista de estaciones que coincida con la de la reserva existente
      this.selectedFuel = this.fuels.find(fuel => fuel.id === this.existingReservation.fuel_id);
    }
  }

  initLoadStates(){
    if(this.existingReservation)
    this.frontService.listStates(this.existingReservation.service_id,this.existingReservation.vehicle_id,this.existingReservation.fuel_id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.states = response.data;
            this.setSelectedState();
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  loadStates(){
    this.loadStations();
    if(this.selectedService && this.selectedVehicle && this.selectedFuel){
      this.frontService.listStates(this.selectedService.id,this.selectedVehicle.id,this.selectedFuel.id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.states = response.data;
              console.log(this.states);
              console.log(this.selectedState);
              if(!this.states.some(s => s.id === this.selectedState?.id)){
                this.selectedState = undefined;
              } else {
                this.selectedState=this.states.find(s => s.id === this.selectedState?.id);
              }
            }
            this.loading = false;
          },
          error: err => {
            this.loading = false;
          }
        }
      );
    }
  }

  private setSelectedState() {
    if (this.states) {

      this.selectedState = this.states.find(state => state.id === this.existingReservation.station.state_id);
    }
  }

  private setSelectedStation() {
    if (this.stations) {
      // Busca la estación en la lista de estaciones que coincida con la de la reserva existente
      this.selectedStation = this.stations.find(station => station.id === this.existingReservation.station.id);
    }
  }

  public initLoadStations(){
    this.loading = true;
    if (this.existingReservation) {
      this.frontService.listStations(this.existingReservation.service_id, this.existingReservation.vehicle_id, this.existingReservation.fuel_id, this.existingReservation.station.state_id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.stations = response.data;
              this.setSelectedStation();
              this.initLoadDates();
            }
            this.loading = false;
          },
          error: err => {
            console.log(err.error.message);
            this.loading = false;
          }
        }
      );
    }
  }

  public loadStations(){
    this.loadDates();
    this.loading = true;
    if (this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState) {
      this.frontService.listStations(this.selectedService.id,this.selectedVehicle.id,this.selectedFuel.id,this.selectedState.id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.stations = response.data;
              console.log(this.stations)
              if(!this.stations.some(s=> s.id === this.selectedStation?.id)){
                this.selectedStation = undefined;
              } else {
                this.selectedStation = this.stations.find(s => s.id === this.selectedStation?.id)
              }
            }
            this.loading = false;
          },
          error: err => {
            console.log(err);
            this.loading = false;
          }
        }
      );
    }
  }
  /*downloadProof(){
    this.reservationGesService.downloadProof(this.existingReservation.id).subscribe({
      next: (response) => {
        // @ts-ignore
        const blob = new Blob([response.blob], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = response.fileName;
        a.click();
        window.URL.revokeObjectURL(url); // Liberar el objeto URL
      },
      error: (error) => {
        console.log(error);
      }
    })
  }*/

  private setSelectedDate() {
    if (this.blockedDates) {
      this.onSelectedDay(this.existingReservation.date);
    }
  }

  initLoadDates(){
    if (this.existingReservation) {
      this.month = new Date(this.existingReservation.date).getMonth()+1;
      this.initListAvailableDates(this.month, this.year, this.existingReservation.service, this.existingReservation.vehicle, this.existingReservation.fuel, this.existingReservation.station);
      this.setSelectedDate();
        this.initListAvailableHours(this.existingReservation.date, this.existingReservation.service, this.existingReservation.vehicle, this.existingReservation.fuel, this.existingReservation.station);
    }
  }

  loadDates(){

    if (this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState && this.selectedStation) {
      this.listAvailableDates(this.month, this.year, this.selectedService, this.selectedVehicle, this.selectedFuel, this.selectedStation);
    }
  }

  nextMonth() {
    // Incrementa el mes actual
    this.month++;

    // Verifica si el mes excede diciembre
    if (this.month > 12) {
      this.month = 1;  // Reinicia el mes a enero
      this.year++;     // Incrementa el año
    }

      this.loadDates();
  }

  /**
   * Retrocede al mes anterior si no se baja del mes 1.
   */
  prevMonth() {
    // Decrementa el mes actual
    this.month--;

    // Verifica si el mes es menor a enero
    if (this.month < 1) {
      this.month = 12; // Ajusta el mes a diciembre
      this.year--;     // Decrementa el año
    }

    this.loadDates();
  }

  initListAvailableDates(month: number, year: number, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined){
    this.loadingDates = true;
    this.frontService.listAvailableDates(month, year, service!.id, vehicle!.id, fuel!.id, station!.id).subscribe({
      next: (response) => {
        if (response.data) {
          response.data.forEach(item => {
            if (item.available == 0) {
              //let month = new Date(this.existingReservation.date).getMonth();
              this.blockedDates.push(item.date);
            }
          });
          if(this.existingReservation){
            this.selectedDate = this.existingReservation.date;
          }
          this.loadingDates = false;
        }
      },
      error: err => {
        this.loadingDates = false;
      }
    })
  }

  listAvailableDates(month: number, year: number, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined){
    this.loadingDates = true;
    this.loadHours();
    this.blockedDates = [];
    this.frontService.listAvailableDates(month, year, service!.id, vehicle!.id, fuel!.id, station!.id).subscribe({
      next: (response) => {
        if (response.data) {
          response.data.forEach(item => {
            if (item.available == 0) {
              this.blockedDates.push(item.date);
            }
            this.loadingDates = false;
          });
          if(this.blockedDates.some(date => date === this.selectedDate)){
            this.selectedDate=undefined;
          }
        }
      },
      error: err => {
        this.selectedDate=undefined;
        this.loadingDates = false;
      }
    })
  }

  initListAvailableHours(date: string, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined) {
    this.loadingHours = true;
    this.frontService.listAvailableHours(date, service!.id, vehicle!.id, fuel!.id, station!.id).subscribe({
      next: (response) => {
        if (response.data) {
          this.hours = response.data.hours;
        }
        this.loadingHours = false;
      }, error: err => {
        this.loadingHours = false;
      }
    })
  }

  listAvailableHours(date: string, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined) {
    this.loadingHours = true;
    this.frontService.listAvailableHours(date, service!.id, vehicle!.id, fuel!.id, station!.id).subscribe({
      next: (response) => {
        if (response.data) {
          this.hours = response.data.hours;
          if(!this.hours.some(h => h.id === this.selectedHour?.id)){
            if(this.selectedHour?.id !== this.existingReservation.hour.id){
              this.selectedHour = undefined;
            }
          }
        }
        this.loadingHours = false;
      }, error: err => {
        this.loadingHours = false;
      }
    })
  }

  loadHours(){
    if (this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState && this.selectedStation && this.selectedDate && this.selectedDate !== '') {
      this.listAvailableHours(this.selectedDate, this.existingReservation.service, this.existingReservation.vehicle, this.existingReservation.fuel, this.existingReservation.station);
    }
  }

  onInitSelectedDay(date: string){
    this.selectedDate = date;

    this.selectedHour = undefined;

    if (this.existingReservation) {
      this.listAvailableHours(date, this.existingReservation.service, this.existingReservation.vehicle, this.existingReservation.fuel, this.existingReservation.station);
    }

  }

  onSelectedDay(date: string){
    this.selectedDate = date;

    this.selectedHour = undefined;

    if (this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState && this.selectedStation && this.selectedDate !== '') {
      this.listAvailableHours(date, this.existingReservation.service, this.existingReservation.vehicle, this.existingReservation.fuel, this.existingReservation.station);
    }

  }

  toggleEditReservation(){
    this.editReservation = !this.editReservation;
    this.onEditReservation.emit(this.editReservation);
  }

  goToEdit(){
    this.toggleEditReservation();
    this.initLoadServices();
    this.initLoadVehicles();
    this.initLoadFuels();
    this.initLoadStates();
    this.initLoadStations();
  }
  reset(){
    this.loadingReset=true;
    this.selectHour = false;
    this.initLoadServices();
    this.initLoadVehicles();
    this.initLoadFuels();
    this.initLoadStates();
    this.initLoadStations();
    this.loadingReset=false;
  }

  return(){
    this.toggleEditReservation()
    this.editReservation = false;
  }

  sendProofToEmail(){
    this.loadingEmail = true;
    this.reservationGesService.sendProofToEmail(this.existingReservation.id, this.email, this.phoneConfirm).subscribe({
      next: (result) => {
        if (result.success) {
          this.toastrService.success(result.message);
        }
        this.loadingEmail = false;
      },
      error: (error) => {
        this.toastrService.error(error.error.message);
        this.loadingEmail = false;
      }
    })
  }

  sendMessage(){
    if(this.existingReservation){
      this.loadingSms = true;
      const formattedDate = new Date(this.existingReservation?.date).toLocaleDateString('es-ES', {
        weekday: 'long', // Nombre del día de la semana
        day: 'numeric',  // Día del mes
        month: 'long',   // Nombre completo del mes
        year: 'numeric'  // Año con cuatro dígitos
      });
      let data = {
        phone:this.existingReservation?.phone,
        message:this.existingReservation?.registration_number+' tiene cita: '+ formattedDate +' a las '+ this.existingReservation.hour.hour +' en ' + this.existingReservation.station.name +'. Gracias por confiar en Itevebasa.'
      }
      this.reservationGesService.sendMessage(data).subscribe({
        next:(response) =>{
          if(response.success){
            console.log(response.data)
          }
          this.loadingSms=false;
        },

        error:(err) => {
          console.log(err.error.message);
          this.loadingSms=false;
        }
      });
    }
  }

  closeEditModal(){
    this.reset()
    this.onCloseEditView.emit(false);
  }

  update(){
    if(this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState && this.selectedStation && this.selectedDate){
      let data = {
        service_id:this.selectedService.id,
        vehicle_id:this.selectedVehicle.id,
        fuel_id:this.selectedFuel.id,
        station_id:this.selectedStation.id,
        date:this.selectedDate,
        hour_id:this.selectHour ? this.selectedHour?.id : this.existingReservation.hour.id,
      }
      this.existingReservation.date;
      if(this.isSelectedSameAsExisting()){
        this.toastrService.info('No has realizado ningún cambio')
      } else {
        if(this.frontMode){

          this.reservationService.update(this.existingReservation.id, data, this.phoneConfirm).subscribe({
              next: (result) => {
                if (result.success) {
                  this.toastrService.success(result.message);
                  if(result.data){
                    console.log(result.data);
                    this.existingReservation = result.data;
                    this.onUpdateReservation.emit(this.existingReservation);
                    this.selectHour=false;
                  }
                  this.return();
                } else {
                  this.toastrService.info(result.message);
                }
                this.loadingCancel = false;
              },
              error: (error) => {
                this.toastrService.error(error.error.message);
                this.loadingCancel = false;
              }
            }

          );

        } else {

          this.reservationService.updateAdmin(this.existingReservation.id,data).subscribe({
              next: (result) => {
                if (result.success) {
                  this.toastrService.success(result.message);
                  if(result.data){
                    console.log(result.data);
                    this.existingReservation = result.data;
                    this.onUpdateReservation.emit(this.existingReservation);
                    this.selectHour=false;
                  }
                  if(this.editView){
                    this.onCloseEditView.emit(false);
                  }else {
                    this.return();
                  }
                } else {
                  this.toastrService.info(result.message);
                }
                this.loadingCancel = false;
              },
              error: (error) => {
                this.toastrService.error(error.error.message);
                this.loadingCancel = false;
              }
            }

          );
        }
      }

    }
  }

  cancel(){
    this.loadingCancel = true;
    this.reservationGesService.cancel(this.existingReservation.id, this.phoneConfirm).subscribe({
      next: (result) => {
        if (result.success) {
          this.toastrService.success(result.message);
          this.onCancelReservation.emit(undefined);
        }

        this.loadingCancel = false;
      },
      error: (error) => {
        this.toastrService.error(error.error.message);
        this.loadingCancel = false;
      }
    })
  }

  checkPhoneNumber(){
    if (this.phoneConfirm != "") {
      console.log(this.existingReservation.registration_number);
      console.log(this.phoneConfirm);
      this.reservationGesService.check(this.existingReservation.id, this.phoneConfirm).subscribe({
        next: (result) => {
          if (result.data) {
            this.confirmed = true;
            // this.toastrService.success(result.message);
          } else {
            this.toastrService.info(result.message);
          }
        },
        error: (error) => {
          // console.log(error.error.message);
          this.toastrService.info(error.error.message);
        }
      })
    }
  }

  protected readonly environment = environment;
}
