<ng-template #template>


  <aside [ngClass]="{'w-80': asideState.type === 'full'}" class="pb-8 hidden md:inline bg-bg2 h-screen overflow-scroll">

    <div class="sticky top-0 bg-bg2 z-20 pb-4 pt-8 px-8 ">
      <img [ngClass]="{'hidden': asideState.type === 'icons'}" class="w-24 animate-fade-in" src="assets/img/logo.svg" alt="Logo itevebasa">
      <div [ngClass]="{'hidden': asideState.type === 'full'}" class="flex items-center justify-center">
        <img  class="w-14 inline animate-fade-in" src="assets/img/logo-min.svg" alt="Logo itevebasa">
      </div>

    </div>

    <div [ngClass]="{'items-center': asideState.type === 'icons'}" class="flex flex-col gap-4 mt-4">
      <ng-container *ngFor="let menu of menuItems" >
        <app-aside-nav-item *ngIf="permissionCheck(menu)"  [asideState]="asideState" [menuItem]="menu"></app-aside-nav-item>
      </ng-container>

    </div>
    <div class="w-full text-center mt-8">
      <a routerLink="/dashboard/nueva-cita" class="mt-12 bg-green2 text-green border border-green py-2 px-4 rounded-2xl">
        <span *ngIf="asideState.type === 'full'">Nueva cita</span>
        <span *ngIf="asideState.type === 'icons'">+</span>
      </a>
    </div>

  </aside>


  <aside [ngClass]="{'hidden': !mobileMenuOpen}" class="absolute inset-0 z-40 hidden">
    <div id="menu-mask" class="absolute inset-0 mask" (click)="asideService.closeMobileMenu()"></div>
    <button class="text-black rounded-full bg-bg2 fixed right-2 top-2 z-20 p-2" (click)="asideService.closeMobileMenu()">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-8 stroke-black" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M18 6l-12 12" />
        <path d="M6 6l12 12" />
      </svg>
    </button>

    <div [@slideInOut]="menuState" id="menu-sidebar" class="absolute inset-0 overflow-y-auto top-0 left-0 bottom-0 w-3/4 bg-bg2 z-50 pb-16 pt-8 flex gap-4 flex-col">



      <ng-container *ngFor="let menu of menuItems" >
        <app-aside-nav-item *ngIf="permissionCheck(menu)" [menuItem]="menu" [asideState]="{type: 'full', collapsed: asideState.collapsed}"></app-aside-nav-item>
      </ng-container>
    </div>
  </aside>

</ng-template>
