import { Component } from '@angular/core';

@Component({
  selector: 'app-table-header-wrapper',
  standalone: true,
  imports: [],
  template: '<div class="w-full p-4 border-b border-lightGray2 "> <ng-content></ng-content> </div>',
})
export class TableHeaderWrapperComponent {

}
