import { Routes } from '@angular/router';
import {LoginComponent} from "./pages/auth/login/login.component";
import {FrontComponent} from "./pages/front/front.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {HomeComponent} from "./pages/dashboard/pages/home/home.component";
import {AuthService} from "./core/services/auth/auth.service";
import {authGuard} from "./core/guards/auth.guard";
import {loginGuard} from "./core/guards/login.guard";
import {ComunityComponent} from "./pages/dashboard/pages/comunity/comunity.component";
import {StationsComponent} from "./pages/dashboard/pages/stations/stations.component";
import {ServicesComponent} from "./pages/dashboard/pages/services/services.component";
import {VehiclesComponent} from "./pages/dashboard/pages/vehicles/vehicles.component";
import {FuelsComponent} from "./pages/dashboard/pages/fuels/fuels.component";
import {QuadrantsComponent} from "./pages/dashboard/pages/quadrants/quadrants.component";
import {CalendarComponent} from "./pages/dashboard/pages/calendar/calendar.component";
import {StatisticsComponent} from "./pages/dashboard/pages/statistics/statistics.component";
import {UsersComponent} from "./pages/dashboard/pages/users/users.component";
import {ResetPasswordComponent} from "./pages/auth/reset-password/reset-password.component";
import {ForgotPasswordComponent} from "./pages/auth/forgot-password/forgot-password.component";
import {HomeStationsComponent} from "./pages/dashboard/pages/stations/pages/home-stations/home-stations.component";
import {NewStationComponent} from "./pages/dashboard/pages/stations/pages/new-station/new-station.component";
import {NewAppointmentComponent} from "./pages/dashboard/pages/new-appointment/new-appointment.component";
import {ConfirmPageComponent} from "./pages/front/pages/confirm-page/confirm-page.component";
import {
  StationsBlockedComponent
} from "./pages/dashboard/pages/stations/pages/stations-blocked/stations-blocked.component";
import {EditStationComponent} from "./pages/dashboard/pages/stations/pages/edit-station/edit-station.component";
import {CanceladasComponent} from "./pages/dashboard/pages/canceladas/canceladas.component";
import {SmsComponent} from "./pages/dashboard/pages/sms/sms.component";
import {
  NewProfessionalAppointmentComponent
} from "./pages/dashboard/pages/new-professional-appointment/new-professional-appointment.component";

export const routes: Routes = [
  {
    path: '',
    component: FrontComponent,
  },
  /*{
    path:'confirm/:id',
    component:ConfirmPageComponent,
  },*/
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginGuard]
  },
  {
    path: 'reset-password/:token/:email',
    component: ResetPasswordComponent,
    canActivate: [loginGuard]
  },
  {
    path: 'olvidaste-tu-contrasena',
    component: ForgotPasswordComponent,
    canActivate: [loginGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'inicio',
        component: HomeComponent
      },
      {
        path: 'comunidades',
        component: ComunityComponent
      },
      {
        path: 'estaciones',
        component: StationsComponent,
        children: [
          {
            path: '',
            component: HomeStationsComponent
          },
          {
            path: 'nueva',
            component: NewStationComponent
          },
          {
            path: 'editar/:id',
            component: EditStationComponent
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: ''
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: ''
          }
        ]
      },
      {
        path: 'servicios',
        component: ServicesComponent
      },
      {
        path: 'tipos-vehiculo',
        component: VehiclesComponent
      },
      {
        path: 'combustibles',
        component: FuelsComponent
      },
      {
        path: 'cuadrantes',
        component: QuadrantsComponent
      },
      {
        path: 'cuadrantes/:station/:quadrant',
        component: QuadrantsComponent
      },
      {
        path: 'agenda',
        component: CalendarComponent
      },
      {
        path: 'agenda-canceladas',
        component: CanceladasComponent
      },
      {
        path: 'estadisticas',
        component: StatisticsComponent
      },
      {
        path: 'usuarios',
        component: UsersComponent
      },
      {
        path: 'sms',
        component: SmsComponent
      },
      {
        path:'nueva-cita',
        component: NewAppointmentComponent,
      },
      /*{
        path:'nueva-cita-profesionales',
        component: NewProfessionalAppointmentComponent,
      },*/
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'inicio'
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'inicio'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
