import { Directive } from '@angular/core';

@Directive({
  selector: '[appNextButton]',
  host: {
    'class': 'bg-primary text-center disabled:bg-darkGray text-white shadow-md hover:shadow-xl py-2 px-6 rounded-2xl font-light flex items-center justify-center gap-4 duration-300 cursor-pointer'
  },
  standalone: true
})
export class NextButtonDirective {

  constructor() { }

}
