<div class="flex flex-col gap-y-6 justify-center text-center mx-auto  my-auto duration-300 px-6">

  <app-step1  *ngIf="currentStep == 1" ></app-step1>
  <app-step2  *ngIf="currentStep == 2" ></app-step2>
  <app-step3  *ngIf="currentStep == 3" ></app-step3>
  <app-step4  *ngIf="currentStep == 4" ></app-step4>
  <app-step5  *ngIf="currentStep == 5" ></app-step5>
  <app-step6  *ngIf="currentStep == 6" ></app-step6>
  <app-step7  *ngIf="currentStep == 7 || currentStep == 8" ></app-step7>

</div>
