<div class="w-full">

  <app-active-filters *ngIf="displayNames" [filters]="filters" [displayNames]="displayNames" (onClearFilters)="clearFilters()" (onRemoveFilter)="removeFilter($event)"></app-active-filters>

  <div class="flex justify-between items-center w-full">
    <form [formGroup]="filtersForm" class="flex gap-x-4 items-center justify-start">

      <input appAuthFormInput *ngIf="filtersForm && hasSearch()"
             class="max-w-56 !p-1.5 rounded-xl !bg-bg2 !border-2 !border-lightGray2 !text-darkGray !focus:ring-none !focus:shadow animate-fade-in-right"
             [formControlName]="searchItem().key" [placeholder]="searchItem().placeholder" type="text"
             (keydown.enter)="search(); $event.preventDefault()" />

      <button *ngIf="(hasSearch() && filtersList.length > 1) || (!hasSearch() && filtersList.length > 0)" [disabled]="loading" class="border border-lightGray2 p-2 rounded-xl hover:bg-lightGray2 text-darkGray flex justify-center items-center gap-2 disabled:bg-darkGray disabled:text-lightGray2" (click)="op.toggle($event)">
        <span>Filtros</span>
        <span *ngIf="!loading && filters.length" class="flex justify-center items-center rounded-full w-5 h-5 text-primary bg-lightBlue text-xs">{{ filters.length }}</span>
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z" /></svg>
      </button>

      <app-loader *ngIf="loading" />
      <p-overlayPanel #op>
        <div class="flex flex-row justify-center items-center gap-3" *ngIf="filtersForm">
          <ng-container *ngFor="let filter of filtersList">
            <p-multiSelect *ngIf="filter.type === 'multiselect'"
              [options]="filter.multiSelectItems"
              [formControlName]="filter.key"
              [optionLabel]="filter.relation_column"
              display="chip"
              [placeholder]="filter.placeholder"/>

            <p-calendar *ngIf="filter.type == 'date-between'"
              [formControlName]="filter.key"
              [showIcon]="true"
              [showOnFocus]="false"
              selectionMode="range"
              dateFormat="dd/mm/yy"
              inputId="buttondisplay" />
          </ng-container>
          <!--<p-multiSelect
            [options]="stations"
            formControlName="station_id"
            optionLabel="name"
            display="chip"
            placeholder="Estaciones"/>
          <p-multiSelect
            [options]="services"
            formControlName="service_id"
            optionLabel="name"
            display="chip"
            [showClear]="true"
            placeholder="Servicios" />
          <p-multiSelect
            [options]="vehicles"
            formControlName="vehicle_id"
            optionLabel="name"
            display="chip"
            placeholder="Vehiculos"/>
          <p-calendar
            formControlName="date"
            [showIcon]="true"
            [showOnFocus]="false"
            selectionMode="range"
            dateFormat="dd/mm/yy"
            inputId="buttondisplay" />-->
        </div>
        <div class="w-full flex justify-end gap-4 mt-3">
          <button *ngIf="filters.length > 0" (click)="op.toggle($event); clearFilters()" class="rounded-xl bg-red-400 hover:bg-red-500 p-2 text-white">BORRAR</button>
          <button (click)="op.toggle($event); search()" class="rounded-xl bg-primary p-2 text-white">APLICAR</button>
        </div>

      </p-overlayPanel>
    </form>

    <div class="flex items-center justify-end gap-2">
      <button  (click)="opColumns.toggle($event)" class="border border-lightGray2 p-2 rounded-xl hover:bg-lightGray2 text-darkGray flex justify-center items-center gap-2">Columnas <svg  xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg></button>
      <p-overlayPanel #opColumns>
        <div class="p-2" role="menu" aria-orientation="vertical" aria-labelledby="column-select-menu">
          <div *ngFor="let header of tableHeaders">
            <label class="inline-flex items-center px-2 py-1 disabled:opacity-50 disabled:cursor-wait">
              <input [(ngModel)]="header.active" class="text-primary transition duration-150 ease-in-out border-gray-300 rounded shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50 "  type="checkbox">
              <span class="ml-2 text-darkGray">{{ header.name }}</span>
            </label>
          </div>

        </div>
      </p-overlayPanel>
      <select [(ngModel)]="perPage" (change)="onChangePerPage.emit(perPage)" class="border border-lightGray2 pr-8 py-2 rounded-xl hover:bg-lightGray2 text-darkGray flex justify-center items-center gap-2">
        <option *ngFor="let option of perPageOptions" [value]="option">{{ option }}</option>
      </select>

    </div>
  </div>

</div>
