import { Directive } from '@angular/core';

@Directive({
  selector: '[appFormInput]',
  host: {
    'class': 'bg-lightGray2 border border-lightGray2 text-darkGray rounded-3xl focus:ring-primary focus:border-primary block w-full p-2.5'
  },
  standalone: true
})
export class FormInputDirective {

  constructor() { }

}
