import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Step1, Step2, Step3, Step4, Step5, Step6} from "../../../../../../models/Front/ReservationForm";
import {select, Store} from "@ngrx/store";
import {
  selectStep1,
  selectStep2,
  selectStep3,
  selectStep4, selectStep5, selectStep6
} from "../../../../../../store/reservationForm/stepper.selector";
import {NgIf} from "@angular/common";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {StepperNumberDirective} from "../../../../../../shared/directives/stepper/stepper-number.directive";

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    NgIf,
    StepperNumberDirective
  ],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.css'
})
export class SummaryComponent implements OnInit {
  step1$: Observable<Step1 | undefined>;
  step1?: Step1;
  step2$: Observable<Step2 | undefined>;
  step2?: Step2;
  step3$: Observable<Step3 | undefined>;
  step3?: Step3;
  step4$: Observable<Step4 | undefined>;
  step4?:Step4;
  step5$:Observable<Step5 | undefined>;
  step5?:Step5;
  step6$:Observable<Step6 | undefined>;
  step6?:Step6;

  constructor(private store: Store) {
    this.step1$ = this.store.pipe(select(selectStep1));
    this.step2$ = this.store.pipe(select(selectStep2));
    this.step3$ = this.store.pipe(select(selectStep3));
    this.step4$ = this.store.pipe(select(selectStep4));
    this.step5$ = this.store.pipe(select(selectStep5));
    this.step6$ = this.store.pipe(select(selectStep6));
  }

  ngOnInit() {
    this.step1$.subscribe(step1 => {
      this.step1 = step1;
    });
    this.step2$.subscribe(step2 => {
      this.step2 = step2;
    });
    this.step3$.subscribe(step3 => {
      this.step3 = step3;
    });
    this.step4$.subscribe(step4 => {
      this.step4 = step4;
    });
    this.step5$.subscribe(step5 => {
      this.step5 = step5;
    })
    this.step6$.subscribe( step6 => {
      this.step6 = step6;
    })
  }

}
