<button class="w-full bg-green text-white hover:shadow rounded-md p-1 px-2" (click)="import.toggle($event)">Importar</button>
<p-overlayPanel #import>
  <ul class="flex flex-col gap-2">
    <li *ngIf="show('json')" class="cursor-pointer hover:bg-lightGray2 text-darkGray p-2"  (click)="fileInput.click()">
      JSON
    </li>
    <li *ngIf="show('csv')" class="cursor-pointer hover:bg-lightGray2 text-darkGray p-2"   (click)="fileInput2.click()">
      CSV
    </li>
    <li *ngIf="show('text')" class="cursor-pointer hover:bg-lightGray2 text-darkGray p-2"   (click)="fileInput3.click()">
      TEXTO
    </li>
  </ul>
  <input [(ngModel)]="file" type="file" accept=".json" #fileInput (change)="onImportClick.emit({type: 'json', file: $event.target}); file = null " style="display:none;" />
  <input [(ngModel)]="file"  type="file" accept=".csv" #fileInput2 (change)="onImportClick.emit({type: 'csv', file: $event.target}); file = null" style="display:none;" />
  <input [(ngModel)]="file"  type="file" accept=".txt,.rtf" #fileInput3 (change)="onImportClick.emit({type: 'text', file: $event.target}); file = null" style="display:none;" />
</p-overlayPanel>
