import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Step1, Step3, Step5, Step6} from "../../../../../models/Front/ReservationForm";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {
  selectShowSummary,
  selectStep1,
  selectStep3,
  selectStep5
} from "../../../../../store/reservationForm/stepper.selector";
import {
  updateShowSummary,
  updateStep,
  updateStep1,
  updateStep5,
  updateStep6
} from "../../../../../store/reservationForm/stepper.actions";
import {NgIf} from "@angular/common";
import {NextButtonDirective} from "../../../../../shared/directives/buttons/next.directive";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";
import {FormErrorDirective} from "../../../../../shared/directives/forms/form-error.directive";

@Component({
  selector: 'app-step5',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NextButtonDirective,
    FrontBackDirective,
    FormErrorDirective
  ],
  templateUrl: './step5.component.html',
  styleUrl: './step5.component.css'
})
export class Step5Component implements OnInit {

  @Output() stepCompleted = new EventEmitter<void>();
  step3$ :Observable<Step3 | undefined>;
  step3?: Step3;
  step5$: Observable<Step5 | undefined>;
  step5Form: FormGroup;

  constructor(private store: Store) {
    this.step3$ = this.store.select(selectStep3);
    this.step5$ = this.store.select(selectStep5);
    this.step5Form = new FormGroup({
      phone_number: new FormControl('', [Validators.required, Validators.maxLength(9), Validators.minLength(9)]),
      email: new FormControl('',[Validators.email])
    });
  }

  ngOnInit() {
    this.step5$.subscribe(step5 => {
      if(step5){
        this.step5Form.patchValue(step5);
      }

    });

    this.step3$.subscribe(step3 => {
      if(step3){
        this.step3 = step3;
      }
    });
  }

  public validate(): boolean {
    return this.step5Form.valid;
  }

  public nextStep() {
    if(this.step3){
      if(this.step3.station){
        if(this.step3.station.payment_methods.length>1){
          this.store.dispatch(updateStep({ step: 6 }));

        } else if(this.step3.station.payment_methods.length===1){
          let paymentMethod = this.step3.station.payment_methods[0];
          let step6: Step6 = {
            payment: paymentMethod,
          };
          this.store.dispatch(updateStep6({step6}));
          let showSummary = false;
          this.store.dispatch(updateShowSummary({showSummary}));
          this.store.dispatch(updateStep({ step: 7 }));
        }
      }
    }

  }

  public update() {
    if (this.validate()) {
      const step5: Step5 = this.step5Form.value;
      this.store.dispatch(updateStep5({step5}));
      this.nextStep()
      //this.stepCompleted.emit();
    }
  }

  goBack(){
    this.store.dispatch(updateStep({ step: 4 }));
  }

}
