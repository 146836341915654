<div *ngIf="oldReservation" class="animate-fade-in-down">
  <div class="flex flex-col justify-center items-center mb-4">
    <ng-content></ng-content>
  </div>
  <app-card-shadow>
    <div class="border border-gray-300 rounded-full py-3 px-10 mb-4 text-center">{{oldReservation.registration_number}}</div>
    <p class="text-red2 italic font-bold text-sm text-center">Tu ITV caduca el {{oldReservation.expiration | date: 'dd/MM/yyyy'}}</p>
    <p class="text-red2 italic text-sm text-center">(Tu última inspección fue en {{oldReservation.station.name}})</p>

    <div class="flex flex-col justify-center items-center border-t border-gray-300 border-b py-2 gap-y-2 my-4">
      <p class="text-sm text-primary text-center">Te proponemos: <span class="font-bold">{{oldReservation.station.name}}</span></p>
      <p class="text-sm text-primary text-center">el <span class="font-bold">{{oldReservation.date | date:'fullDate'}}</span> a las <span class="font-bold">{{oldReservation.hour.hour}}h</span></p>

      <div class="flex flex-col justify-center items-center gap-y-2 w-full max-w-[340px]">
        <div class="flex justify-center w-full" (click)="defaultReservation()">
          <div class="grid grid-cols-3 w-full bg-primary min-w-[200px] sm:min-w-[340px] hover:bg-darkPrimary cursor-pointer duration-300 text-left shadow mb-2 text-gray-50 py-2 px-8 rounded-full">
            <p class="col-span-2 text-center">Reservar <span class="font-bold">esta cita</span></p>
            <div class="text-end items-center">→</div>
          </div>
        </div>

        <div class="flex justify-center w-full" (click)="otherDateReservation()">
          <div class="grid grid-cols-3 w-full bg-[#F6F5EF] min-w-[200px] sm:min-w-[340px] hover:bg-[#e6e5df] cursor-pointer duration-300 shadow text-gray-700 py-2 px-8 rounded-full mb-2">
            <p class="col-span-2 text-center">Seleccionar <span class="font-bold">otra fecha</span></p>
            <div class="text-end items-center">→</div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center items-center w-full" (click)="otherStationReservation()">
      <div class="grid grid-cols-3 bg-[#F6F5EF] hover:bg-[#e6e5df] cursor-pointer duration-300 shadow text-gray-700 py-2 px-8 rounded-full mt-4 mb-2 w-full min-w-[200px] sm:min-w-[340px] max-w-[340px]">
        <p class="col-span-2 text-center">Seleccionar <span class="font-bold">otro centro</span></p>
        <div class="text-end items-center">→</div>
      </div>
    </div>
  </app-card-shadow>

  <div class="flex justify-center items-center py-6 w-full">
    <div (click)="onReturnClick.emit()" appFrontBack class="flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="w-5">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M5 12l14 0" />
        <path d="M5 12l4 4" />
        <path d="M5 12l4 -4" />
      </svg>
      <p>Volver</p>
    </div>
  </div>
</div>
