<div class="w-full mx-auto p-4 animate-fade-in" *ngIf="!confirmed">
  <h1 class="text-2xl text-center text-darkGray">Confirma tu cita</h1>
  <p class="text-sm text-center mb-6 text-darkGray">¡Revisa todos tus datos! Y confirma tu reserva</p>
  <div class="grid grid-cols-2 md:grid-cols-3 gap-x-4 mt-6">
    <div class="col-span-2 md:col-span-1">
      <div class="border-b border-gray-300 text-primary text-start py-2">Vehículo</div>
      <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-darkGray">
        <p >{{ step1?.registration_number }}</p>
        <p >{{ step2?.service?.name }}</p>
        <p >{{ step2?.vehicle?.name }}</p>
        <p >{{ step2?.fuel?.name }}</p>
      </div>
    </div>

    <div>
      <div class="border-b border-gray-300 text-primary text-start py-2">Centro ITV</div>
      <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ step3?.state?.name }}</p>
        <p>{{ step3?.station?.name }}</p>
        <a *ngIf="step3?.station?.maps_link" href="{{step3?.station?.maps_link}}" target="_blank" class="underline text-primary mt-2 cursor-pointer"> Google maps</a>
      </div>
    </div>

    <div>
      <div class="border-b border-gray-300 text-primary text-start py-2">Fecha de la cita</div>
      <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ step4?.date | date:'dd/MM/YYYY' }}</p>
        <p>{{ step4?.hour?.hour }}h</p>
      </div>
    </div>


    <div class="col-span-2 md:col-span-1">
      <div class="border-b border-gray-300 text-primary text-start py-2">Cliente</div>
      <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ step5?.phone_number }}</p>
        <p>{{ step5?.email }}</p>
      </div>
    </div>

    <div class="col-span-2">
      <div class="border-b border-gray-300 text-primary text-start py-2 col-span-2">Pago</div>

      <div class="col-span-2 grid grid-cols-4 text-sm pt-2 text-darkGray">
        <p class="text-start" *ngIf="price">{{price | currency:'EUR'}}</p>
        <p class="text-start col-span-3">{{step6?.payment?.front_name}}</p>
      </div>
    </div>



  </div>
  <div class="w-full mt-6 bg-lightGray2 text-darkGray p-4 text-xs" *ngIf="step3?.station?.information || step3?.station?.limitations">
    <p>{{step3?.station?.information}}</p>
    <p class="mt-2">{{step3?.station?.limitations}}</p>
  </div>
  <div class="mt-4 text-center flex justify-end">
    <button (click)="confirm()" [disabled]="loading" class="flex gap-4 items-center w-fit font-bold py-2 px-10 rounded-full bg-yellow text-primary hover:shadow-md duration-300 max-h-12">
      <a >CONFIRMAR</a> <app-loader *ngIf="loading" size="extra-small"></app-loader>
    </button>
  </div>
</div>

<div class="min-h-screen flex flex-col" *ngIf="confirmed">
  <!--<app-stepper [formPassed]="true"></app-stepper>-->
  <app-confirm-card (adminEmitter)="adminEmitter()" [admin]="admin" [isProfessional]="isProfessional" *ngIf="existingReservation" [existingReservation]="existingReservation"
                    (resetWithoutRegistrationClick)="reset()" (professionalOtherReservation)="professionalOtherReservation()" [showResetWithoutRegistration]="true"
  ></app-confirm-card>

</div>
