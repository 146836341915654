// src/app/core/services/auth.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../../models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {
  private localStorageKey = 'itv_user';
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;

  constructor() {
    const storedUser = localStorage.getItem(this.localStorageKey);
    this.currentUserSubject = new BehaviorSubject<User | null>(storedUser ? JSON.parse(storedUser) : null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  public isLoggedIn(){
    return !!this.currentUserSubject.value;
  }

  public isNotProfessional() {
    return !this.currentUserSubject.value?.roles.includes('professional');
  }

  public setUser(user: User | null): void {
    if (user) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(user));
    } else {
      localStorage.removeItem(this.localStorageKey);
    }
    this.currentUserSubject.next(user);
  }

}
