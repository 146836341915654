import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BreadcrumbService} from "../../../../../../core/services/breadcrumb/breadcrumb.service";
import {StationService} from "../../../../../../core/services/stations/station.service";
import {StationFormService} from "../../../../../../core/services/stations/station-form.service";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {Step1StationComponent} from "./step1-station/step1-station.component";
import {Step2StationComponent} from "./step2-station/step2-station.component";
import {Step3StationComponent} from "./step3-station/step3-station.component";
import {StationForm} from "../../../../../../models/StationForm/StationForm";
import {FormInputDirective} from "../../../../../../shared/directives/forms/form-input.directive";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {Step2Quadrants} from "../../../../../../models/StationForm/Step2Quadrants";
import {sample} from "rxjs";
import {Quadrant} from "../../../../../../models/Quadrant";

@Component({
  selector: 'app-new-station',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    Step1StationComponent,
    Step2StationComponent,
    Step3StationComponent,
    NgForOf,
    FormInputDirective,
    OverlayPanelModule
  ],
  templateUrl: './new-station.component.html',
  styleUrl: './new-station.component.css'
})
export class NewStationComponent implements OnInit{
  @Input() editStation:boolean=false;

  stationForm!: StationForm;

  @Input() quadrants:Quadrant[]|undefined=[];

  selectedQuadrant: Step2Quadrants | undefined;



  constructor(private breadcrumbService: BreadcrumbService,
              private stationService: StationService,
              private stationFormService: StationFormService) {

    this.manageStateForm();
    this.selectedQuadrant = this.stationForm.step2?.quadrants[0]
  }

  isQuadrantInArray(quadrant: Step2Quadrants, array: Step2Quadrants[]): boolean {
    if(array.some(q => q.id === quadrant.id)){
      return true;
    } else {
      this.selectedQuadrant=undefined;
      return false;
    }
  }

  ngOnInit() {
    this.stationFormService.reset();
    if(this.editStation){
      console.log('hola');
      this.cancel();
      this.goToSteep(2);
    }
  }


  manageStateForm(){
    this.stationFormService.stationForm$.subscribe(
      data => {
        this.stationForm = data;
        this.breadcrumbService.setBreadcrumb({
          display_name: 'Estaciones',
          items: [{
            name: 'Nueva estación',
            url: '/dashboard/estaciones'
          },
            {
              name: this.stationForm.step1?.name ? this.stationForm.step1.name.toUpperCase() : ''
            }]
        });
        // this.currentStep = data!.currentStep;
      }
    )

  }

  goToSteep(step: number) {
    this.stationFormService.changeToStep(step);
  }

  isAnyQuadrantEmpty(){
    return this.stationForm.step2?.quadrants.some(q=>q.services.length<1 || q.vehicles.length<1 || q.fuels.length<1)
  }

  quadrantIsInComplete(quadrant: Step2Quadrants): boolean {
    const completeDays = [1, 2, 3, 4, 5];
    const daysSet = new Set<number>();

    // Añadir los días de cada sección al Set
    for (let section of quadrant.sections) {
      for (let day of section.days) {
        daysSet.add(day);
      }
    }

    // Verificar si el Set contiene todos los días completos
    for (let day of completeDays) {
      if (!daysSet.has(day)) {
        return true; // Falta algún día completo
      }
    }

    return false; // Todos los días están completos
  }

  cancel(){
    this.selectedQuadrant=undefined;
    this.stationFormService.setStep2({
      quadrants: []
    })
    this.stationFormService.reset();
  }


}
