import {createAction, props} from "@ngrx/store";
import {Step1, Step2, Step3, Step4, Step5, Step6} from "../../models/Front/ReservationForm";
export const updateConfirmed = createAction(
  '[Confirmed] Update Confirmed', props<{confirmed:boolean}>()
);
export const updateShowSummary = createAction(
  '[ShowSummary] Update ShowSummary', props<{showSummary:boolean}>()
);
export const updateStep = createAction(
  '[Step] Update Step', props<{step:number}>()
);
export const updateStep1 = createAction(
  '[Step1] Update Step1', props<{step1:Step1}>()
);
export const updateStep2 = createAction(
  '[Step2] Update Step2', props<{step2:Step2}>()
);

export const updateStep3 = createAction(
  '[Step3] Update Step3', props<{step3:Step3}>()
);

export const updateStep4 = createAction(
  '[Step4] Update Step4', props<{step4:Step4}>()
);
export const updateStep5 = createAction(
  '[Step5] Update Step5', props<{step5:Step5}>()
);
export const updateStep6 = createAction(
  '[Step6] Update Step6', props<{step6:Step6}>()
);
