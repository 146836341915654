import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {environment} from "../../../../environments/environment";
import {Fuel} from "../../../models/Fuel";
import {SortColumn} from "../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";
import {Paginator} from "../../../models/Paginator";
import {Vehicle} from "../../../models/Vehicle";

@Injectable({
  providedIn: 'root'
})
export class FuelService {

  constructor(private http: HttpClient) { }

  list(): Observable<Response<Fuel[]>>{
    return this.http.get<Response<Fuel[]>>(`${environment.API_URL}/fuels/list`)
  }

  listpPaginate(sorts: SortColumn[] = [], filters: ActiveFilter[] = [], perPage = 50): Observable<Response<Paginator<Fuel>>>{
    return this.http.get<Response<Paginator<Fuel>>>(`${environment.API_URL}/fuels/list-paginate/${perPage}`)
  }
}
