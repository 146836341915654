import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {environment} from "../../../../environments/environment";
import {PaymentMethod} from "../../../models/PaymentMethod";

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {

  constructor(private http: HttpClient) { }

  list(): Observable<Response<PaymentMethod[]>>{
    return this.http.get<Response<PaymentMethod[]>>(`${environment.API_URL}/payment-methods/list`)
  }
}
