import { Directive } from '@angular/core';

@Directive({
  selector: '[appFormInputBlueNoFull]',
  host: {
    'class': 'bg-lightBlue duration-300 text-sm border border-lightGray2 text-primary rounded-3xl focus:ring-primary focus:border-primary block p-2.5 shadow-lg'
  },
  standalone: true
})
export class FormInputBlueNoFullDirective {

  constructor() { }

}
