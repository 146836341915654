<div class="border-l border-lightGray2 px-20">

  <app-existing-reservation-card [admin]="true" [editReservation]="false" [phoneConfirm]="existingReservation.phone" (onUpdateReservation)="existingReservation = $event" (onCancelReservation)="existingReservation=$event" *ngIf="existingReservation" [existingReservation]="existingReservation"
                                 (onReturnClick)="returnRegistration()" (onEditReservation)="editReservation=$event">
  </app-existing-reservation-card>
  <button class="mx-auto" *ngIf="existingReservation && !editReservation" (click)="forceWithReservationAlert()" appNextButton>
    Continuar con la reserva
    <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M15 16l4 -4" /><path d="M15 8l4 4" /></svg>

  </button>

  <div *ngIf="currentStep<5 && !existingReservation" class="flex flex-col w-full mx-auto gap-y-8 animate-fade-in" >
    <form [formGroup]="step1Form">
    <div class="flex flex-row gap-x-6 items-start justify-center w-full">
      <div class="flex flex-col w-2/3">
        <input formControlName="registration_number" type="text" placeholder="0000AAA" class="w-full rounded-xl border-2 border-lightGray2">
        <div class="my-1" *ngIf="step1Form.get('registration_number')?.invalid && step1Form.get('registration_number')?.touched" appFormError> Matrícula no valida</div>
        <div class="my-1" *ngIf="error" appFormError> {{ error }}</div>
      </div>
      <div class="w-1/3">
        <button appNextOutlined (click)="onAddRegistrationNumber()" type="button" >
        <p>Continuar</p>
        <svg *ngIf="!loadingStep1"  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M15 16l4 -4" /><path d="M15 8l4 4" /></svg>
        <app-loader size="extra-small" *ngIf="loadingStep1"></app-loader>
      </button>
      </div>

    </div>
    </form>
    <div *ngIf="step2 && step3" class="grid grid-cols-2 gap-x-6 gap-y-4">
      <div *ngIf="currentStep>1 && step2.subStep>=1" class="flex flex-col gap-y-2 animate-fade-in" >
        <label class="text-darkGray text-lg">Tipo de inspección</label>
        <select id appFormInputBlueNoFull [(ngModel)]="selectedService" (change)="onSelectService()" [ngModelOptions]="{standalone: true}">
          <option disabled [ngValue]="undefined">Selecciona un tipo de inspección</option>
          <option *ngFor="let service of services" [ngValue]="service" [selected]="selectedService?.id===service.id ? service : ''"> {{service.name}} </option>
        </select>
      </div>
      <div *ngIf="currentStep>1 && step2.subStep>=2" class="flex flex-col gap-y-2 animate-fade-in">
        <label class="text-darkGray text-lg" >Tipo de vehículo</label>
        <select appFormInputBlueNoFull [(ngModel)]="selectedVehicle" (change)="onSelectVehicle()">
          <option disabled [ngValue]="undefined">Selecciona un tipo de vehículo</option>
          <option *ngFor="let vehicle of vehicles" [ngValue]="vehicle"> {{vehicle.name}}</option>
        </select>
      </div>
      <div *ngIf="currentStep>1 && step2.subStep>=3" class="flex flex-col gap-y-2 animate-fade-in">
        <label class="text-darkGray text-lg">Tipo de combustible</label>
        <select appFormInputBlueNoFull [(ngModel)]="selectedFuel" (change)="onSelectFuel()">
          <option disabled [ngValue]="undefined">Selecciona un tipo de combustible</option>
          <option *ngFor="let fuel of fuels" [ngValue]="fuel"> {{fuel.name}}</option>
        </select>
      </div>
      <div *ngIf="currentStep>2 && step3.subStep>=1" class="flex flex-col gap-y-2 animate-fade-in">
        <label class="text-darkGray text-lg">Comunidad</label>
        <select appFormInputBlueNoFull [(ngModel)]="selectedState" (change)="onSelectState()">
          <option disabled [ngValue]="undefined">Selecciona una comunidad</option>
          <option *ngFor="let state of states" [ngValue]="state"> {{state.name}} </option>
        </select>
      </div>
      <div *ngIf="currentStep>2 && step3.subStep>=2" class="flex flex-col gap-y-2 animate-fade-in" >
        <label class="text-darkGray text-lg">Centro</label>
        <select appFormInputBlueNoFull [(ngModel)]="selectedStation" (change)="onSelectStation()">
          <option disabled [ngValue]="undefined">Selecciona una estación</option>
          <option *ngFor="let station of stations" [ngValue]="station">{{station.name}}</option>
        </select>
      </div>
    </div>
    <div *ngIf="currentStep>3" class="grid grid-cols-4 gap-x-6 animate-fade-in">
      <div class="col-span-3 flex flex-col">
        <h1 class="text-lg text-darkGray">Fecha</h1>
        <app-month-calendar [loading]="loadingDates" (nextMonth)="nextMonth()" (prevMonth)="prevMonth()" [changeMonth]="true" [frontMode]="true"  [blockedDates]="blockedDates" [month]="month" [year]="year" [selectDaysMode]="true" selectedColor="#168FFB" [currentSelection]="[selectedDate ?? '']" (onSelectDayChange)="onSelectedDay($event)"></app-month-calendar>

      </div>
      <div *ngIf="selectedDate" class="flex flex-col animate-fade-in">
        <label class="text-lg text-darkGray">Hora</label>
        <app-loader size="large" *ngIf="loadingHours"></app-loader>
        <select *ngIf="!loadingHours" [(ngModel)]="selectedHour" (ngModelChange)="onSelectHour()" appFormSelectSquare >
          <option disabled [ngValue]="undefined">Selecciona una hora</option>
          <option class="{{hour.available<1 ? 'bg-lightGray2 text' : ''}}" [ngValue]="hour" [disabled]="hour.available<1" *ngFor="let hour of hours">{{hour.hour}} {{hour.available>0 ? ' - '+hour.available : ''}}</option>
        </select>
        <button class="mt-6 animate-fade-in" *ngIf="selectedHour" (click)="nextStep()" appNextSquare>Continuar <app-loader size="extra-small" *ngIf="loading"></app-loader></button>
      </div>
    </div>

  </div>

  <div *ngIf="currentStep==5" class="flex flex-col w-full mx-auto gap-y-8 mt-10 animate-fade-in">

    <form [formGroup]="step5Form" class="flex flex-col mb-10 gap-y-4">
      <div class="flex flex-col text-start">
        <input formControlName="name" type="text" placeholder="Nombre (opcional)" class="w-full rounded-full bg-lightGray2 px-3 py-2 outline-0 border-0" />
      </div>
      <div class="flex flex-col text-start">
        <input formControlName="email" type="email" placeholder="Email (opcional)" class="w-full rounded-full bg-lightGray2 px-3 py-2 outline-0 border-0" />
        <div *ngIf="step5Form.get('email')?.invalid && step5Form.get('email')?.touched" appFormError>Email no válido</div>
      </div>
      <div class="flex flex-col text-start">
        <input formControlName="phone_number" placeholder="Móvil *" type="text" class="w-full rounded-full bg-lightGray2 text-darkGray px-3 py-2 outline-0 border-0" />
        <div *ngIf="step5Form.get('phone_number')?.invalid && step5Form.get('phone_number')?.touched" appFormError>Número de teléfono no válido</div>
      </div>
      <div class="flex items-center justify-between mt-2">
        <button (click)="goBack()" appFrontBack>
      <span>
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l4 4" /><path d="M5 12l4 -4" /></svg>
      </span>
          <span>Atrás</span>

        </button>
        <button (click)="onAddInfo()" [disabled]="step5Form.invalid" type="button" appNextSquare>
          Siguiente
          <app-loader size="extra-small" *ngIf="loading"></app-loader>
        </button>
        <!--<button type="button" (click)="update()" class="bg-blue-500 px-4 py-2 mt-10 shadow-2xl duration-300 text-xl rounded-2xl text-white w-fit">Continuar →</button>-->
      </div>
    </form>
  </div>

  <div *ngIf="currentStep==6" class="flex flex-col w-full mx-auto gap-y-8 mt-10 animate-fade-in">
    <div *ngIf="selectedStation" class="flex flex-col gap-y-2 animate-fade-in" >
      <label class="text-darkGray text-lg">Método de pago</label>
      <select appFormInputBlueNoFull [(ngModel)]="selectedPayment" (change)="onSelectPayment()">
        <option disabled [ngValue]="undefined">Selecciona un método de pago</option>
        <option *ngFor="let payment of selectedStation.payment_methods" [ngValue]="payment">{{payment.front_name}}</option>
      </select>
    </div>
    <div class="flex items-center justify-between mt-2">
      <button (click)="goBack()" appFrontBack>
      <span>
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l4 4" /><path d="M5 12l4 -4" /></svg>
      </span>
        <span>Atrás</span>

      </button>
      <button (click)="confirm()" [disabled]="!selectedPayment" type="button" appNextSquare>
        Reservar
        <app-loader size="extra-small" *ngIf="loading"></app-loader>
      </button>
      <!--<button type="button" (click)="update()" class="bg-blue-500 px-4 py-2 mt-10 shadow-2xl duration-300 text-xl rounded-2xl text-white w-fit">Continuar →</button>-->
    </div>
  </div>

  <div *ngIf="currentStep>6" class="w-full mx-auto p-4 animate-fade-in">
    <div class="flex flex-row gap-x-3 justify-start items-center">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.00"
           stroke-linecap="round" stroke-linejoin="round" class="w-12 text-primary">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M4 5h2"/>
        <path d="M5 4v2"/>
        <path d="M11.5 4l-.5 2"/>
        <path d="M18 5h2"/>
        <path d="M19 4v2"/>
        <path d="M15 9l-1 1"/>
        <path d="M18 13l2 -.5"/>
        <path d="M18 19h2"/>
        <path d="M19 18v2"/>
        <path d="M14 16.518l-6.518 -6.518l-4.39 9.58a1 1 0 0 0 1.329 1.329l9.579 -4.39z"/>
      </svg>
      <h1 class="text-3xl text-darkGray">¡Genial! Se ha reservado correctamente la cita para la ITV</h1>
    </div>
    <p class="text-xs text-center text-darkGray">¡Revisa todos tus datos!</p>

    <div class="grid grid-cols-3 gap-x-2 mt-6 text-sm">
      <div class="border-b border-lightGray2 text-primary text-start py-2">Vehículo</div>
      <div class="border-b border-lightGray2 text-primary text-start py-2">Centro ITV</div>
      <div class="border-b border-lightGray2 text-primary text-start py-2">Fecha de la cita</div>
      <div class="flex flex-col text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ step1?.registration_number }}</p>
        <p>{{ step2?.service?.name }}</p>
        <p>{{ step2?.vehicle?.name }}</p>
        <p>{{ step2?.fuel?.name }}</p>
      </div>
      <div class="flex flex-col text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ step3?.state?.name }}</p>
        <p>{{ step3?.station?.name }}</p>
        <a href="{{step3?.station?.maps_link}}" target="_blank" class="underline text-primary mt-2 cursor-pointer"> Google maps</a>
      </div>
      <div class="flex flex-col text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ step4?.date | date:'dd/MM/YYYY' }}</p>
        <p>{{ step4?.hour?.hour }}h</p>
        <!--      <a class="underline text-primary mt-2 cursor-pointer">Añadir a iCalendar</a>
              <a class="underline text-primary mt-2 cursor-pointer">Añadir a Wallet</a>-->
      </div>
      <div class="border-b border-gray-300 text-primary text-start py-2 col-span-1">Cliente</div>
      <div class="border-b border-gray-300 text-primary text-start py-2 col-span-2">Pago</div>
      <div class="flex flex-col text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ step5?.phone_number }}</p>
        <p>{{ step5?.email }}</p>
      </div>
      <div class="col-span-2 grid grid-cols-4 text-sm pt-2 text-darkGray">
        <p class="text-start">46,50€</p>
        <p class="text-start col-span-3">{{ step6?.payment?.front_name }}</p>
      </div>
      <div class="border-b border-lightGray2 text-primary text-start pt-2 mt-4 pb-2 col-span-3">Justificante</div>
      <div class="col-span-3  flex flex-col justify-start p-4">
        <div class="max-w-48">
          <button appNextButton>
            Enviar SMS
          </button>
        </div>


        <div class="flex flex-col gap-y-3 justify-start max-w-96 mt-10">
          <input type="text" placeholder="Email" appFormInputOutlined value="{{step5?.email}}">
          <div class="h-fit">
            <button appNextOutlined>
              Enviar justificante por email
            </button>
          </div>

        </div>
      </div>
      <div class="col-span-3 flex justify-end">
        <div class="max-w-64">
        <button (click)="reset()" appNextButton>
          <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-6"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" /><path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" /></svg>
          Volver a reservar otra cita
        </button>
        </div>
      </div>



    </div>
  </div>

</div>
