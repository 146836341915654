import { Directive } from '@angular/core';

@Directive({
  selector: '[appFormError]',
  host: {
    'class': 'text-red-500 text-xs font-[400]'
  },
  standalone: true
})
export class FormErrorDirective {

  constructor() { }

}
