import { trigger, state, style, animate, transition } from '@angular/animations';

export const slideInOutAnimation = trigger('slideInOut', [
  state('in', style({
    transform: 'translate3d(0, 0, 0)' // Posición inicial: no desplazamiento (visible en su posición original)
  })),
  state('out', style({
    transform: 'translate3d(-100%, 0, 0)' // Posición de salida: desplazamiento hacia la izquierda
  })),
  transition('in => out', animate('400ms ease-in-out')),
  transition('out => in', animate('400ms ease-in-out'))
]);

