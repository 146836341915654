import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {DatePipe, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {NextButtonDirective} from "../../../../shared/directives/buttons/next.directive";
import {OutlineButtonDirective} from "../../../../shared/directives/outline/outline-button.directive";
import {Reservation} from "../../../../models/Reservation";
import {ReservationGesService} from "../../../../core/services/reservations/reservation-ges.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-confirm-card',
  standalone: true,
  imports: [
    DatePipe,
    FormsModule,
    LoaderComponent,
    NextButtonDirective,
    NgIf,
    OutlineButtonDirective
  ],
  templateUrl: './confirm-card.component.html',
  styleUrl: './confirm-card.component.css'
})
export class ConfirmCardComponent implements OnInit {
  loadingEmail = false;
  loadingSms = false;
  email: string = "";

  @Input() isProfessional: boolean = false;

  @Input() admin: boolean = false;

  @Output() resetWithoutRegistrationClick: EventEmitter<void> = new EventEmitter();
  @Output() professionalOtherReservation: EventEmitter<void> = new EventEmitter();
  @Output() adminEmitter: EventEmitter<void> = new EventEmitter();

  @Input() existingReservation!:Reservation;
  @Input() showResetWithoutRegistration:boolean =  false;

    protected readonly environment = environment;

    constructor(private reservationGesService: ReservationGesService, private toastrService: ToastrService) {


    }

    ngOnInit() {
      if (this.existingReservation) {
        this.email = this.existingReservation.email;
      }
    }

  sendMessage(){
    if(this.existingReservation){
      this.loadingSms=true;
      const formattedDate = new Date(this.existingReservation?.date).toLocaleDateString('es-ES', {
        weekday: 'long', // Nombre del día de la semana
        day: 'numeric',  // Día del mes
        month: 'long',   // Nombre completo del mes
        year: 'numeric'  // Año con cuatro dígitos
      });
      let data = {
        phone:this.existingReservation?.phone,
        message:this.existingReservation?.registration_number+' tiene cita: '+ formattedDate +' a las '+ this.existingReservation.hour.hour +' en ' + this.existingReservation.station.name +'. Gracias por confiar en Itevebasa.'
      }
      this.reservationGesService.sendMessage(data,this.existingReservation.id).subscribe({
        next:(response)=>{
          if(response.success){
            this.toastrService.success(response.message);
          }
          this.loadingSms=false;
        },

        error:(err)=>{
          //console.log(err.error.message);
          this.loadingSms=false;
        }
      });
    }
  }

  sendProofToEmail(){
    this.loadingEmail = true;
    this.reservationGesService.sendProofToEmail(this.existingReservation!.id, this.email,  this.existingReservation!.phone).subscribe({
      next: (result) => {
        if (result.success) {
          this.toastrService.success(result.message);
        }
        this.loadingEmail = false;
      },
      error: (error) => {
        this.toastrService.error(error.error.message);
        this.loadingEmail = false;
      }
    })
  }

  reset(){
    this.adminEmitter.emit();
  }

}
