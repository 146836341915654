import {ToastrService} from "ngx-toastr";
import {QuadrantSection} from "../QuadrantSection";

export interface CreateQuadrantHour {
  hour: string;
  capacity: number;
}

export function exportHoursToJson(hours: CreateQuadrantHour[]) {
  const jsonData = JSON.stringify(hours, null, 2);
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'horas-capacidad.json';
  a.click();
  window.URL.revokeObjectURL(url);
}

export function exportHoursToCsv(hours: CreateQuadrantHour[]) {
  const header = ['hour', 'capacity'];
  const csvRows = hours.map(hour => `${hour.hour},${hour.capacity}`);
  const csvContent = [header.join(','), ...csvRows].join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'horas-capacidad.csv';
  a.click();
  window.URL.revokeObjectURL(url);
}

export function exportHoursToTxt(hours: CreateQuadrantHour[]) {
  const txtRows = hours.map(hour => `${hour.hour}|${hour.capacity}`);
  const txtContent = txtRows.join('\n');
  const blob = new Blob([txtContent], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'horas-capacidad.txt';
  a.click();
  window.URL.revokeObjectURL(url);
}

export function importHoursFromTxt(e: any, toastr: ToastrService): CreateQuadrantHour[] {
  const txtContent = e.target.result as string;
  const txtRows = txtContent.split('\n');

  const formattedData = txtRows.map(row => {
    const matches = row.match(/(\d{2}:\d{2})\|(\d+)/);
    if (matches) {
      const hour = matches[1];
      const capacity = parseInt(matches[2], 10);
      return { hour, capacity };
    }
    return null;
  }).filter(item => item !== null);

  // Validar que todas las filas sean válidas
  if (formattedData.length > 0) {
    toastr.success('Horas y capacidad importado correctamente.');
    // @ts-ignore
    return formattedData;
  } else {
    console.error('Invalid TXT structure');
    toastr.error('Estructura inválida');
    return generateHourList();
  }
}

export function importHourFromJson(e: any, toastr: ToastrService) {
  // @ts-ignore
  const importedData = JSON.parse(e.target.result as string);
  if (Array.isArray(importedData) && importedData.every(item => item.hour && item.capacity !== undefined)) {
    toastr.success('Horas y capacidad importado correctamente.')
    return importedData;
  } else {
    toastr.error('Estructura inválida');
    console.error('Invalid JSON structure');
    return generateHourList();
  }
}

export function importHoursFromCsv(e: any, toastr: ToastrService): CreateQuadrantHour[] {
  const csvContent = e.target.result as string;
  const csvRows = csvContent.split('\n');
  const headers = csvRows[0].split(',');
  if (headers[0] !== 'hour' || headers[1] !== 'capacity') {
    toastr.error('Estructura inválida');
    console.error('Invalid CSV structure');
    return generateHourList();
  }
  toastr.success('Horas y capacidad importado correctamente.');
  return csvRows.slice(1).map(row => {
    const [hour, capacity] = row.split(',');
    return {hour, capacity: parseInt(capacity, 10)};
  });

}

export function generateHourList(section?: QuadrantSection): CreateQuadrantHour[] {
  let hours: CreateQuadrantHour[] = [];
  // Hora inicial y final en formato HH:mm
  const startHour = 7;
  const endHour = 22;

  // Intervalo en minutos
  const interval = 10;

  // Generar las horas en intervalos de 10 minutos hasta las 22:00
  for (let hour = startHour; hour <= endHour; hour++) {
    // Determinar el minuto inicial según la hora
    let startMinute = (hour === startHour) ? 30 : 0;

    for (let minute = startMinute; minute < 60; minute += interval) {
      if (hour === endHour && minute > 0) {
        // Detener la generación cuando lleguemos a las 22:00 exactamente
        break;
      }

      const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

      // Verificar si tenemos un `QuadrantSection` y buscar si hay una capacidad definida para este tiempo
      let capacity = 0; // Capacidad por defecto
      if (section) {
        const matchingHour = section.hours.find(h => h.hour === time);
        if (matchingHour) {
          capacity = matchingHour.capacity;
        }
      }

      hours.push({ hour: time, capacity });
    }
  }

  return hours;
}

