import { Directive } from '@angular/core';

@Directive({
  selector: '[appFrontBack]',
  host: {
    'class': 'flex flex-row items-center gap-x-4 p-2 px-4 text-primary bg-lightBlue shadow-md hover:shadow-xl duration-300 cursor-pointer w-fit rounded-full'
  },
  standalone: true
})
export class FrontBackDirective {

  constructor() { }

}
