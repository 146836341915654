import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {User} from "../../../models/User";
import {Response} from "../../../models/Response";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  /**
   * Autenticación de un usuario.
   *
   * @param user Usuario para el login.
   * @return Observable<Response<User>> Observable de la respuesta de la autenticación.
   */
  signIn(user: { email: string, password: string }): Observable<Response<User>> {
    return this.http.post<Response<User>>(`${environment.API_URL}/auth/login`, {email: user.email, password:user.password});
  }

  logOut(): Observable<Response<null>>{
    return this.http.post<Response<null>>(`${environment.API_URL}/auth/logout`, null);
  }

  sendPasswordResetLink(email: string): Observable<any> {
    return this.http.post(`${environment.API_URL}/auth/password/email`, { email });
  }

  resetPassword(data: { email: string, password: string, password_confirmation: string, token: string }): Observable<any> {
    return this.http.post(`${environment.API_URL}/auth/password/reset`, data);
  }
}
