import {Component, Input} from '@angular/core';
import {AsideService, AsideState} from "../../../../../../core/services/aside-service/aside-service.service";
import {NgClass, NgIf} from "@angular/common";
import {MenuItem} from "../../../../../../const/menu";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {AuthService} from "../../../../../../core/services/auth/auth.service";
import {AuthStateService} from "../../../../../../core/services/auth/auth-state.service";

@Component({
  selector: 'app-aside-nav-item',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './aside-nav-item.component.html',
})
export class AsideNavItemComponent {
  @Input() asideState!: AsideState;
  @Input() menuItem!: MenuItem;
  protected readonly matchMedia = matchMedia;
  constructor(private sanitizer: DomSanitizer, public asideService: AsideService) {}

  get safeIconHtml(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.menuItem.icon);
  }
}
