import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-import',
  standalone: true,
  imports: [
    OverlayPanelModule,
    NgIf,
    FormsModule
  ],
  templateUrl: './import.component.html',
  styleUrl: './import.component.css'
})
export class ImportComponent {
  @Input() excludes: string[] = [];
  @Output() onImportClick: EventEmitter<{type: 'json'|'csv'|'text', file: any}> = new EventEmitter();
  file = null;
  show(type: string){
    return this.excludes.indexOf(type) === -1;
  }
}
