<form  class="mt-8 animate-fade-in-down">
  <div class="w-full mx-auto grid grid-cols-1 md:grid-cols-2">
    <form [formGroup]="form" (ngSubmit)="generateQuadrant()" class="flex flex-col gap-0 border-r border-lightGray px-4">
      <div class="border-b border-darkGray py-4">
        <label appFormLabel class="!text-base">Servicios</label>
        <app-loader *ngIf="loading"></app-loader>
        <div class="flex flex-wrap gap-x-2 gap-y-1">
          <div class="inline-flex items-center" *ngFor="let item of services">
            <input class="rounded-full" formControlName="services_check" type="checkbox" [value]="item.id" (change)="onCheckboxChange($event, 'services', item)">
            <span class="ml-1 text-sm text-darkGray">{{ item.name }}</span>
          </div>
        </div>
        <span *ngIf="form.get('services')?.touched && form.get('services')?.invalid" appFormError>
          Debes de seleccionar almenos un servicio
        </span>
      </div>

      <div class="border-b border-darkGray py-4">
        <label appFormLabel class="!text-base">Vehiculos</label>
        <app-loader *ngIf="loading"></app-loader>
        <div class="flex flex-wrap gap-x-2 gap-y-1">
          <div class="inline-flex items-center" *ngFor="let item of vehicles">
            <input class="rounded-full" formControlName="vehicles_check" type="checkbox" [value]="item.id" (change)="onCheckboxChange($event, 'vehicles', item)">
            <span class="ml-1 text-sm text-darkGray">{{ item.name }}</span>
          </div>
        </div>
        <span *ngIf="form.get('vehicles')?.touched && form.get('vehicles')?.invalid" appFormError>
         Debes de seleccionar almenos un vehiculo
        </span>
      </div>

      <div class="py-4">
        <label appFormLabel class="!text-base flex items-center gap-2">Combustibles <button type="button" (click)="allCombustibles()" class="text-xs">TODOS</button></label>
        <app-loader *ngIf="loading"></app-loader>
        <div class="flex flex-wrap gap-x-2 gap-y-1">
          <div class="inline-flex items-center" *ngFor="let item of fuels">
            <input class="rounded-full" formControlName="fuels_check" type="checkbox" [value]="item.id" (change)="onCheckboxChange($event, 'fuels', item)">
            <span class="ml-1 text-sm text-darkGray">{{ item.name }}</span>
          </div>
        </div>
        <span *ngIf="form.get('fuels')?.touched && form.get('fuels')?.invalid" appFormError>
         Debes de seleccionar almenos un combustible
        </span>
      </div>

      <div>
        <label appFormLabel>Nombre cuadrante</label>
        <input appFormInput type="text" formControlName="name">
        <span *ngIf="form.get('name')?.touched && form.get('name')?.invalid" appFormError>
          El nombre del cuadrante es requerido
        </span>
      </div>


      <div class="w-full flex justify-end mt-2">
        <button type="submit" class="bg-white border-2 border-lightGray2 hover:bg-lightGray2 text-darkGray shadow-sm p-2 px-4 rounded-2xl text-sm font-light flex items-center gap-4">
          Generar cuadrante
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="w-5">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M5 12l14 0" />
            <path d="M15 16l4 -4" />
            <path d="M15 8l4 4" />
          </svg>
        </button>
      </div>

    </form>


    <div class="flex flex-col gap-4 px-4">

      <label appFormLabel class="!text-base">Cuadrantes de este centro</label>

      <div *ngFor="let quadrant of toCreateQuadrants; let i = index" class="w-full text-darkGray border-2 border-lightGray2 rounded-2xl p-2 px-4 flex justify-between">
        <div class="flex flex-col w-full">
          <span class="mb-2">{{ quadrant.name }}</span>
          <div class="flex justify-around items-center">
            <div class="flex flex-wrap w-full gap-1 items-center">
              <span class="text-xs text-darkGray">Servicios:</span>
              <div *ngFor="let service of quadrant.services; let e = index" class="flex justify-around items-center gap-2 text-xs bg-lightBlue text-primary py-1 px-2 rounded-3xl">
                <span >{{service.name}}</span>
                <button (click)="addService(i,service)" type="button" class="flex-shrink-0 ml-2 p-0.5 rounded-full inline-flex items-center justify-center text-primary hover:bg-primary hover:text-lightBlue focus:outline-none focus:bg-indigo-500 focus:text-white">
                  <svg class="w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                    <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"></path>
                  </svg>
                </button>
              </div>
            </div>
            <button class="w-fit items-center" (click)="ops.toggle($event)">
              <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
              <p-overlayPanel #ops>
                <div>
                  <div class="py-4">
                    <label appFormLabel class="!text-base">Servicios</label>
                    <div class="grid grid-cols-2 gap-x-2 gap-y-2">
                      <div *ngFor="let item of services">
                        <div class="flex justify-between gap-2 items-center">
                          <span class="ml-1 text-sm text-darkGray">{{ item.name }}</span>
                          <button (click)="addService(i,item)" class="text-white {{isServiceInArray(item,quadrant.services) ? 'bg-primary' : 'bg-green'}}  rounded-full w-4 h-4 flex items-center justify-center">
                            <span *ngIf="!isServiceInArray(item,quadrant.services)">+</span>
                            <span *ngIf="isServiceInArray(item,quadrant.services)">✔</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!--          <span *ngIf="form.get('services')?.touched && form.get('services')?.invalid" appFormError>
                              Debes de seleccionar almenos un servicio
                            </span>-->
                  </div>
                </div>
              </p-overlayPanel>
            </button>
          </div>
          <div class="flex justify-around items-center">
            <div class="flex flex-wrap w-full gap-1 items-center mt-2">
              <span class="text-xs text-darkGray">Vehiculos: </span>
              <div *ngFor="let vehicle of quadrant.vehicles; let e = index" class="flex justify-around items-center gap-2 text-xs bg-lightBlue text-primary py-1 px-2 rounded-3xl">
                <span>{{vehicle.name}}</span>
                <button (click)="addVehicle(i,vehicle)" type="button" class="flex-shrink-0 ml-2 p-0.5 rounded-full inline-flex items-center justify-center text-primary hover:bg-primary hover:text-lightBlue focus:outline-none focus:bg-indigo-500 focus:text-white">
                  <svg class="w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                    <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"></path>
                  </svg>
                </button>
              </div>
            </div>
            <button class="w-fit items-center" (click)="opv.toggle($event)">
              <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
              <p-overlayPanel #opv>
                <div>
                  <div class="py-4">
                    <label appFormLabel class="!text-base">Vehículos</label>
                    <div class="grid grid-cols-2 gap-x-2 gap-y-2">
                      <div *ngFor="let item of vehicles">
                        <div class="flex justify-between gap-2 items-center">
                          <span class="ml-1 text-sm text-darkGray">{{ item.name }}</span>
                          <button (click)="addVehicle(i,item)" class="text-white {{isVehicleInArray(item,quadrant.vehicles) ? 'bg-primary' : 'bg-green'}}  rounded-full w-4 h-4 flex items-center justify-center">
                            <span *ngIf="!isVehicleInArray(item,quadrant.vehicles)">+</span>
                            <span *ngIf="isVehicleInArray(item,quadrant.vehicles)">✔</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!--          <span *ngIf="form.get('services')?.touched && form.get('services')?.invalid" appFormError>
                              Debes de seleccionar almenos un servicio
                            </span>-->
                  </div>
                </div>
              </p-overlayPanel>
            </button>
          </div>
          <div class="flex justify-around items-center">
            <div class="flex flex-wrap w-full gap-1 items-center mt-2">
              <span class="text-xs text-darkGray">Combustibles: </span>
              <div *ngFor="let fuel of quadrant.fuels; let e = index" class="flex justify-around items-center gap-2 text-xs bg-lightBlue text-primary py-1 px-2 rounded-3xl">
              <span>{{fuel.name}}</span>
                <button (click)="addFuel(i,fuel)" type="button" class="flex-shrink-0 ml-2 p-0.5 rounded-full inline-flex items-center justify-center text-primary hover:bg-primary hover:text-lightBlue focus:outline-none focus:bg-indigo-500 focus:text-white">
                  <svg class="w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                    <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"></path>
                  </svg>
                </button>
              </div>
            </div>
            <button class="w-fit items-center" (click)="opf.toggle($event)">
              <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
              <p-overlayPanel #opf>
                <div>
                  <div class="py-4">
                    <label appFormLabel class="!text-base">Combustibles</label>
                    <div class="grid grid-cols-2 gap-x-2 gap-y-2">
                      <div *ngFor="let item of fuels">
                        <div class="flex justify-between gap-2 items-center">
                          <span class="ml-1 text-sm text-darkGray">{{ item.name }}</span>
                          <button (click)="addFuel(i,item)" class="text-white {{isFuelInArray(item,quadrant.fuels) ? 'bg-primary' : 'bg-green'}}  rounded-full w-4 h-4 flex items-center justify-center">
                            <span *ngIf="!isFuelInArray(item,quadrant.fuels)">+</span>
                            <span *ngIf="isFuelInArray(item,quadrant.fuels)">✔</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!--          <span *ngIf="form.get('services')?.touched && form.get('services')?.invalid" appFormError>
                              Debes de seleccionar almenos un servicio
                            </span>-->
                  </div>
                </div>
              </p-overlayPanel>
            </button>
          </div>
        </div>
        <div>
          <button (click)="removeQuadrant(i)" class="bg-red-500 hover:bg-red-600 text-white p-1 rounded-2xl">
            <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
          </button>
        </div>
      </div>

    </div>

  </div>
  <div class="w-full flex {{editStation ? 'justify-end' : 'justify-between'}}">
    <button *ngIf="!editStation" (click)="stationFormService.changeToStep(1)" type="submit" appFrontBack>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="w-5 rotate-180">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M5 12l14 0" />
        <path d="M15 16l4 -4" />
        <path d="M15 8l4 4" />
      </svg>
      Atrás
    </button>
    <button (click)="next()" [disabled]="toCreateQuadrants.length < 1 || isAnyQuadrantEmpty()" type="submit" appNextButton>
      <span *ngIf="!editStation">Continuar</span>
      <span *ngIf="editStation">Añadir</span>
      <svg *ngIf="!editStation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="w-5">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M5 12l14 0" />
        <path d="M15 16l4 -4" />
        <path d="M15 8l4 4" />
      </svg>
    </button>
  </div>
</form>
