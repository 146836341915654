import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {TableHeader} from "../../../../models/Table/TableHeader";
import {ReservationService} from "../../../../core/services/reservations/reservation.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ActiveFiltersComponent} from "../active-filters/active-filters.component";
import {AuthFormInputDirective} from "../../../directives/auth/auth-form-input.directive";
import {CalendarModule} from "primeng/calendar";
import {LoaderComponent} from "../../loader/loader.component";
import {MultiSelectModule} from "primeng/multiselect";
import {NgForOf, NgIf} from "@angular/common";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {Filter} from "../../../../models/Table/Filter";
import {filter} from "rxjs";

@Component({
  selector: 'app-table-filters',
  standalone: true,
  imports: [
    ActiveFiltersComponent,
    AuthFormInputDirective,
    CalendarModule,
    LoaderComponent,
    MultiSelectModule,
    NgForOf,
    NgIf,
    OverlayPanelModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './table-filters.component.html',
})
export class TableFiltersComponent implements OnChanges {
  filtersForm: FormGroup;
  @Input() filters: ActiveFilter[] = [];
  @Input() filtersList: Filter[] = [];
  @Input() perPage!: number;
  @Input() perPageOptions: number[] = [];
  @Input() loading: boolean = false;
  @Input() displayNames: { [key: string]: string } | null= null;
  @Output() onChangeFilters: EventEmitter<ActiveFilter[]> = new EventEmitter<ActiveFilter[]>();

  @Output() onChangePerPage: EventEmitter<number> = new EventEmitter<number>();
  @Input() tableHeaders: TableHeader[] = [];

  constructor(private fb: FormBuilder, private reservationService: ReservationService, private spinner: NgxSpinnerService) {
    this.filtersForm = this.fb.group({})
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filtersList.forEach(filter => {
      this.filtersForm.addControl(filter.key, new FormControl(null));
    });
    this.filters.forEach(filter => {
      this.patchFilter(filter);
    });
  }

  patchFilter(filter: ActiveFilter){
    const patchObject = {};
    // @ts-ignore
    patchObject[filter.column] = filter.value;
    this.filtersForm.patchValue(patchObject);
  }

  hasSearch(){
    // console.log(this.filtersList.filter(filter => {return filter.type === 'search'}).length);
    return this.filtersList.filter(filter => {return filter.type === 'search'}).length
  }
  searchItem(){
    return this.filtersList.filter(filter => {return filter.type === 'search'})[0];
  }

  search(): void {
    const formValues = this.filtersForm.value;
    const filters: ActiveFilter[] = [];

    // console.log(this.filtersForm.value);

    this.filtersList.forEach(filter => {

      // console.log(formValues[filter.key]);

      if (formValues[filter.key] && formValues[filter.key].length > 0) {

        let obj: any = {
          column: filter.key,
          operator: filter.operator,
          value: formValues[filter.key]
        }

        if ((filter.parsed === 'relationship-arr' || filter.parsed == 'array')) {
          obj['parsed'] = filter.parsed;
          obj['relation_column'] = filter.relation_column;
          obj['relation_filter_column'] = filter.relation_filter_column;
        } else if (filter.parsed === 'date-arr' || filter.parsed === 'date') {
          obj['parsed'] = filter.parsed;
          obj['format'] = 'dd/MM/Y';
        }

        filters.push(obj);
      }
    });


    this.onChangeFilters.emit(filters);
  }

  clearFilters(): void {
    this.filtersForm.reset();
    this.filters = [];
    this.onChangeFilters.emit(this.filters);
  }

  removeFilter(item: {pos: number, filter: ActiveFilter}): void {
    this.filters.splice(item.pos, 1);
    item.filter.value = null;
    this.onChangeFilters.emit(this.filters);
    this.patchFilter(item.filter);
  }

}
