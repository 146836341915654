<div *ngIf="existingReservation" class="animate-fade-in-down">
  <div class="flex flex-col justify-center items-center mb-4">
    <ng-content></ng-content>
  </div>
  <app-card-shadow>
    <div class="flex justify-around">
      <button *ngIf="editReservation && !frontMode && !editView" (click)="reset()" appNextOutlined>
        <app-loader *ngIf="loadingReset" size="small"></app-loader>
        <svg *ngIf="!loadingReset"  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-6"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" /><path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" /></svg>
      </button>
      <div *ngIf="!editReservation || (editReservation && frontMode)" class="border border-lightGray rounded-full w-full py-1 px-10 text-xl text-darkGray">{{existingReservation.registration_number}}</div>
      <input *ngIf="!frontMode && editReservation" [(ngModel)]="registration" class="border border-lightGray rounded-full w-full py-1 text-center text-xl text-darkGray">
    </div>
    <div *ngIf="!editReservation" class="border-b border-lightGray pb-3 w-full flex flex-col gap-1 mt-3">
      <p class="text-red2 text-sm">Ya tienes una cita en <span class="font-bold">{{existingReservation.station.name}}</span> el <span class="font-bold">{{existingReservation.date|date:'dd/MM/yyyy'}}</span> a las <span class="font-bold">{{existingReservation.hour.hour}}</span></p>
      <p class="text-red2 text-sm">En <span class="font-bold">{{existingReservation.service.name}} / {{existingReservation.vehicle.name}}</span></p>
    </div>
    <div class="mt-2 w-full text-center" *ngIf="!editReservation && !confirmed && !admin">
      <p class="text-darkGray text-sm">Confirma el número de teléfono para gestionar la cita</p>
      <input [(ngModel)]="phoneConfirm" appOutlineInput placeholder="Número de telefono" class="mt-2 text-center" type="text">
      <div class="flex w-full justify-center mt-3">
        <button (click)="checkPhoneNumber()" appNextButton>Confirmar</button>
      </div>
    </div>
    <div *ngIf="!editReservation && (confirmed || admin)" class="mt-2 w-full">
      <div class="grid grid-cols-2 w-full gap-4">
        <a appOutlineButton class="cursor-pointer hidden md:block" download="" href="{{environment.API_URL}}/reservations/proof/{{existingReservation.id}}/{{phoneConfirm}}"> Descargar justificante</a>
        <a appOutlineButton class="cursor-pointer md:hidden" download="" href="{{environment.API_URL}}/reservations/proof/{{existingReservation.id}}/{{phoneConfirm}}">Justificante</a>
        <button (click)="sendMessage()" class="hidden md:block" appOutlineButton>Enviar datos por sms <app-loader size="extra-small" *ngIf="loadingSms"></app-loader></button>
        <button (click)="sendMessage()" class="md:hidden" appOutlineButton>Enviar sms <app-loader size="extra-small" *ngIf="loadingSms"></app-loader></button>
        <!-- TODO: Hacer formualrio -->
        <input [(ngModel)]="email" appOutlineInput placeholder="Email" class="col-span-2" type="text">
        <button (click)="sendProofToEmail()" appOutlineButton class="col-span-2 flex justify-center items-center gap-4" [disabled]="loadingEmail">
          Enviar justificante por mail <app-loader size="extra-small" *ngIf="loadingEmail"></app-loader>
        </button>
      </div>
      <div class="mt-3 grid grid-cols-2 w-full gap-4">
        <button (click)="goToEdit()" class="flex flex-row justify-center items-center bg-yellow hover:bg-white border border-white hover:border-yellow cursor-pointer gap-x-2 px-4 py-4 rounded-full duration-200 text-darkGray">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.00" stroke-linecap="round" stroke-linejoin="round" class="text-gray-700 w-5">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z" />
            <path d="M16 3v4" />
            <path d="M8 3v4" />
            <path d="M4 11h16" />
            <path d="M7 14h.013" />
            <path d="M10.01 14h.005" />
            <path d="M13.01 14h.005" />
            <path d="M16.015 14h.005" />
            <path d="M13.015 17h.005" />
            <path d="M7.01 17h.005" />
            <path d="M10.01 17h.005" />
          </svg>
          <p class="hidden md:block">Gestionar cita</p>
          <p class="md:hidden text-sm">Gestionar</p>
        </button>
        <button [disabled]="loadingCancel" (click)="cancel()" class="flex justify-center gap-4 text-center items-center cursor-pointer text-sm rounded-full px-4 py-4 border border-red2 text-red2 hover:bg-red2 hover:text-white duration-200">
          <span class="hidden md:block">ANULAR CITA</span> <span class="md:hidden text-sm">ANULAR</span>  <app-loader size="extra-small" *ngIf="loadingCancel"></app-loader>
        </button>
      </div>
    </div>
    <div *ngIf="editReservation"  class="flex flex-col mt-4 justify-start px-0 md:px-4 animate-fade-in">
      <p *ngIf="frontMode" class="text-xs text-lightGray text-start my-2">Si necesita cambiar los demás datos cancele y vuelva a realizar otra reserva</p>
      <div class="grid grid-cols-3 gap-x-8 gap-y-2">
        <div class="animate-fade-in text-start {{frontMode ? 'border-b border-primary' : ''}}">
          <p class="text-primary text-sm">Tipo de inspección</p>
        </div>
        <div class="animate-fade-in text-start {{frontMode ? 'border-b border-primary' : ''}}">
          <p class="text-primary text-sm" >Tipo de vehículo </p>
        </div>
        <div class="animate-fade-in text-start {{frontMode ? 'border-b border-primary' : ''}}">
          <p class="text-primary text-sm">Tipo de combustible</p>
        </div>
        <div *ngIf="frontMode" class="animate-fade-in text-start">
          <p class="text-darkGray text-sm">{{existingReservation.service.name}}</p>
        </div>
        <div *ngIf="!frontMode" class="flex flex-col gap-y-2 animate-fade-in">
          <select appFormSelectSquare [(ngModel)]="selectedService" (change)="loadVehicles()">
            <option disabled [ngValue]="undefined">Selecciona un tipo de inspección</option>
            <option *ngFor="let service of services" [ngValue]="service">{{service.name}}</option>
          </select>
        </div>
        <div *ngIf="frontMode" class="animate-fade-in text-start">
          <p class="text-darkGray text-sm">{{existingReservation.vehicle.name}}</p>
        </div>
        <div *ngIf="!frontMode" class="flex flex-col gap-y-2 animate-fade-in">
          <select appFormSelectSquare [(ngModel)]="selectedVehicle" (change)="loadFuels()">
            <option disabled [ngValue]="undefined">Selecciona un vehículo</option>
            <option *ngFor="let vehicle of vehicles" [ngValue]="vehicle">{{vehicle.name}}</option>
          </select>
        </div>
        <div *ngIf="frontMode" class="animate-fade-in text-start">
          <p class="text-darkGray text-sm">{{existingReservation.fuel.name}}</p>
        </div>
        <div *ngIf="!frontMode" class="flex flex-col gap-y-2 animate-fade-in">
          <select appFormSelectSquare [(ngModel)]="selectedFuel" (change)="loadStates()">
            <option disabled [ngValue]="undefined">Selecciona un combustible</option>
            <option *ngFor="let fuel of fuels" [ngValue]="fuel">{{fuel.name}}</option>
          </select>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 my-6 gap-y-2 gap-x-8">
        <div>
          <div class="animate-fade-in text-start pr-4 w-fit {{frontMode ? 'border-b border-primary' : ''}}">
            <p class="text-primary text-sm">Comunidad</p>
          </div>
          <div *ngIf="!frontMode" class="flex flex-col gap-y-2 animate-fade-in">
            <select appFormSelectSquare [(ngModel)]="selectedState" (change)="loadStations()">
              <option disabled [ngValue]="undefined">Selecciona una comunidad</option>
              <option *ngFor="let state of states" [ngValue]="state">{{state.name}}</option>
            </select>
          </div>
          <div *ngIf="frontMode" class="animate-fade-in text-start">
            <p class="text-darkGray text-sm">{{existingReservation.station.state.name}}</p>
          </div>
        </div>

        <div>
          <div class="animate-fade-in text-start">
            <p class="text-primary text-sm">Centro</p>
          </div>

          <div class="flex flex-col gap-y-2 animate-fade-in">
            <select appFormSelectSquare [(ngModel)]="selectedStation" (change)="loadDates()">
              <option disabled [ngValue]="undefined">Selecciona una estación</option>
              <option *ngFor="let station of stations" [ngValue]="station">{{station.name}}</option>
            </select>
          </div>
        </div>



      </div>
      <div class="grid grid-cols-1 md:grid-cols-4 gap-x-6 animate-fade-in">
        <div class="md:col-span-3 flex text-start flex-col">
          <h1 class="text-sm text-primary mb-2">Fecha</h1>
          <app-month-calendar [loading]="loadingDates" (nextMonth)="nextMonth()" (prevMonth)="prevMonth()" [changeMonth]="true" [frontMode]="true"  [blockedDates]="blockedDates" [month]="month" [year]="year" [selectDaysMode]="true" selectedColor="#168FFB" [currentSelection]="[selectedDate ?? '']" (onSelectDayChange)="onSelectedDay($event)"></app-month-calendar>
        </div>
        <div class="flex flex-col text-center md:text-start animate-fade-in mt-4 md:mt-0">
          <label class="text-sm mb-2 pl-[4px] text-primary">Hora <span *ngIf="!selectHour" class="text-darkGray text-sm ml-10">{{existingReservation.hour.hour}}h</span></label>
          <select *ngIf="!loadingHours && selectHour" [(ngModel)]="selectedHour" appFormSelectSquare >
            <option disabled [ngValue]="undefined">Selecciona una hora</option>
            <option class="{{hour.available<1 ? 'bg-lightGray2' : ''}}" [ngValue]="hour" [disabled]="hour.available<1" *ngFor="let hour of hours">{{hour.hour}} {{hour.available>0 ? ' - '+hour.available : ''}}</option>
          </select>
          <button *ngIf="!selectHour" class="mt-2 hover:shadow-lg duration-300" (click)="selectHour=true" appNextOutlined>Cambiar hora</button>
          <button class="mt-8 mb-2 hover:shadow-lg" appNextButton (click)="update()"> Cambiar</button>
          <button *ngIf="!editView" (click)="toggleEditReservation()" class="bg-red-500 text-white shadow-sm p-2 px-4 rounded-2xl hover:shadow-lg duration-300">Volver</button>
          <button *ngIf="editView" (click)="closeEditModal()" type="button" class="bg-red-500 text-white shadow-sm p-2 px-4 rounded-2xl hover:shadow-lg duration-300">
            Cancelar
          </button>
        </div>
      </div>

    </div>

  </app-card-shadow>
  <div *ngIf="!editReservation" class="mx-auto py-6 w-full flex items-center justify-center">
    <div (click)="onReturnClick.emit()" appFrontBack>
      <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l4 4" /><path d="M5 12l4 -4" /></svg>
      <p>Volver</p>
    </div>
  </div>
</div>
