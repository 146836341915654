<div class="pb-4" *ngIf="filters.length > 0">
  <small class="text-darkGray"> Filtros Aplicados: </small>

  <span *ngFor="let filter of filters; let i = index" class="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-lightBlue text-primary">

      <ng-container [ngSwitch]="filter.parsed">
        <span *ngSwitchCase="undefined"> {{ displayNames[filter.column] }}: {{ filter.value }} </span>
        <span *ngSwitchCase="'date'">{{ displayNames[filter.column] }}: {{ filter.value | date:'dd/MM/Y' }}</span>
        <span *ngSwitchCase="'date-arr'">{{ displayNames[filter.column] }}: <span *ngFor="let date of filter.value; let last = last">{{ date | date:filter.format ?? 'dd/MM/Y' }} <ng-container *ngIf="!last"> al </ng-container></span></span>
        <span class="flex gap-1" *ngSwitchCase="'relationship-arr'">{{ displayNames[filter.column] }}:
          <span *ngFor="let data of filter.value; let last = last">
            <span>{{getName(data, filter.relation_column ?? 'name')}}<ng-container *ngIf="!last">,</ng-container></span>
          </span>
        </span>
        <span class="flex gap-1" *ngSwitchCase="'array'">
          {{ displayNames[filter.column] }}:
          <span *ngFor="let data of filter.value; let last = last">
            <span>{{getName(data, filter.relation_column ?? 'name')}}<ng-container *ngIf="!last">,</ng-container></span>
          </span>
        </span>
      </ng-container>

      <button (click)="onRemoveFilter.emit({pos: i, filter})" type="button" class="flex-shrink-0 ml-2 p-0.5 rounded-full inline-flex items-center justify-center text-primary hover:bg-primary hover:text-lightBlue focus:outline-none focus:bg-indigo-500 focus:text-white">
        <span class="sr-only">Remover opción de filtro</span>
        <svg class="w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
          <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"></path>
        </svg>
      </button>
    </span>

  <button (click)="onClearFilters.emit()" class="focus:outline-none active:outline-none ml-2">
      <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-lightGray2 text-darkGray hover:bg-darkGray hover:text-lightGray2">
        Borrar
      </span>
  </button>

</div>
