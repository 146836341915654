import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {StationFormService} from "../../../../../../../core/services/stations/station-form.service";
import {StationForm} from "../../../../../../../models/StationForm/StationForm";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {Step2Quadrants} from "../../../../../../../models/StationForm/Step2Quadrants";
import {FormInputDirective} from "../../../../../../../shared/directives/forms/form-input.directive";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {MonthCalendarComponent} from "../../../../../../../shared/components/month-calendar/month-calendar.component";
import {
  exportSectionsToJson,
  importSectionsFromJson,
  QuadrantSection
} from "../../../../../../../models/QuadrantSection";
import {FormErrorDirective} from "../../../../../../../shared/directives/forms/form-error.directive";
import {ColorPickerModule} from "primeng/colorpicker";
import {ToastrService} from "ngx-toastr";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {StationService} from "../../../../../../../core/services/stations/station.service";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {formatDateEn} from "../../../../../../../const/functions";
import {
  CreateQuadrantHour,
  exportHoursToCsv,
  exportHoursToJson, exportHoursToTxt, generateHourList, importHourFromJson, importHoursFromCsv, importHoursFromTxt
} from "../../../../../../../models/StationForm/CreateQuadrantHour";
import {ExportComponent} from "../../../../../../../shared/components/export/export.component";
import {ImportComponent} from "../../../../../../../shared/components/import/import.component";
import {OutlineButtonDirective} from "../../../../../../../shared/directives/outline/outline-button.directive";
import {NextButtonDirective} from "../../../../../../../shared/directives/buttons/next.directive";
import {NextSquareButtonDirective} from "../../../../../../../shared/directives/buttons/next-square.directive";
import {HoursFormComponent} from "../../../../../../../shared/components/hours-form/hours-form.component";
import {QuadrantLine} from "../../../../../../../models/QuadrantLine";
import {ConfirmationService} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";



@Component({
  selector: 'app-step3-station',
  standalone: true,
  imports: [
    JsonPipe,
    NgForOf,
    FormInputDirective,
    FormsModule,
    MonthCalendarComponent,
    NgIf,
    ReactiveFormsModule,
    FormErrorDirective,
    ColorPickerModule,
    OverlayPanelModule,
    ExportComponent,
    ImportComponent,
    OutlineButtonDirective,
    NextButtonDirective,
    NextSquareButtonDirective,
    HoursFormComponent,
    ConfirmDialogModule
  ],
  providers : [ConfirmationService],
  templateUrl: './step3-station.component.html',
  styleUrl: './step3-station.component.css'
})
export class Step3StationComponent{
  @Input() quadrant: Step2Quadrants | undefined;
  stationForm: StationForm|null = null;
  hours: CreateQuadrantHour[] = [];
  @Input() editStation:boolean=false;
  @ViewChild('hoursForm') hoursForm!: HoursFormComponent;
  stationId: string = "";

  // sections: QuadrantSection[] = [];

  months: number[] = [1,2,3,4,5,6,7,8,9,10,11,12];
  year: number = 2024;

  weekDays: any[] = [
    { day: 'L', name: 'Lunes', key: 1 },
    { day: 'M', name: 'Martes', key: 2 },
    { day: 'X', name: 'Miércoles', key: 3 },
    { day: 'J', name: 'Jueves', key: 4 },
    { day: 'V', name: 'Viernes', key: 5 },
    { day: 'S', name: 'Sábado', key: 6 },
    { day: 'D', name: 'Domingo', key: 0 },
  ];

  sectionForm!: FormGroup;
  sectionDateForm!: FormGroup;

  editSectionMode:boolean = false;
  editSection:QuadrantSection|undefined;
  manualSelectDays: boolean = false;
  currentSelectedDays: string[] = [];

  /*ngOnChanges(changes: SimpleChanges) {
    if (changes['quadrant']) {
      // this.sections = this.quadrant?.sections ?? [];
    }
  }*/

  constructor(private stationFormService: StationFormService, private fb: FormBuilder,
              private toastr: ToastrService, private stationService: StationService,
              private router: Router, private confirmationService:ConfirmationService,
              private route:ActivatedRoute){
    this.stationFormService.stationForm$.subscribe(stationForm => {
      this.stationForm = stationForm;
    });
  }

  onUpdateDates(dates: string[]){
    this.currentSelectedDays = dates;
  }

  onFormSubmit(data: any){
    this.hours = this.hoursForm.hours;
    if (this.editSection) {
      if (this.quadrant){
        for(let sectionToUpdate of  this.quadrant!.sections){
          if(sectionToUpdate.name===this.editSection.name){

            if(this.hasOverlappingDays(data.info,this.quadrant?.sections ?? [])){
              this.toastr.error('Ya tienes una configuración para este día.');

            }else {
                sectionToUpdate.name= data.name;
                sectionToUpdate.color= data.color;
                sectionToUpdate.hours=this.hours;
                sectionToUpdate.days= data.info;
                this.stationFormService.updateQuadrant(this.quadrant);
                this.toastr.success('Linea de cuadrante editada correctamente');
            }
          }
        }
        this.resetAll();
      }
    } else {
      this.addSection(data);
      this.resetAll();
    }
  }

  hasOverlappingDays(newDays: number[] | string[], existingSections: any[]): boolean {
    return existingSections.some(section => {
      // Omite la sección que está siendo editada si estamos en modo edición
      if (section.name === this.editSection?.name) {
        return false; // Ignora esta sección y sigue verificando las demás
      }

      // Comprobación de superposición de días
      // @ts-ignore
      return section.days.some((day: number | string) => newDays.includes(day));
    });
  }

  saveDaysToSection(days: number[]|string[], name: string, color: string){
    if (this.hasOverlappingDays(days, this.quadrant?.sections ?? [])) {
      this.toastr.error('Ya tienes una configuración para este día.');
    } else {
      const filteredDates = days.filter(date => date !== null);
      this.quadrant?.sections.push({
        name: name,
        days:filteredDates,
        color: color,
        hours: this.hours
      });
      if (this.quadrant) {
        this.stationFormService.updateQuadrant(this.quadrant);
      }
    }
  }
  addSection(data:any) {
    if (data.info) {
      const selectedColor = data.color;
      const days =  data.info;
      const name = data.name;
      this.saveDaysToSection(days, name, selectedColor);
    } else {
      this.sectionForm.markAllAsTouched();
    }
  }
  addDateSection(){
    if (this.sectionDateForm.valid) {
      const selectedColor = this.sectionDateForm.value.selectedColor;
      const name = this.sectionDateForm.value.name;
      this.saveDaysToSection(this.currentSelectedDays, name, selectedColor);
      this.manualSelectDaysMode();
    } else {
      this.sectionForm.markAllAsTouched();
    }
  }

  removeSection(section: QuadrantSection){
    this.confirmationService.confirm({
    header: 'Eliminar linea de cuadrante',
    message: '¿Está seguro de que desea borrar esta línea de cuadrante?',
    accept: () => {
      if (this.quadrant) {
        const quadrantSectionIndex = this.quadrant.sections.findIndex(sectionE => sectionE.name === section.name);
        if (quadrantSectionIndex > -1) {
          this.quadrant.sections.splice(quadrantSectionIndex, 1);
          this.stationFormService.updateQuadrant(this.quadrant);
          this.editSection=undefined;
          this.editSectionMode=false;
          this.manualSelectDays=false;
        }
      }
  }
 });
  }

  generateHourList(section?:QuadrantSection): void {
    if(this.editSectionMode){
      this.hours = generateHourList(section);
    } else {
      this.hours = generateHourList();
    }

  }

  exportSections(type: 'json'|'csv'|'text'){
    if (type === 'csv') {
    } else if (type === 'json') {
      exportSectionsToJson(this.quadrant?.sections ?? []);
    }
  }
  importSections(data: {type: 'json'|'csv'|'text', file: any}){
    if (data.type === 'csv') {
    } else if (data.type === 'json') {
      const file = data.file.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            if (this.quadrant) {
              this.quadrant.sections = importSectionsFromJson(e, this.toastr);
              this.stationFormService.updateQuadrant(this.quadrant);
            }
          } catch (error) {
            console.error('Error parsing JSON', error);
          }
        };
        reader.readAsText(file);
      }
    }
  }

  totalCapacityLine(section: QuadrantSection)
  {

    let total = 0;

    section.hours.forEach(hour => {
      total += hour.capacity;
    });

    return total;
  }

  manualSelectDaysMode(){
    this.currentSelectedDays = [];
    this.sectionDateForm.patchValue({
      name: '',
      selectedColor: '#FF5E59'
    });
    this.manualSelectDays = !this.manualSelectDays;
  }

  onSelectDate(date: string){
    this.hoursForm.addDate(date);
    // console.log(this.hoursForm);
  }

  selectSection(section: QuadrantSection){
    this.currentSelectedDays = [];
    // console.log(section.days);
    if (isNaN(section.days[0])) {
      this.currentSelectedDays = section.days;
      this.editSection = section;
      this.manualSelectDays = true;
    } else {
      this.editSection = section;
      this.manualSelectDays = false;
    }
  }

  cancelEdit() {
    this.editSection = undefined;
    this.manualSelectDays = false;
    this.currentSelectedDays = [];
    // this.selectedDays = [];
  }

  resetAll() {
    this.hoursForm.resetAll();
    this.editSection = undefined;
    this.currentSelectedDays = [];
    this.manualSelectDays = false;
  }

  save(){
    // console.log(this.quadrant);
    if (this.stationForm && this.stationForm.step2?.quadrants) {
      let sectionExist:boolean=true;
      for (let quadrant of this.stationForm.step2?.quadrants){
        if(quadrant.sections.length==0){
          sectionExist=false
          this.toastr.error('Debe crear alguna sección para el cuadrante')
          break;
        }

      }
      if(sectionExist){
        if(this.editStation){
          this.route.paramMap.subscribe(params => {
            this.stationId = params.get('id') || "";
            // Aquí puedes utilizar el valor de 'id' según lo que necesites hacer
          });
          if(this.stationId){
            /*this.stationService.addQuadrants(this.stationForm,+this.stationId).subscribe({
              next:(response)=>{
                if(response.data){
                  this.toastr.success(response.message)
                  this.resetAll()
                }
              },
              error:(err)=> {
                this.toastr.error(err.error.message)
              }
            })*/
          }
        } else {

          this.stationService.create(this.stationForm).subscribe({
            next: (value) => {
              this.toastr.success(value.message);
              this.stationFormService.reset();
              this.router.navigate(['/dashboard/estaciones']);
            },
            error: (err) => {
              // console.error(err.error.message);
              console.log(err.error);
              this.toastr.error(err.error.message);
            }
          })
        }
      }

    }
  }
}
