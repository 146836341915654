<nav class="w-full bg-white py-4 flex items-center flex-column flex-wrap md:flex-row justify-between" aria-label="Table navigation">
  <span class="text-sm font-normal text-gray-500 mb-4 md:mb-0 block w-full md:inline md:w-auto">
    Mostrando
    <span class="font-semibold text-gray-900">{{ (paginator.current_page - 1) * paginator.per_page + 1 }}</span>
    -
    <span class="font-semibold text-gray-900">{{ Math.min(paginator.current_page * paginator.per_page , paginator.total) }}</span>
    de
    <span class="font-semibold text-gray-900">{{ paginator.total }}</span>
  </span>
  <ul class="inline-flex items-center -space-x-px rtl:space-x-reverse text-sm h-8">
    <!--<li *ngFor="let page of paginator.links">
      <button [disabled]="!page.url" (click)="loadPage(page.url)" [class.bg-primary]="page.active" [class.text-primary]="page.active" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 disabled:text-gray-500 disabled:bg-gray-100">
        {{page.label}}
      </button>
    </li>-->
    <li class="mr-2">
      <app-loader *ngIf="loading" />
    </li>
    <li>
      <button [disabled]="!paginator.prev_page_url" (click)="loadPage(paginator.prev_page_url)"  class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 disabled:text-gray-500 disabled:bg-gray-100">Anterior</button>
    </li>
    <li *ngFor="let page of getPages()">
      <ng-container *ngIf="page !== -1; else ellipsis">
        <button (click)="loadPage(paginator.path+'?page='+page)" [class.bg-primary]="paginator.current_page === page" [class.text-primary]="paginator.current_page === page" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 disabled:text-gray-500 disabled:bg-gray-100">{{ page }}</button>
      </ng-container>
      <ng-template #ellipsis>
        <span class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300">...</span>
      </ng-template>
    </li>
    <li>
      <button [disabled]="!paginator.next_page_url" (click)="loadPage(paginator.next_page_url)"  class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 disabled:text-gray-500 disabled:bg-gray-100">
        Siguiente
      </button>
    </li>

  </ul>
</nav>
