import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {User} from "../../../models/User";
import {State} from "../../../models/State";
import {QuadrantLine} from "../../../models/QuadrantLine";
import {Vehicle} from "../../../models/Vehicle";

@Injectable({
  providedIn: 'root'
})
export class StatesService {

  constructor(private http: HttpClient) { }

  list(): Observable<Response<State[]>>{
    return this.http.get<Response<State[]>>(`${environment.API_URL}/states/list`)
  }

  getById(stateId: number|string): Observable<Response<State>>{
    return this.http.get<Response<State>>(`${environment.API_URL}/states/get/${stateId}`)
  }

  addQuadrantReduction(stateId: number, body: any): Observable<Response<QuadrantLine>>{
    return this.http.post<Response<QuadrantLine>>(`${environment.API_URL}/states/quadrant/${stateId}`, body)
  }

  national(): Observable<Response<QuadrantLine[]>>{
    return this.http.get<Response<QuadrantLine[]>>(`${environment.API_URL}/states/national`)
  }

  addQuadrantReductionNational(body: any): Observable<Response<QuadrantLine>>{
    return this.http.post<Response<QuadrantLine>>(`${environment.API_URL}/states/quadrant`, body)
  }

  setImage(stateId: number, image: any){
    const formData: FormData = new FormData();
    // Agregar el archivo al FormData si existe
    if (image) {
      formData.append('image', image);
    }
    return this.http.post<Response<State>>(`${environment.API_URL}/states/image/${stateId}`, formData, {
      headers: {
        'enctype': 'multipart/form-data',  // Asegura que los encabezados están correctos
      }
    })
  }
}
