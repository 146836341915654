import { createReducer, on } from '@ngrx/store';
import { ReservationForm } from '../../models/Front/ReservationForm';
import {
  updateConfirmed,
  updateShowSummary,
  updateStep,
  updateStep1,
  updateStep2,
  updateStep3,
  updateStep4,
  updateStep5,
  updateStep6
} from './stepper.actions';

// Define the key for localStorage and expiration time
const LOCAL_STORAGE_KEY = 'stepperState';
const EXPIRATION_TIME = 20 * 60 * 1000; // 20 minutes in milliseconds

// Initial state
const initialStepperState: ReservationForm = {
  confirmed:false,
  showSummary: false,
  step: 1,
  steps: {}
};

// Load state from localStorage
function loadState(): ReservationForm {
  const savedState = null;//localStorage.getItem(LOCAL_STORAGE_KEY);
  if (savedState) {
    const { timestamp, ...state } = JSON.parse(savedState);
    if (Date.now() - timestamp < EXPIRATION_TIME) {
      return state;
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY); // Remove expired data
    }
  }
  return initialStepperState;
}

// Save state to localStorage
function saveState(state: ReservationForm): void {
  const stateToSave = {
    ...state,
    timestamp: Date.now()
  };
  // localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(stateToSave));
}

export const stepperReducer = createReducer(
  loadState(), // Initialize the state from localStorage

  on(updateConfirmed, (state, { confirmed }) => {
    const newState = {
      ...state,
      confirmed
    };
    saveState(newState); // Save new state to localStorage
    return newState;
  }),

  on(updateShowSummary, (state, { showSummary }) => {
    const newState = {
      ...state,
      showSummary
    };
    saveState(newState); // Save new state to localStorage
    return newState;
  }),

  on(updateStep, (state, { step }) => {
    const newState = {
      ...state,
      step
    };
    saveState(newState); // Save new state to localStorage
    return newState;
  }),

  on(updateStep1, (state, { step1 }) => {
    const newState = {
      ...state,
      steps: {
        ...state.steps,
        step1
      }
    };
    saveState(newState); // Save new state to localStorage
    return newState;
  }),

  on(updateStep2, (state, { step2 }) => {
    const newState = {
      ...state,
      steps: {
        ...state.steps,
        step2
      }
    };
    saveState(newState); // Save new state to localStorage
    return newState;
  }),

  on(updateStep3, (state, { step3 }) => {
    const newState = {
      ...state,
      steps: {
        ...state.steps,
        step3
      }
    };
    saveState(newState); // Save new state to localStorage
    return newState;
  }),

  on(updateStep4, (state, { step4 }) => {
    const newState = {
      ...state,
      steps: {
        ...state.steps,
        step4
      }
    };
    saveState(newState); // Save new state to localStorage
    return newState;
  }),

  on(updateStep5, (state, { step5 }) => {
    const newState = {
      ...state,
      steps: {
        ...state.steps,
        step5
      }
    };
    saveState(newState); // Save new state to localStorage
    return newState;
  }),

  on(updateStep6, (state, { step6 }) => {
    const newState = {
      ...state,
      steps: {
        ...state.steps,
        step6
      }
    };
    saveState(newState); // Save new state to localStorage
    return newState;
  })
);
