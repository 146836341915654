import { createFeatureSelector, createSelector } from '@ngrx/store';
import {ReservationForm, Step1, Step2, Step3, Step4, Step5, Step6} from "../../models/Front/ReservationForm";

// Selecciona el estado completo del stepper
export const selectStepperState = createFeatureSelector<ReservationForm>('stepper');

//Selecciona el valor de 'confirmed'
export const selectConfirmed = createSelector(
  selectStepperState,
  (state:ReservationForm) => state.confirmed
)

// Selecciona el valor de showSummary
export const selectShowSummary = createSelector(
  selectStepperState,
  (state:ReservationForm) => state.showSummary
)

// Selecciona el valor de 'step'
export const selectCurrentStep = createSelector(
  selectStepperState,
  (state: ReservationForm) => state.step
);

// Selecciona el estado del paso 1
export const selectStep1 = createSelector(
  selectStepperState,
  (state: ReservationForm) => state.steps.step1
);

// Selecciona el estado del paso 2
export const selectStep2 = createSelector(
  selectStepperState,
  (state: ReservationForm) => state.steps.step2
);

// Selecciona el estado del paso 3
export const selectStep3 = createSelector(
  selectStepperState,
  (state: ReservationForm) => state.steps.step3
);

// Selecciona el estado del paso 4
export const selectStep4 = createSelector(
  selectStepperState,
  (state: ReservationForm) => state.steps.step4
);

export const selectStep5 = createSelector(
  selectStepperState,
  (state: ReservationForm) => state.steps.step5
);

export const selectStep6 = createSelector(
  selectStepperState,
  (state: ReservationForm) => state.steps.step6
);
