import { Directive } from '@angular/core';

@Directive({
  selector: '[appAuthFormInput]',
  host: {
    'class': 'bg-white border border-lightGray text-darkGray rounded-2xl focus:ring-primary focus:border-primary block w-full p-2.5'
  },
  standalone: true
})
export class AuthFormInputDirective {

  constructor() { }

}
