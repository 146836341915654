import {Component, Input} from '@angular/core';
import {BookFormService} from "../../../../core/services/book-form.service";
import {User} from "../../../../models/User";
import {ReservationForm} from "../../../../models/Front/ReservationForm";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {NgForOf, NgIf} from "@angular/common";
import {Router, RouterLink} from "@angular/router";
import {updateConfirmed, updateStep} from "../../../../store/reservationForm/stepper.actions";
import {AuthService} from "../../../../core/services/auth/auth.service";

@Component({
  selector: 'app-stepperv2',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    RouterLink
  ],
  templateUrl: './stepperv2.component.html',
  styleUrl: './stepperv2.component.css'
})
export class Stepperv2Component {
  @Input() formPassed:boolean=false;
  currentStep: number = 1;
  confirmed = false;
  user: User | null = null;

  steps = [
    { name: 'MATRÍCULA', pos: 1 },
    { name: 'VEHÍCULO', pos: 2 },
    { name: 'LUGAR', pos: 3 },
    { name: 'FECHA', pos: 4 },
    { name: 'DATOS', pos: 5 },
    { name: 'PAGO', pos: 6 },
    { name: 'CONFIRMACIÓN', pos: 7 },
  ];

  constructor(private bookFormService: BookFormService,
              public authStateService: AuthStateService,
              private authService: AuthService,
              private router:Router) {
    this.bookFormService.reservationForm$.subscribe({
      next: (form: ReservationForm) => {
        this.currentStep = form.step;
        this.confirmed = form.confirmed;
        this.formPassed = form.confirmed;
      }
    });
    this.authStateService.currentUser.subscribe(data => {
      this.user = data;
      if (data) {
        if (data.roles.includes('professional')) {
          this.steps.splice(0,1);
        }
      }
    });
  }

  public goStep(step:number) {
    if(step<this.currentStep && !this.confirmed){
      // console.log('Current Step before:', this.currentStep);
      this.bookFormService.setCurrentStep(step);
      // console.log('Current Step after:', this.currentStep);
    }
  }

  public reset(){
    if (this.user && this.user.roles.includes('professional')) {
      this.bookFormService.resetProfessional({
        registration_number: this.user.profile.name
      }, {name: this.user.profile.name, email: this.user.profile.email, phone_number: this.user.profile.phone})
    } else {
      this.bookFormService.reset(undefined);
    }
  }

  logOut() {
    this.authService.logOut().subscribe({
      next: value => {
        this.authStateService.setUser(null);
        // TODO: RESETEAR FORMUALRIO DE NUEVA RESERVA
        this.router.navigate(['/login'])
      }, error: () => {
        this.authStateService.setUser(null);
        // TODO: RESETEAR FORMUALRIO DE NUEVA RESERVA
        this.router.navigate(['/login'])
      }
    })
  }
}
