<div class="w-full">

  <ng-template #plate>
    <p class="text-xl text-darkGray">¿Cuál es tu matrícula?</p>
  </ng-template>

  <ng-template #matriculaFormatos>
    <div (click)="toggleDialog()" class="flex flex-row items-center text-xs mt-4 gap-x-2 cursor-pointer">
      <div class="cursor-pointer"><p class="rounded-full border border-primary text-primary w-4 h-4 text-center">?</p></div>
      <p class="text-darkGray">Formatos de matrícula</p>
    </div>
  </ng-template>

  <div (click)="toggleDialog()" class="absolute inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50" *ngIf="visible">
    <div (click)="$event.stopPropagation()" class="max-w-md w-full bg-white rounded-2xl px-4 py-4 mx-auto animate-fade-in-up animate-delay-100">

      <button (click)="toggleDialog()" class="rounded-full absolute -top-1.5 -right-1.5 bg-black text-white p-1">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
      </button>

      <p class="text-left text-darkGray text-xs mb-2 mt-2">Sin guiones ni espacios.</p>
      <div class="w-full gap-14 text-darkGray">
        <table class="min-w-full bg-white rounded-lg">
          <thead>
            <tr class="text-left">
              <th class="px-6 py-3 font-bold text-sm text-darkGray">PATRÓN</th>
              <th class="px-6 py-3 font-bold text-sm text-darkGray">EJEMPLO</th>
            </tr>
          </thead>
          <tbody>
          <tr class="text-left">
            <td class="px-6 pb-4">
              <ul class="list-disc list-inside space-y-1 text-sm text-darkGray">
                <li>LNNNNLL</li>
                <li>NNNNLLL</li>
                <li>PPNNNNLL</li>
                <li>PPNNNNNN</li>
                <li>LNNNNLLL</li>
                <li>LNNNNN</li>
                <li>LLNNNNL</li>
                <li>LNNNNL</li>
                <li>LNNNNNN</li>
                <li>LLNNNN</li>
                <li>LLNNNNN</li>
                <li>LNNNNNL</li>
                <li>LLLNNNNN</li>
                <li>LLNNNNNLL</li>
                <li>LLNNNNNL</li>
              </ul>
            </td>

            <td class="px-6 pb-4">
              <ul class="list-disc list-inside space-y-1 text-sm text-darkGray">
                <li>A1234BC</li>
                <li>1234DCD</li>
                <li>BA1234VD</li>
                <li>GR123456</li>
                <li>A1234CDR</li>
                <li>A12345</li>
                <li>AB1234C</li>
                <li>A1234B</li>
                <li>A123456</li>
                <li>AB1234</li>
                <li>AB12345</li>
                <li>A12345B</li>
                <li>MMA03332</li>
                <li>BA38842VE</li>
                <li>AB01744R</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <p class="text-left text-darkGray text-xs mt-2"><span class="font-bold">"P"</span> : Siglas de la provincia</p>
      <p class="text-left text-darkGray text-xs mt-1"><span class="font-bold">"L"</span>: Carácter alfabético válido</p>
      <p class="text-left text-darkGray text-xs mt-1"><span class="font-bold">"N"</span> : Carácter numérico</p>
    </div>
  </div>


  <div *ngIf="!existingReservation" class="animate-fade-in-down">
    <p class="text-primary text-2xl mb-6 text-center">Reserva, gestiona o anula tu cita</p>
    <app-card-shadow>
      <ng-container *ngTemplateOutlet="plate"></ng-container>
      <form [formGroup]="step1Form" class="w-full flex flex-col items-center">
        <input formControlName="registration_number" class="outline-none border p-2 md:px-4 md:py-4 rounded-full text-center w-60 md:w-96 mt-2" type="text" placeholder="0000AAA">
        <div class="my-1" *ngIf="step1Form.get('registration_number')?.invalid && step1Form.get('registration_number')?.touched" appFormError> Matrícula no valida</div>
        <div class="my-1" *ngIf="error" appFormError> {{ error }}</div>
        <ng-container *ngTemplateOutlet="matriculaFormatos"></ng-container>
        <div class="max-w-48">
        <button [disabled]="loading" type="button" (click)="update()" appNextButton class="mt-3">
          <span>Continuar</span>
          <span>
            <svg *ngIf="!loading"  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M15 16l4 -4" /><path d="M15 8l4 4" /></svg>
            <app-loader size="extra-small" *ngIf="loading"></app-loader>
          </span>
        </button>
        </div>
      </form>
    </app-card-shadow>
  </div>

  <app-existing-reservation-card (onUpdateReservation)="existingReservation = $event" (onCancelReservation)="existingReservation=$event" *ngIf="existingReservation" [existingReservation]="existingReservation"
                                 (onReturnClick)="returnRegistration()">
    <ng-container *ngTemplateOutlet="plate"></ng-container>
    <ng-container *ngTemplateOutlet="matriculaFormatos"></ng-container>
  </app-existing-reservation-card>

  <!--<div *ngIf="existingReservation" class="animate-fade-in-down">
    <div class="flex flex-col justify-center items-center mb-4">
      <ng-container *ngTemplateOutlet="plate"></ng-container>
      <ng-container *ngTemplateOutlet="matriculaFormatos"></ng-container>
    </div>
    <app-card-shadow>
      <div class="border border-lightGray rounded-full py-1 px-10 text-xl text-darkGray">{{existingReservation.registration_number}}</div>
      <div class="border-b border-lightGray pb-3 w-full flex flex-col gap-1 mt-3">
        <p class="text-red2 text-sm">Ya tienes una cita en <span class="font-bold">{{existingReservation.station.name}}</span> el <span class="font-bold">{{existingReservation.date|date:'dd/MM/yyyy'}}</span> a las <span class="font-bold">{{existingReservation.hour.hour}}</span></p>
        <p class="text-red2 text-sm">En <span class="font-bold">{{existingReservation.service.name}}/ {{existingReservation.vehicle.name}}</span></p>
      </div>
      <div class="mt-2 w-full">
        <div class="grid grid-cols-2 w-full gap-4">
          <button appOutlineButton>Desargar justificante</button>
          <button appOutlineButton>Enviar datos por sms</button>
          &lt;!&ndash; TODO: Hacer formualrio &ndash;&gt;
          <input appOutlineInput placeholder="Email" class="col-span-2" type="text">
          <button appOutlineButton class="col-span-2">Enviar justificante por mail</button>
        </div>
        <div class="mt-3 grid grid-cols-2 w-full gap-4">
          <button class="flex flex-row justify-center items-center bg-yellow hover:bg-white border border-white hover:border-yellow cursor-pointer gap-x-2 px-4 py-4 rounded-full duration-200 text-darkGray">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.00" stroke-linecap="round" stroke-linejoin="round" class="text-gray-700 w-5">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z" />
              <path d="M16 3v4" />
              <path d="M8 3v4" />
              <path d="M4 11h16" />
              <path d="M7 14h.013" />
              <path d="M10.01 14h.005" />
              <path d="M13.01 14h.005" />
              <path d="M16.015 14h.005" />
              <path d="M13.015 17h.005" />
              <path d="M7.01 17h.005" />
              <path d="M10.01 17h.005" />
            </svg>
            <p>Gestionar cita</p>
          </button>
          <button class="text-center items-center cursor-pointer text-sm rounded-full px-4 py-4 border border-red2 text-red2 hover:bg-red2 hover:text-white duration-200">ANULAR CITA</button>
        </div>
      </div>
    </app-card-shadow>
    <div class="mx-auto py-6 w-full flex items-center justify-center">
      <div (click)="returnRegistration()" appFrontBack>
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l4 4" /><path d="M5 12l4 -4" /></svg>
        <p>Introducir otra matrícula</p>
      </div>
    </div>
  </div>-->



</div>








<!-- <div class="w-full"> -->
  <!--<div *ngIf="!registrationAlert && !exitingReservationAlert && !existingReservationAlert2">
    <p class="text-blue-500 text-2xl mb-6">Reserva, gestiona o anula tu cita</p>
    <div class="bg-gray-50 px-28 py-8 flex flex-col justify-center items-center text-center mx-auto gap-y-2 rounded-2xl shadow-xl max-w-screen-md">
      <p class="text-xl text-gray-600">¿Cuál es tu matrícula?</p>
      <form [formGroup]="step1Form" class="w-full flex flex-col items-center">
        <input formControlName="registration_number" class="outline-none border p-2 px-4 py-2 rounded-full w-full md:w-96 h-14" type="text" placeholder="0000AAA">
        <div *ngIf="step1Form.get('registration_number')?.invalid && step1Form.get('registration_number')?.touched" class="text-red-600">Matrícula no valida</div>
        <div class="flex flex-row items-center text-xs mt-2 gap-x-2">
          <div class="cursor-pointer"><p class="rounded-full border border-blue-500 text-blue-500 w-4 h-4 text-center">?</p></div>
          <p class="text-gray-700">Formatos de matrícula</p>
        </div>
        <button type="button" (click)="update()" class="bg-blue-500 hover:bg-blue-600 duration-300 px-4 py-2 mt-10 shadow-2xl text-lg rounded-xl text-white w-fit">Continuar →</button>
      </form>
    </div>
  </div>-->


  <!--
  <div *ngIf="registrationAlert" class="flex flex-col justify-center">
    <p class="text-xl text-gray-600">¿Cuál es tu matrícula?</p>
    <div class="flex flex-row items-center justify-center text-xs mt-2 gap-x-2">
      <div class="cursor-pointer"><p class="rounded-full border border-blue-500 text-blue-500 w-4 h-4 text-center">?</p></div>
      <p class="text-gray-700">Formatos de matrícula</p>
    </div>
    <div class="bg-gray-50 px-32 py-8 flex flex-col justify-center items-center text-center mx-auto gap-y-2 rounded-2xl shadow-xl mt-4 max-w-screen-md">
      <div class="border border-gray-300 rounded-full py-3 px-10">{{step1Form.get('registration_number')?.value}}</div>
      <p class="text-red-600 italic font-bold text-sm">Tu ITV caduca el 25/05/2024</p>
      <p class="text-red-600 italic text-sm px-4">(Tu última inspección fue en: Almendralejo)</p>
      <div class=" flex flex-col border-t border-gray-300 border-b py-2 gap-y-2">
        <p class="text-sm text-blue-500">Te proponemos: <span class="font-bold">ITV Almendralejo</span></p>
        <p class="text-sm text-blue-500">el <span class="font-bold">24/05/2024</span> a las <span class="font-bold">10:40h</span></p>
        <div class="grid grid-cols-3 bg-blue-500 hover:bg-blue-600 cursor-pointer duration-300 text-left shadow mb-2 text-gray-50 py-2 px-8 rounded-full">
          <p class="col-span-2 text-center">Reservar <span class="font-bold">esta cita</span></p>
          <div class="text-end">→</div>
        </div>
        <div class="grid grid-cols-3 bg-[#F6F5EF] hover:bg-[#e6e5df] cursor-pointer duration-300 shadow text-gray-700 py-2 px-8 rounded-full mb-2">
          <p class="col-span-2 text-center">Seleccionar <span class="font-bold">otra fecha</span></p>
          <div class="text-end">→</div>
        </div>
      </div>
      <div class="grid grid-cols-3 bg-[#F6F5EF] hover:bg-[#e6e5df] cursor-pointer duration-300 shadow text-gray-700 py-2 px-8 rounded-full mt-6 mb-2">
        <p class="col-span-2 text-center">Seleccionar <span class="font-bold">otra centro</span></p>
        <div class="text-end">→</div>
      </div>
    </div>
    <div class="mx-auto py-6">
      <div (click)="returnRegistration()" class="flex flex-row gap-x-4 p-3 text-blue-500 bg-blue-100 hover:bg-blue-200 duration-300 cursor-pointer w-fit rounded-full">
        <p>←</p>
        <p>Introducir otra matrícula</p>
      </div>
    </div>

  </div>-->

  <!--<div *ngIf="existingReservation" class="flex flex-col justify-center">
    <p class="text-xl text-gray-600">¿Cuál es tu matrícula?</p>
    <div class="flex flex-row items-center justify-center text-xs mt-2 gap-x-2">
      <div class="cursor-pointer"><p class="rounded-full border border-blue-500 text-blue-500 w-4 h-4 text-center">?</p></div>
      <p class="text-gray-700">Formatos de matrícula</p>
    </div>
    <div class="bg-gray-50 px-10 py-8 flex flex-col justify-center items-center text-center mx-auto gap-y-2 rounded-2xl shadow-xl mt-4 max-w-screen-md">
      <div class="border border-gray-300 rounded-full py-3 px-10">{{step1Form.get('registration_number')?.value}}</div>
      <div class="border-b border-gray-300 pb-4">
        <p class="text-red-600 text-sm">Ya tienes una cita en <span class="font-bold">{{existingReservation.station.name}}</span> el <span class="font-bold">{{existingReservation.date|date:'dd/MM/yyyy'}}</span> a las <span class="font-bold">{{existingReservation.quadrant_line_hour.hour}}</span></p>
        <p class="text-red-600 text-sm">En <span class="font-bold">{{existingReservation.service.name}}/ {{existingReservation.vehicle.name}}</span></p>
      </div>
      <p class="mt-4 text-gray-700 text-center text-sm">Puedes recuperar la información, modificar o anular tu cita</p>
      <input type="text" class="rounded-full bg-gray-200 w-full px-4 py-2" placeholder="Móvil*">
      <div class="flex flex-row justify-center items-center bg-[#FDDC03] hover:bg-yellow-400 cursor-pointer gap-x-2 px-6 py-4 rounded-full duration-300">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.00" stroke-linecap="round" stroke-linejoin="round" class="text-gray-700 w-5">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z" />
          <path d="M16 3v4" />
          <path d="M8 3v4" />
          <path d="M4 11h16" />
          <path d="M7 14h.013" />
          <path d="M10.01 14h.005" />
          <path d="M13.01 14h.005" />
          <path d="M16.015 14h.005" />
          <path d="M13.015 17h.005" />
          <path d="M7.01 17h.005" />
          <path d="M10.01 17h.005" />
        </svg>
        <p>Gestionar cita</p>
      </div>
    </div>
    <div class="mx-auto py-6">
      <div (click)="returnRegistration()" class="flex flex-row gap-x-4 p-3 text-blue-500 bg-blue-100 hover:bg-blue-200 duration-300 cursor-pointer w-fit rounded-full">
        <p>←</p>
        <p>Introducir otra matrícula</p>
      </div>
    </div>

  </div>

  <div *ngIf="existingReservationAlert2" class="flex flex-col justify-center">
    <p class="text-xl text-gray-600">¿Cuál es tu matrícula?</p>
    <div class="flex flex-row items-center justify-center text-xs mt-2 gap-x-2">
      <div class="cursor-pointer"><p class="rounded-full border border-blue-500 text-blue-500 w-4 h-4 text-center">?</p></div>
      <p class="text-gray-700">Formatos de matrícula</p>
    </div>
    <div class="bg-gray-50 px-10 py-8 flex flex-col justify-center items-center text-center mx-auto gap-y-2 rounded-2xl shadow-xl mt-4 max-w-screen-md">
      <div class="border border-gray-300 rounded-full py-3 px-10">{{step1Form.get('registration_number')?.value}}</div>
      <div class=" pb-4">
        <p class="text-red-600 text-sm">Ya tienes una cita en <span class="font-bold">Almendralejo</span> el <span class="font-bold">25/05/2021</span> a las <span class="font-bold">15:30</span></p>
        <p class="text-red-600 text-sm">En <span class="font-bold">Periódica obligatoria/ Ligeros /Motocicletas</span></p>
      </div>
      <div class=" flex flex-col border-b border-gray-300 border-t py-4 gap-y-4">
        <div class="flex flex-row gap-x-4">
          <div class="text-center cursor-pointer text-sm rounded-full px-6 py-4 border border-blue-500 text-blue-500 hover:bg-blue-200 duration-300">Descargar justificante</div>
          <div class="text-center cursor-pointer text-sm rounded-full px-6 py-4 border border-blue-500 text-blue-500 hover:bg-blue-200 duration-300">Enviar datos por SMS</div>
        </div>
        <input type="text" class="rounded-full border w-full px-6 py-4 border-blue-500 outline-blue-500" placeholder="Email">
        <div class="text-center cursor-pointer text-sm rounded-full px-6 py-4 border border-blue-500 text-blue-500 hover:bg-blue-200 duration-300">Enviar justificante por email</div>
      </div>
      <div class="flex flex-row items-center justify-center gap-x-4 mt-2">
        <div class="flex flex-row justify-center items-center bg-[#FDDC03] hover:bg-yellow-400 cursor-pointer gap-x-2 px-6 py-4 rounded-full duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.00" stroke-linecap="round" stroke-linejoin="round" class="text-gray-700 w-5">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z" />
            <path d="M16 3v4" />
            <path d="M8 3v4" />
            <path d="M4 11h16" />
            <path d="M7 14h.013" />
            <path d="M10.01 14h.005" />
            <path d="M13.01 14h.005" />
            <path d="M16.015 14h.005" />
            <path d="M13.015 17h.005" />
            <path d="M7.01 17h.005" />
            <path d="M10.01 17h.005" />
          </svg>
          <p>Gestionar cita</p>
        </div>
        <div class="text-center items-center cursor-pointer text-sm rounded-full px-6 py-4 border border-red-500 text-red-500 hover:bg-red-100 duration-300">ANULAR CITA</div>
      </div>

    </div>
    <div class="mx-auto py-6">
      <div (click)="returnRegistration()" class="flex flex-row gap-x-4 p-3 text-blue-500 bg-blue-100 hover:bg-blue-200 duration-300 cursor-pointer w-fit rounded-full">
        <p>←</p>
        <p>Introducir otra matrícula</p>
      </div>
    </div>

  </div>-->

<!-- </div> -->


