import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-safe-zone',
  standalone: true,
  imports: [],
  templateUrl: './dashboard-safe-zone.component.html',
})
export class DashboardSafeZoneComponent {

}
