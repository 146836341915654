import { Directive } from '@angular/core';

@Directive({
  selector: '[appNextSquare]',
  host: {
    'class': 'bg-primary disabled:bg-darkGray justify-center text-white shadow-sm hover:shadow-xl py-2 px-8 rounded-xl text-md font-light flex items-center gap-4 duration-300'
  },
  standalone: true
})
export class NextSquareButtonDirective {

  constructor() { }

}
