import { Directive } from '@angular/core';

@Directive({
  selector: '[appFormSelectSquare]',
  host: {
    'class': 'h-fit text-darkGray text-sm border-2 border-lightGray2 rounded-xl'
  },
  standalone: true
})
export class FormSelectSquareDirective {

  constructor() { }

}
