import { Directive } from '@angular/core';

@Directive({
  selector: '[appOutlineButton]',
  host: {
    'class': 'border duration-300 border-darkPrimary rounded-full hover:bg-darkPrimary disabled:bg-darkPrimary disabled:text-lightGray2 hover:text-white px-4 py-4 text-darkPrimary flex justify-center text-sm font-light'
  },
  standalone: true
})
export class OutlineButtonDirective {

  constructor() { }

}
