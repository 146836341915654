import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { StationForm, StationFormStep1, StationFormStep2 } from "../../../models/StationForm/StationForm";
import {Step2Quadrants} from "../../../models/StationForm/Step2Quadrants";

@Injectable({
  providedIn: 'root'
})
export class StationFormService {
  private stationFormSubject: BehaviorSubject<StationForm>;
  public stationForm$: Observable<StationForm>;

  constructor() {
    const savedForm = null;//localStorage.getItem('stationForm');
    const initialForm = savedForm ? JSON.parse(savedForm) : { currentStep: 1, step1: undefined, step2: undefined };
    this.stationFormSubject = new BehaviorSubject<StationForm>(initialForm);
    this.stationForm$ = this.stationFormSubject.asObservable();
  }

  public get currentStationFormValue(): StationForm {
    return this.stationFormSubject.value;
  }

  private updateLocalStorage(form: StationForm): void {
    // localStorage.setItem('stationForm', JSON.stringify(form));
  }

  updateQuadrant(quadrant: Step2Quadrants): void {
    let value = this.currentStationFormValue;

    let index = value.step2?.quadrants.findIndex(value1 => value1.id === quadrant.id);
    if (index && index >= 0) {
      value.step2!.quadrants[index] = quadrant;
    }
    this.stationFormSubject.next(value);
    this.updateLocalStorage(value);
  }

  public setStep1(step1: StationFormStep1): void {
    let form = this.currentStationFormValue;
    form.step1 = step1;
    form.currentStep = 2;
    this.stationFormSubject.next(form);
    this.updateLocalStorage(form);
  }

  public setStep2(step2: StationFormStep2): void {
    let form = this.currentStationFormValue;
    form.step2 = step2;
    this.stationFormSubject.next(form);
    this.updateLocalStorage(form);
  }

  public changeToStep(step: number): void {
    let form = this.currentStationFormValue;
    form.currentStep = step;
    this.stationFormSubject.next(form);
    this.updateLocalStorage(form);
  }

  reset(){
    this.stationFormSubject.next({ currentStep: 1, step1: undefined, step2: undefined });
    let form = this.currentStationFormValue;
    this.updateLocalStorage(form);
  }

}
