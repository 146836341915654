import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Quadrant} from "../../../models/Quadrant";
import {NgForOf, NgIf} from "@angular/common";
import {ConfirmationService} from "primeng/api";
import {QuadrantService} from "../../../core/services/quadrants/quadrant.service";
import {Fuel} from "../../../models/Fuel";
import {Service} from "../../../models/Service";
import {Vehicle} from "../../../models/Vehicle";
import {ToastrService} from "ngx-toastr";
import {Button} from "primeng/button";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {FormErrorDirective} from "../../directives/forms/form-error.directive";
import {FormLabelDirective} from "../../directives/forms/form-label.directive";
import {LoaderComponent} from "../loader/loader.component";
import {StationFormService} from "../../../core/services/stations/station-form.service";
import {FuelService} from "../../../core/services/fuels/fuel.service";
import {VehiclesService} from "../../../core/services/vehicles/vehicles.service";
import {ServicesService} from "../../../core/services/services/services.service";
import {forkJoin} from "rxjs";
import {NextButtonDirective} from "../../directives/buttons/next.directive";

@Component({
  selector: 'app-quadrants-station',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    Button,
    OverlayPanelModule,
    FormErrorDirective,
    FormLabelDirective,
    FormsModule,
    LoaderComponent,
    ReactiveFormsModule,
    NextButtonDirective
  ],
  providers: [
    ConfirmationService
  ],
  templateUrl: './quadrants-station.component.html',
  styleUrl: './quadrants-station.component.css'
})
export class QuadrantsStationComponent {
  @Input() quadrant!:Quadrant;
  @Input() showEdit: boolean = false;
  @Output() onEditClick: EventEmitter<Quadrant> = new EventEmitter();
  @Output() onUpdateQuadrant: EventEmitter<Quadrant> = new EventEmitter();

  services: Service[] = [];
  servicesToAdd: Service[] = [];
  vehicles: Vehicle[] = [];
  vehiclesToAdd: Vehicle[] = [];
  fuels: Fuel[] = [];
  fuelsToAdd: Fuel[] = [];

constructor(private confirmService:ConfirmationService, private quadrantService:QuadrantService,
            private toastr:ToastrService, private fb:FormBuilder,
            public stationFormService: StationFormService, private fuelService: FuelService,
            private vehiclesService: VehiclesService, private serviceService: ServicesService, ) {

  this.loadElements();
}

  // Método para verificar si un fuel está dentro de los fuels del quadrant
  isFuelInArray(fuel: Fuel, fuels:Fuel[]): boolean {
    // Busca si el fuel existe en el arreglo de fuels del quadrant
    return fuels.some(f => f.id === fuel.id);
  }

  addService(service: Service) {
    // Verifica si el servicio ya está en el array servicesToAdd
    const index = this.servicesToAdd.findIndex(s => s.id === service.id);

    if (index !== -1) {
      // Si el servicio ya está, lo elimina del array
      this.servicesToAdd.splice(index, 1);
    } else {
      // Si el servicio no está, lo añade al array
      this.servicesToAdd.push(service);
    }
  }

  saveServices(){
  let data = {
    services: this.servicesToAdd
  }
  this.quadrantService.updateServices(this.quadrant.id,data).subscribe({
    next:(response) => {
      if(response.success){
        if(response.data){
          this.quadrant = response.data;
          this.onUpdateQuadrant.emit(this.quadrant);
          this.servicesToAdd = [];
        }
      }
    },
    error:(err) => {
      this.toastr.error(err.error.message);
    }
  })
  }

  saveVehicles(){
    let data = {
      vehicles: this.vehiclesToAdd
    }
    this.quadrantService.updateVehicles(this.quadrant.id,data).subscribe({
      next:(response) => {
        if(response.success){
          if(response.data){
            this.quadrant = response.data;
            this.onUpdateQuadrant.emit(this.quadrant);
            this.vehiclesToAdd = [];
          }
        }
      },
      error:(err) => {
        this.toastr.error(err.error.message);
      }
    })
  }

  saveFuels(){
    let data = {
      fuels: this.fuelsToAdd
    }
    this.quadrantService.updateFuels(this.quadrant.id,data).subscribe({
      next:(response) => {
        if(response.success){
          if(response.data){
            this.quadrant = response.data;
            this.onUpdateQuadrant.emit(this.quadrant);
            this.fuelsToAdd = [];
          }
        }
      },
      error:(err) => {
        this.toastr.error(err.error.message);
      }
    })
  }

  addVehicle(vehicle: Vehicle) {
    // Verifica si el vehicle ya está en el array vehiclesToAdd
    const index = this.vehiclesToAdd.findIndex(v => v.id === vehicle.id);

    if (index !== -1) {
      // Si el vehicle ya está, lo elimina del array
      this.vehiclesToAdd.splice(index, 1);
    } else {
      // Si el vehicle no está, lo añade al array
      this.vehiclesToAdd.push(vehicle);
    }
  }

  addFuel(fuel: Fuel) {
    // Verifica si el fuel ya está en el array fuelsToAdd
    const index = this.fuelsToAdd.findIndex(f => f.id === fuel.id);

    if (index !== -1) {
      // Si el fuel ya está, lo elimina del array
      this.fuelsToAdd.splice(index, 1);
    } else {
      // Si el fuel no está, lo añade al array
      this.fuelsToAdd.push(fuel);
    }
  }

  isVehicleInArray(vehicle: Vehicle, vehicles:Vehicle[]): boolean {
    // Busca si el fuel existe en el arreglo de fuels del quadrant
    return vehicles.some(v => v.id === vehicle.id);
  }

  isServiceInArray(service: Service, services:Service[]): boolean {
    // Busca si el fuel existe en el arreglo de fuels del quadrant
    return services.some(s => s.id === service.id);
  }

  loadElements(){
    //this.loading = true;
    forkJoin({
      services: this.serviceService.list(),
      vehicles: this.vehiclesService.list(),
      fuels: this.fuelService.list()
    }).subscribe({
      next: (results) => {
        this.services = results.services.data ?? [];
        this.vehicles = results.vehicles.data ?? [];
        this.fuels = results.fuels.data ?? [];

        //this.loading = false;
      },
      error: (error) => {
        //this.loading = false;
        // Manejar errores aquí
        console.error('Error al llamar a los observables:', error);
      }
    });
  }


  changeVehicleVisibility(quadrant:Quadrant,vehicle:Vehicle){
    this.quadrantService.updateVehicleVisibility(quadrant.id,vehicle.id).subscribe({
      next:(response) => {
        if(response.success){
          if(vehicle.pivot){
            vehicle.pivot.visible = !vehicle.pivot.visible;
          }
        }
      },
      error: (err) => {
        this.toastr.error(err.error.message)
      }
      }
    )
  }

  changeServiceVisibility(quadrant:Quadrant,service:Service){
    this.quadrantService.updateServiceVisibility(quadrant.id,service.id).subscribe({
        next:(response) => {
          if(response.success){
            if(service.pivot){
              service.pivot.visible = !service.pivot.visible;
            }
          }
        },
        error: (err) => {
          this.toastr.error(err.error.message)
        }
      }
    )
  }

  changeFuelVisibility(quadrant:Quadrant,fuel:Fuel){
    this.quadrantService.updateFuelVisibility(quadrant.id,fuel.id).subscribe({
        next:(response) => {
          if(response.success){
            if(fuel.pivot){
              fuel.pivot.visible = !fuel.pivot.visible;
            }
          }
        },
        error: (err) => {
          this.toastr.error(err.error.message)
        }
      }
    )
  }




/*  removeQuadrant(pos: number){
    if(this.quadrants){
      this.quadrants.splice(pos,1);
      this.stationFormService.setStep2({
        quadrants: this.toCreateQuadrants
      })
    }
  }*/
}
