import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Paginator} from "../../../models/Paginator";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {PaginatorService} from "../../../core/services/paginator/paginator.service";
import {NgxSpinnerService} from "ngx-spinner";
import {SortColumn} from "../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";
import {LoaderComponent} from "../loader/loader.component";


@Component({
  selector: 'app-paginator',
  standalone: true,
  imports: [
    JsonPipe,
    NgIf,
    NgForOf,
    LoaderComponent
  ],
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.css'
})
export class PaginatorComponent {
  @Input() paginator!: Paginator<any>;
  @Input() sorts: SortColumn[] = [];
  @Input() filters: ActiveFilter[] = [];
  protected readonly Math = Math;
  @Output() onChangePage = new EventEmitter<Paginator<any>>();
  loading: boolean = false;

  constructor(private paginatorService: PaginatorService, private spinner: NgxSpinnerService) { }

  loadPage(url: string|undefined){
      if (url) {
        this.loading = true;
        this.paginatorService.paginate(url, this.sorts, this.filters).subscribe({
          next: (data) => {
            if (data.data) {
              this.paginator = data.data;
              this.onChangePage.emit(this.paginator);
            }
            this.loading = false;
          },
          error: (error) => {
            console.log(error);
            this.loading = false;
          },
        })
      }

  }

  getPages(): number[] {
    const totalPagesToShow = 7;
    const pages: number[] = [];
    if (this.paginator.last_page <= totalPagesToShow) {
      for (let i = 1; i <= this.paginator.last_page; i++) {
        pages.push(i);
      }
    } else {
      if (this.paginator.current_page <= 4) {
        for (let i = 1; i <= 5; i++) {
          pages.push(i);
        }
        pages.push(-1); // Elipsis
        pages.push(this.paginator.last_page);
      } else if (this.paginator.current_page >= this.paginator.last_page - 3) {
        pages.push(1);
        pages.push(-1); // Elipsis
        for (let i = this.paginator.last_page - 4; i <= this.paginator.last_page; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        pages.push(-1); // Elipsis
        for (let i = this.paginator.current_page - 1; i <= this.paginator.current_page + 1; i++) {
          pages.push(i);
        }
        pages.push(-1); // Elipsis
        pages.push(this.paginator.last_page);
      }
    }
    return pages;
  }

}
