import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {StepperComponent} from "../../components/stepper/stepper.component";
import {FormComponent} from "../../components/form/form.component";
import {DatePipe, NgIf} from "@angular/common";
import {SummaryComponent} from "../../components/summary/summary.component";
import {Observable} from "rxjs";
import {Step1, Step2, Step3, Step4, Step5, Step6} from "../../../../models/Front/ReservationForm";
import {select, Store} from "@ngrx/store";
import {
  selectConfirmed,
  selectCurrentStep,
  selectShowSummary,
  selectStep1,
  selectStep2, selectStep3, selectStep4, selectStep5, selectStep6
} from "../../../../store/reservationForm/stepper.selector";
import {NextButtonDirective} from "../../../../shared/directives/buttons/next.directive";
import {
  updateConfirmed,
  updateStep,
  updateStep1,
  updateStep2,
  updateStep3,
  updateStep4,
  updateStep5, updateStep6
} from "../../../../store/reservationForm/stepper.actions";
import {FrontService} from "../../../../core/services/front/front.service";
import {Reservation} from "../../../../models/Reservation";
import {ActivatedRoute, Router} from "@angular/router";
import {NextSquareButtonDirective} from "../../../../shared/directives/buttons/next-square.directive";
import {OutlineButtonDirective} from "../../../../shared/directives/outline/outline-button.directive";
import {ReservationGesService} from "../../../../core/services/reservations/reservation-ges.service";
import {ToastrService} from "ngx-toastr";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {FormsModule} from "@angular/forms";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-confirm-page',
  standalone: true,
  imports: [
    StepperComponent,
    FormComponent,
    NgIf,
    SummaryComponent,
    DatePipe,
    NextButtonDirective,
    NextSquareButtonDirective,
    OutlineButtonDirective,
    LoaderComponent,
    FormsModule
  ],
  templateUrl: './confirm-page.component.html',
  styleUrl: './confirm-page.component.css'
})
export class ConfirmPageComponent implements OnInit{
  reservationId:string | null='';
  currentStep: number = 7;
  existingReservation?: Reservation;
  private error: any;

  loadingEmail = false;
  loadingSms = false;
  email: string = "";
  constructor(private store: Store, private cd:ChangeDetectorRef, private frontService:FrontService, private route:ActivatedRoute,private router:Router,
              private reservationGesService: ReservationGesService, private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.reservationId = params.get('id');
      if(this.reservationId){
        this.frontService.getReservation(this.reservationId).subscribe(
          {
            next: (response) => {
              if(response.data){
                this.existingReservation=response.data;
                this.email = this.existingReservation.email;
              }
              //this.loading = false;
            },
            error: error => {
              this.error = error.error.message;
              console.error(error.error.message);
              //this.loading = false;
            }
          }
        );
      }
    });
  }

  sendMessage(){
    if(this.existingReservation){
      this.loadingSms=true;
      const formattedDate = new Date(this.existingReservation?.date).toLocaleDateString('es-ES', {
        weekday: 'long', // Nombre del día de la semana
        day: 'numeric',  // Día del mes
        month: 'long',   // Nombre completo del mes
        year: 'numeric'  // Año con cuatro dígitos
      });
      let data = {
        phone:this.existingReservation?.phone,
        message:this.existingReservation?.registration_number+' tiene cita: '+ formattedDate +' a las '+ this.existingReservation.hour.hour +' en ' + this.existingReservation.station.name +'. Gracias por confiar en Itevebasa.'
      }
      this.reservationGesService.sendMessage(data).subscribe({
        next:(response)=>{
          if(response.success){
            //console.log(response.data)
          }
          this.loadingSms=false;
        },

        error:(err)=>{
          //console.log(err.error.message);
          this.loadingSms=false;
        }
      });
    }
  }

  public resetStep2(){

    let step2: Step2 = {
      subStep: 1,
    };
    this.store.dispatch(updateStep2({step2}));

  }

  public resetStep3(){
    let step3: Step3 = {
      subStep: 1,
    };
    this.store.dispatch(updateStep3({step3}));
  }

  public resetStep4(){
    let step4:Step4={
      date:undefined,
      hour:undefined,
    }
    this.store.dispatch(updateStep4({
      step4
    }))
  }

  public resetStep5(){
    let step5:Step5={
      phone_number: '',
      email: '',
      name: '',
    }
    this.store.dispatch(updateStep5({
      step5
    }))
  }

  public resetStep6(){
    let step6:Step6={
      payment:undefined,
    }
    this.store.dispatch(updateStep6({step6}))
  }

  public resetWithoutRegistrtion(){
    this.resetStep2();
    this.resetStep3();
    this.resetStep4();
    this.resetStep5();
    this.resetStep6();
    let step = 1;
    this.store.dispatch(updateStep({step}));
    let confirmed = false;
    this.store.dispatch(updateConfirmed({confirmed}));
    this.router.navigate(['']);
  }

  sendProofToEmail(){
    this.loadingEmail = true;
    this.reservationGesService.sendProofToEmail(this.existingReservation!.id, this.existingReservation!.email,  this.existingReservation!.phone).subscribe({
      next: (result) => {
        if (result.success) {
          this.toastrService.success(result.message);
        }
        this.loadingEmail = false;
      },
      error: (error) => {
        this.toastrService.error(error.error.message);
        this.loadingEmail = false;
      }
    })
  }

  protected readonly environment = environment;
}
