<div class="w-full">
  <div class="flex flex-col gap-y-8">

    <div class="w-full">
      <p class="text-2xl text-darkGray mb-2">FESTIVOS / BLOQUEO GENERAL</p>
      <div class="grid grid-cols-4 gap-8">
        <app-blocked-dates  *ngFor="let section of nationalLines" [quadrantLinesBlocked]="section"></app-blocked-dates>
        <app-community-blocked-form #blockedForm (onSubmitSend)="saveBlockedDates($event)"></app-community-blocked-form>
      </div>
    </div>
    <!--<button appNextOutlined class="w-fit">Seleccionar festivos nacionales</button>-->

    <p class="text-2xl text-darkGray mb-2">FESTIVOS / BLOQUEOS COMUNIDAD</p>

    <div class="grid grid-cols-2 gap-8">
      <app-community-card *ngFor="let state of states" [state]="state"></app-community-card>
    </div>


  </div>

</div>

