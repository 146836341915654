<!--
<ngx-loading-bar color="#02C65C" height="6px" ></ngx-loading-bar>
-->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#ffe000" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner>
<router-outlet></router-outlet>
<!--
<div
  class="absolute bottom-5 right-5 hidden rounded-md bg-primary py-1 px-3 align-middle text-sm font-medium text-white sm:block">
  <span class="hidden sm:block md:hidden">sm</span>
  <span class="hidden md:block lg:hidden">md</span>
  <span class="hidden lg:block xl:hidden">lg</span>
  <span class="hidden xl:block 2xl:hidden">xl</span>
  <span class="hidden 2xl:block">2xl</span>
</div>
-->

