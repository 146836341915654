import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../../../models/User";
import {Breadcrumb} from "../../../models/Breadcrumb";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbSubject: BehaviorSubject<Breadcrumb | null>;
  public breadcrumb: Observable<Breadcrumb | null>;

  constructor() {
    this.breadcrumbSubject = new BehaviorSubject<Breadcrumb | null>(null);
    this.breadcrumb = this.breadcrumbSubject.asObservable();
  }

  public get currentBreadcrumbValue(): Breadcrumb | null {
    return this.breadcrumbSubject.value;
  }

  public setBreadcrumb(breadcrumb: Breadcrumb | null): void {
    this.breadcrumbSubject.next(breadcrumb);
  }
}
