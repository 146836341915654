import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {FormErrorDirective} from "../../../../../../../../shared/directives/forms/form-error.directive";
import {LoaderComponent} from "../../../../../../../../shared/components/loader/loader.component";
import {NextOutlinedButtonDirective} from "../../../../../../../../shared/directives/buttons/next-outlined.directive";
import {NgForOf, NgIf} from "@angular/common";
import {OldReservation, Reservation} from "../../../../../../../../models/Reservation";
import {ReservationForm, Step1} from "../../../../../../../../models/Front/ReservationForm";
import {updateStep1} from "../../../../../../../../store/reservationForm/stepper.actions";
import {FrontService} from "../../../../../../../../core/services/front/front.service";
import {BookFormService} from "../../../../../../../../core/services/book-form.service";
import {
  ExistingReservationCardComponent
} from "../../../../../../../front/components/existing-reservation-card/existing-reservation-card.component";
import {NextButtonDirective} from "../../../../../../../../shared/directives/buttons/next.directive";
import {OldReservationComponent} from "../../../../../../../front/components/old-reservation/old-reservation.component";
import {UserService} from "../../../../../../../../core/services/users/user.service";
import {User} from "../../../../../../../../models/User";
import {UserV2} from "../../../../../../../../models/UserV2";
import {
  FormInputBlueNoFullDirective
} from "../../../../../../../../shared/directives/forms/form-input-blue-no-full.directive";
import {Vehicle} from "../../../../../../../../models/Vehicle";

@Component({
  selector: 'app-step1',
  standalone: true,
  imports: [
    FormErrorDirective,
    FormsModule,
    LoaderComponent,
    NextOutlinedButtonDirective,
    NgIf,
    ReactiveFormsModule,
    ExistingReservationCardComponent,
    NextButtonDirective,
    OldReservationComponent,
    FormInputBlueNoFullDirective,
    NgForOf
  ],
  templateUrl: './step1.component.html',
  styleUrl: './step1.component.css'
})
export class Step1Component {
  loading: boolean = false;
  error: string|null = null;

  existingReservation?: Reservation;
  oldReservation?: OldReservation;

  editReservation:boolean = false;

  step1Form: FormGroup;

  professional: boolean = false;

  currentStep: number = 1;

  users: UserV2[] = [];
  selectedUser?:UserV2 ;

  constructor(private frontService: FrontService, private bookFormService: BookFormService, private userService: UserService) {
    this.step1Form = new FormGroup({
      registration_number: new FormControl(null, [Validators.required])
    });
    this.bookFormService.reservationForm$.subscribe({
      next: (form: ReservationForm) => {
        this.currentStep = form.step;
        if (this.professional != form.professional) {
          this.professional = form.professional;
          if(this.professional) {
            this.loadProfessionals();
          }
        }


      }
    })


  }

  loadProfessionals(){
    this.loading = true;
    this.userService.listProfessionals().subscribe({
      next: (response) => {
        if(response.data) {
          this.users = response.data;
          this.loading = false;
        }
      },
      error: err => {
        this.loading = false;
      }
    })
  }

  onAddRegistrationNumber() {
    this.error = null;
    if(this.step1Form.valid){
      let data:any = {
        registration_number: this.step1Form.get('registration_number')?.value,
      }
      this.bookFormService.reset(this.step1Form.value);
      this.frontService.checkReservation(data).subscribe(
        {
          next: (response) => {
            this.loading=true;
            //this.resetWithoutRegistration()

            if (response.data) {
              // Verificar si es una OldReservation por la existencia de 'expiration' y ausencia de 'id'
              if ('expiration' in response.data && !('id' in response.data)) {
                this.oldReservation = response.data as OldReservation;
              }

              // Verificar si es una Reservation por la existencia de las propiedades clave como 'id'
              if ('id' in response.data) {
                this.existingReservation = response.data as Reservation;
              }

            } else {
              this.next();
            }

            this.loading = false;
          },
          error: error => {
            this.error = error.error.message;
            this.loading = false;
          }
        }
      );
    }
  }

  next(){
    const step1: Step1 = this.step1Form.value;
    this.bookFormService.setStep1(step1);
    this.existingReservation=undefined;
    this.oldReservation=undefined;
    if(this.professional && this.selectedUser) {
      this.bookFormService.setProfessionalId(this.selectedUser.id);
    }
  }

  public returnRegistration(){
    this.existingReservation=undefined;
    this.oldReservation=undefined;
  }

  clearOldReservation(key:string){
    // this.loadServices();
    this.oldReservation=undefined;
  }

  updateRegistrationNumber(){
    const step1: Step1 = this.step1Form.value;
    if (step1.registration_number) {
      this.loading=true;
      this.bookFormService.reset(this.step1Form.value);
      this.frontService.checkReservation(step1).subscribe(
        {
          next: (response) => {

            //this.resetWithoutRegistration()

            if (response.data) {
              // Verificar si es una OldReservation por la existencia de 'expiration' y ausencia de 'id'
              if ('expiration' in response.data && !('id' in response.data)) {
                this.oldReservation = response.data as OldReservation;
              }

              // Verificar si es una Reservation por la existencia de las propiedades clave como 'id'
              if ('id' in response.data) {
                this.existingReservation = response.data as Reservation;
              }

            } else {
              // @ts-ignore
              this.bookFormService.updateRegistrationNumber(step1.registration_number);
            }

            this.loading = false;
          },
          error: error => {
            this.error = error.error.message;
            this.loading = false;
          }
        }
      );

    }
  }

  onSelectUser(){
    if (this.selectedUser) {
      this.step1Form.patchValue({
        'registration_number': this.selectedUser.name
      })
      this.bookFormService.updateStep5({
        name: this.selectedUser.name,
        phone_number: this.selectedUser.phone,
        email: this.selectedUser.email
      })
      this.next();
    }
  }


}
