import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbService} from "../../../../../../core/services/breadcrumb/breadcrumb.service";
import {StationService} from "../../../../../../core/services/stations/station.service";
import {StationFormService} from "../../../../../../core/services/stations/station-form.service";
import {ActivatedRoute} from "@angular/router";
import {QuadrantLine} from "../../../../../../models/QuadrantLine";
import {BlockedDatesComponent} from "../../../../../../shared/components/blocked-dates/blocked-dates.component";
import {NgForOf} from "@angular/common";
import {
  CommunityBlockedFormComponent
} from "../../../comunity/components/community-blocked-form/community-blocked-form.component";
import {Station} from "../../../../../../models/Station";

@Component({
  selector: 'app-stations-blocked',
  standalone: true,
  imports: [
    BlockedDatesComponent,
    NgForOf,
    CommunityBlockedFormComponent
  ],
  templateUrl: './stations-blocked.component.html',
  styleUrl: './stations-blocked.component.css'
})
export class StationsBlockedComponent implements OnInit {
  @Input() station!: Station;

  quadrantLines: QuadrantLine[] = [];

  @ViewChild('blockedForm') blockedForm!: CommunityBlockedFormComponent;

  constructor(private breadcrumbService: BreadcrumbService,
              private route: ActivatedRoute, private stattionService: StationService) {
  }

  ngOnInit() {
    this.loadLines();
  }

  loadLines(){
    this.stattionService.stationsBlocked(this.station.id).subscribe({
      next: (data) => {
        if (data.data) {
          this.quadrantLines = data.data;
        }
      },
      error: err => {
        console.log(err);
      }
    })
  }

  onCreate(data: any){
    this.stattionService.addQuadrantReduction(this.station.id, data).subscribe({
      next: (data) => {
        if (data.data) {
          this.quadrantLines.push(data.data);
          this.blockedForm.reset();
        }
      },
      error: err => {
        console.log(err);
      }
    })
  }
}
