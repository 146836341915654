import { Directive } from '@angular/core';

@Directive({
  selector: '[appFormInputNoFull]',
  host: {
    'class': 'bg-lightGray2 border border-lightGray2 text-darkGray rounded-3xl focus:ring-primary focus:border-primary block p-2.5 pr-8'
  },
  standalone: true
})
export class FormInputNoFullDirective {

  constructor() { }

}
