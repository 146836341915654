import {Component, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {DashboardLayoutComponent} from "./layouts/dashboard-layout/dashboard-layout.component";
import {LoginComponent} from "./pages/auth/login/login.component";
import {initFlowbite} from "flowbite";
import {NgxSpinnerComponent} from "ngx-spinner";
import {registerLocaleData} from "@angular/common";
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, DashboardLayoutComponent, LoginComponent, NgxSpinnerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'web_itv';
  ngOnInit() {
    initFlowbite();
  }
}
