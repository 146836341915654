import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customMonth',
  standalone: true
})
export class CustomMonthPipe implements PipeTransform {

  private months: string[] = ['Ene', 'Feb', 'May', 'Abr', 'Mar', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

  transform(value: Date | string | number): string {
    let date: Date;
    if (value instanceof Date) {
      date = value;
    } else if (typeof value === 'string' || typeof value === 'number') {
      date = new Date(value);
    } else {
      return '';
    }

    const monthIndex = date.getMonth();
    return this.months[monthIndex];
  }

}
