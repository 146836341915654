import {Component, OnInit} from '@angular/core';
import {DatePipe, NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {Observable} from "rxjs";
import {Step2, Step3} from "../../../../../models/Front/ReservationForm" ;
import {Store} from "@ngrx/store";
import {selectStep2, selectStep3} from "../../../../../store/reservationForm/stepper.selector";
import {updateStep, updateStep2, updateStep3} from "../../../../../store/reservationForm/stepper.actions";
import {State} from "../../../../../models/State";
import {Station} from "../../../../../models/Station";
import {FrontService} from "../../../../../core/services/front/front.service";
import {CardShadowComponent} from "../../utils/card-shadow/card-shadow.component";
import {CardSelectorComponent} from "../../utils/card-selector/card-selector.component";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";

@Component({
  selector: 'app-step3',
  standalone: true,
  imports: [
    NgIf,
    CardShadowComponent,
    NgForOf,
    CardSelectorComponent,
    DatePipe,
    UpperCasePipe,
    FrontBackDirective
  ],
  templateUrl: './step3.component.html',
  styleUrl: './step3.component.css'
})
export class Step3Component implements OnInit{
  step2$: Observable<Step2 | undefined>;
  step2?: Step2;
  step3?: Step3;
  step3$: Observable<Step3|undefined>;
  states?: State[];
  stations?: Station[];
  stateSelected?: State;
  stationSelected?:Station;
  // @Output() stepCompleted = new EventEmitter<void>();
  substep: number = 1;

  loading: boolean = false;

  constructor(private store: Store, private frontService:FrontService) {
    this.step2$ = this.store.select(selectStep2);
    this.step3$ = this.store.select(selectStep3);

    this.step2$.subscribe(step2 => {
      if (step2) {
        this.step2 = step2;
      }
    });
    this.step3$.subscribe(step3 => {
      this.step3 = step3;
      if (step3) {
        this.stateSelected = step3.state;
        this.stationSelected = step3.station;
      }
    });
  }

  ngOnInit() {

    if(this.step2?.service && this.step2.vehicle && this.step2.fuel) {
      this.loading = true;
      this.frontService.listStates(this.step2.service.id,this.step2.vehicle.id,this.step2.fuel.id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.states = response.data;
            }
            this.loading = false;
          },
          error: err => {
            this.loading = false;
          }
        }
      );
    }


    if (this.step3 && this.step3.state) {
      // this.selectState(this.step3.state);
      this.loadStations(this.step3.state);
    }

  }


  public nextSubStep(step: number) {
    this.substep = step;
    // this.update();
  }

  public backSubStep(){
    if (this.substep > 1) {
      this.substep--;
    } else {
      this.store.dispatch(updateStep({ step: 2 }));
    }
  }

  public nextStep() {
    this.store.dispatch(updateStep({ step: 4 }));
  }

  public update() {
    const step3: Step3 = {
      subStep: this.substep,
      state:this.stateSelected,
      station:this.stationSelected,
    };
    this.store.dispatch(updateStep3({step3}));

    if (this.stateSelected && this.stationSelected) {
      // this.stepCompleted.emit();
      this.nextStep();
    }
  }

  public selectState(state:State) {
    this.stateSelected = state;

    this.loadStations(state);

    this.nextSubStep(2);
  }

  public loadStations(state:State){
    this.loading = true;
    if (this.step2) {
      this.frontService.listStations(this.step2.service!.id, this.step2.vehicle!.id, this.step2.fuel!.id, state.id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.stations = response.data;
              // console.log(response.data);
            }
            this.loading = false;
          },
          error: err => {
            // console.log(err);
            this.loading = false;
          }
        }
      );
    }
  }

  public selectStation(station: Station) {
    this.stationSelected = station;
    this.update();
  }
}
