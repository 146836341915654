import {AfterViewChecked, AfterViewInit, Component, OnInit} from '@angular/core';
import {StationFormService} from "../../../../../../../core/services/stations/station-form.service";
import {FormLabelDirective} from "../../../../../../../shared/directives/forms/form-label.directive";
import {FormInputDirective} from "../../../../../../../shared/directives/forms/form-input.directive";
import {LoaderComponent} from "../../../../../../../shared/components/loader/loader.component";
import {FormErrorDirective} from "../../../../../../../shared/directives/forms/form-error.directive";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {State} from "../../../../../../../models/State";
import {StatesService} from "../../../../../../../core/services/states/states.service";
import {environment} from "../../../../../../../../environments/environment";
import {PaymentMethod} from "../../../../../../../models/PaymentMethod";
import {PaymentMethodsService} from "../../../../../../../core/services/payment-methods/payment-methods.service";
import {forkJoin} from "rxjs";
import {MultiSelectModule} from "primeng/multiselect";
import {EditorModule} from "primeng/editor";

@Component({
  selector: 'app-step1-station',
  standalone: true,
  imports: [
    FormLabelDirective,
    FormInputDirective,
    LoaderComponent,
    FormErrorDirective,
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    JsonPipe,
    MultiSelectModule,
    EditorModule,
  ],
  templateUrl: './step1-station.component.html',
  styleUrl: './step1-station.component.css'
})
export class Step1StationComponent {
  form: FormGroup;
  loadingStates: boolean = false;
  states: State[] = [];

  paymentMethods: PaymentMethod[] = [];

  constructor(private stationFormService: StationFormService, private fb: FormBuilder,
              private stateService: StatesService, private paymentMethodsService: PaymentMethodsService) {
    this.form = this.fb.group({
      name: [environment.production ? '' :'Barakaldo', Validators.required],
      code: [environment.production ? '' :'03300', Validators.required],
      state_id: [environment.production ? '' :1, Validators.required],
      maps_link: [environment.production ? '' :'https://maps.app.goo.gl/menTt4HNTFTsvgCn8'],
      start: [],
      end: [],
      information: [environment.production ? '' :'Información que aparecerá en la ficha'],
      limitations: [environment.production ? '' :'Limitaciones de la estación'],
      paymentMethods: new FormControl<number[] | null>([],[ Validators.required, Validators.minLength(1)])//[],//[null, Validators.required, Validators.minLength(1)],
    });
    this.loadData();
    this.refreshStep();
  }

  refreshStep(){
    this.stationFormService.stationForm$.subscribe(station => {
      if (station.step1) {
        this.form.patchValue(station.step1);
      }
    })
  }

  loadData() {
    // Indica que la carga de datos ha comenzado
    this.loadingStates = true;

    // Usa forkJoin para ejecutar ambos observables simultáneamente
    forkJoin({
      states: this.stateService.list(),
      paymentMethods: this.paymentMethodsService.list()
    }).subscribe({
      next: ({ states, paymentMethods }) => {
        if (states.data) {
          this.states = states.data;
        }
        if (paymentMethods.data) {
          this.paymentMethods = paymentMethods.data;
        }
        // Indica que la carga de datos ha terminado
        this.loadingStates = false;
      },
      error: error => {
        // Manejo de errores para ambos observables
        this.loadingStates = false;
      }
    });
  }

  /*loadStates(){
    this.loadingStates = true;
    this.stateService.list().subscribe({
      next: data => {
        if (data.data) {
          this.states = data.data;
        }
        this.loadingStates = false;
      },
      error: error => {
        this.loadingStates = false;
      },
    })
  }

  loadPaymentMethods(){
    this.paymentMethodsService.list().subscribe({
      next: data => {
        if (data.data){
          this.paymentMethods = data.data;
        }
      },
      error: error => {

      }
    })
  }*/


  next(){
    if (this.form.valid) {
      // console.log(this.form.value);
      this.stationFormService.setStep1(this.form.value);
    } else {
      this.form.markAllAsTouched();
    }
  }
}
