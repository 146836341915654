import { Component } from '@angular/core';

@Component({
  selector: 'app-other-stations',
  standalone: true,
  imports: [],
  templateUrl: './other-stations.component.html',
  styleUrl: './other-stations.component.css'
})
export class OtherStationsComponent {

}
