import {Component, EventEmitter, Output} from '@angular/core';
import {BookFormService} from "../../../../../../../../core/services/book-form.service";
import {Step2, Step3} from "../../../../../../../../models/Front/ReservationForm";
import {FrontService} from "../../../../../../../../core/services/front/front.service";
import {State} from "../../../../../../../../models/State";
import {Station} from "../../../../../../../../models/Station";
import {
  FormInputBlueNoFullDirective
} from "../../../../../../../../shared/directives/forms/form-input-blue-no-full.directive";
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {LoaderComponent} from "../../../../../../../../shared/components/loader/loader.component";
import {Service} from "../../../../../../../../models/Service";
import {Vehicle} from "../../../../../../../../models/Vehicle";
import {Fuel} from "../../../../../../../../models/Fuel";

@Component({
  selector: 'app-step3',
  standalone: true,
  imports: [
    FormInputBlueNoFullDirective,
    FormsModule,
    NgForOf,
    NgIf,
    LoaderComponent
  ],
  templateUrl: './step3.component.html',
  styleUrl: './step3.component.css'
})
export class Step3Component {

  step2?: Step2;
  step3?: Step3;

  loading = true;
  loadingStations = false;

  states?:State[];
  selectedState?:State;

  stations?:Station[];
  selectedStation?:Station;


  selectedService?: Service;
  selectedVehicle?:Vehicle ;
  selectedFuel?:Fuel;

  professional:boolean = false;
  professional_id?:number = undefined;

  @Output() onEnd: EventEmitter<{step2: Step2, step3: Step3}> = new EventEmitter<{step2: Step2, step3: Step3}>();

  constructor(private bookFormService: BookFormService, private frontService: FrontService) {
    this.bookFormService.reservationForm$.subscribe({
      next: data => {
        this.professional_id = data.professional_id;
        this.professional = data.professional;

        if (data.steps.step2?.subStep == 4) {
          /*console.log('TERMINADO')*/
          this.step3 = data.steps.step3;
          if (this.step3?.subStep == 0){
            this.step3.subStep = 1;
            this.selectedState = undefined;
            this.selectedStation = undefined;
            this.selectedService = data.steps.step2.service;
            this.selectedVehicle = data.steps.step2.vehicle;
            this.selectedFuel = data.steps.step2.fuel;
            this.loadStates();
          }
          // console.log(data.steps.step3);
          if (!this.step2) {
            this.step2 = data.steps.step2;
            this.selectedService = data.steps.step2.service;
            this.selectedVehicle = data.steps.step2.vehicle;
            this.selectedFuel = data.steps.step2.fuel;
            this.loadStates();
           /* console.log('COMPLETADO PASO 2')*/
          }
        } else {
          /*console.log('EN PROCESO')*/
          this.step2 = undefined;
          this.selectedState = undefined;
          this.selectedStation = undefined;
        }
      }
    })
  }

  loadStates(){
    this.loading = true;
    if (this.selectedService && this.selectedVehicle && this.selectedFuel) {
      this.frontService.listStates(this.selectedService.id,this.selectedVehicle.id,this.selectedFuel.id, this.professional_id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.states = response.data;
            }
            this.loading = false;
          },
          error: err => {
            this.loading = false;
          }
        }
      );
    }
  }

  onSelectState(){
    if (this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState) {
      this.selectedStation = undefined;
      this.loadStations(this.selectedService, this.selectedVehicle, this.selectedFuel, this.selectedState);

      const step3: Step3 = {
        subStep: 2,
        state: this.selectedState,
        station: undefined
      };
      if (this.step2) {
        this.onEnd.emit({step2: this.step2, step3: step3});
      }
      this.bookFormService.updateStep3(step3);
      this.resetNextSteps();
    } else {
      this.reset();
    }
  }

  loadStations(service:Service,vehicle:Vehicle,fuel:Fuel,state:State){
    this.loadingStations = true;
    this.frontService.listStations(service.id, vehicle.id, fuel.id, state.id, this.professional_id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.stations = response.data;
          }
          this.loadingStations = false;
        },
        error: err => {
          console.log(err);
          this.loadingStations = false;
        }
      }
    );
  }

  onSelectStation(){
    if (this.selectedState && this.selectedStation) {
      const step3: Step3 = {
        subStep: 3,
        state: this.selectedState,
        station: this.selectedStation,
      };
      this.bookFormService.setStep3(step3);
      this.resetNextSteps();
    } else {
      this.reset();
    }
  }

  resetNextSteps() {
    this.bookFormService.resetStep4();
  }

  reset(){
    const step3: Step3 = {
      subStep: 1,
      state: undefined,
      station: undefined
    };
    this.bookFormService.updateStep3(step3);
  }

}
