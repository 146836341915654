import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {DashboardLayoutComponent} from "../../layouts/dashboard-layout/dashboard-layout.component";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    RouterOutlet,
    DashboardLayoutComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {

}
