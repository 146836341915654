<div>
  <div class="flex flex-col border-2 border-lightGray2 rounded-2xl p-4 gap-y-3 shadow-sm">
    <div class="flex flex-col">
      <label class="text-xs" appFormLabel>Fechas...</label>
      <div class="flex flex-col justify-start mb-2">
        <div class="flex justify-start flex-row items-center gap-x-2">
          <div class="">
            <input class="p-1 text-sm text-darkGray rounded-full border border-lightGray2" type="date" [(ngModel)]="dateSelected">
          </div>
          <div>
            <button (click)="addDate()" class=" flex bg-lightGray2 rounded-full w-6 h-6 text-darkGray text-center justify-center items-center">+</button>
          </div>
        </div>
        <span *ngIf="dateSelectedAlert" appFormError>
              La fecha es requerida
            </span>
      </div>
      <div class="flex flex-wrap items-center gap-x-2 gap-y-2">
        <div *ngFor="let dateControl of dates.controls; let i = index" class="text-darkGray bg-lightGray2 text-sm py-1 px-2 gap-x-2 border border-lightGray2 rounded-2xl flex items-center">
          <p class="text-sm text-darkGray">{{dateControl.value|date:'dd/MM/yy'}}</p>
          <button (click)="removeDate(i)" class="text-red-400">
            <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class=" w-4"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
          </button>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-1">
      <label class="text-xs" appFormLabel>Horas...</label>
      <div class="flex flex-col justify-start">
        <div class="flex justify-start flex-wrap gap-x-2 gap-y-2 items-center">
          <div class="flex flex-wrap items-center gap-x-2">
            <input class=" p-1 text-sm text-darkGray rounded-full border border-lightGray2" type="time" [(ngModel)]="initHourSelected">
            <p class="text-sm text-darkGray">a</p>
            <input class=" p-1 text-sm text-darkGray rounded-full border border-lightGray2" type="time" [(ngModel)]="finishHourSelected">
          </div>
          <div>
            <button (click)="addSchedule()" class=" flex justify-center items-center text-center bg-lightGray2 rounded-full w-6 h-6 text-darkGray">+</button>
          </div>
        </div>
        <span *ngIf="hoursSelectedAlert" appFormError>
              La hora de inicio y fin son requeridas
      </span>
      </div>

      <div class="flex flex-wrap items-center gap-x-2 gap-y-2 mt-1">
        <div *ngFor="let schedule of blocked.controls; let i = index" class="text-darkGray bg-lightGray2 text-sm py-1 px-2 border border-lightGray2 rounded-2xl flex items-center">
          <p class="text-sm text-darkGray">de {{schedule.get('init')?.value}} a {{schedule.get('finish')?.value}}</p>
          <button (click)="removeSchedule(i)" class="text-red-400">
            <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-4"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
          </button>
        </div>
      </div>
    </div>
    <form [formGroup]="scheduleForm" class="w-full mt-1">

      <div class="flex flex-col">
        <label class="text-xs" appFormLabel>Nombre</label>
        <input class=" p-1 rounded-full border border-lightGray2" type="text" formControlName="name">
        <span *ngIf="scheduleForm.get('name')?.touched && scheduleForm.get('name')?.invalid" appFormError>
            El nombre de la estación es requerido
      </span>
      </div>

      <!--  <div formArrayName="dates" class="flex flex-col gap-3 mt-2">
          <div *ngFor="let dateControl of dates.controls; let i = index" class="flex justify-between items-center gap-4">
            <div class="flex flex-col justify-start items-start mb-2 flex-grow">
              <label for="date-{{i}}" class="text-xs" appFormLabel>Fecha</label>
              <input id="date-{{i}}" [formControlName]="i" type="date" appFormInput />
              <span *ngIf="dateControl.touched && dateControl.invalid" appFormError>
              La fecha es requerida
            </span>
            </div>
            <button type="button" (click)="removeDate(i)" class="text-red-500 hover:text-red-700 font-bold">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-5">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M4 7l16 0" />
                <path d="M10 11l0 6" />
                <path d="M14 11l0 6" />
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
              </svg>
            </button>
          </div>
        </div>-->

      <!--  <button type="button" (click)="addDate()" appNextOutlined class="w-full">Añadir fecha</button>-->

      <!--  <div formArrayName="blocked" class="flex flex-col gap-3 mt-2">
          <div *ngFor="let schedule of blocked.controls; let i = index" [formGroupName]="i" class="flex justify-between items-center gap-4">
            <div class="flex flex-col justify-start items-start mb-2 flex-grow">
              <label for="init-{{i}}" class="text-xs" appFormLabel>Inicio</label>
              <input id="init-{{i}}" formControlName="init" type="time" appFormInput />
              <span *ngIf="schedule.get('init')?.touched && schedule.get('init')?.invalid" appFormError>
                    Hora de inicio requerida
                  </span>
            </div>

            <div class="flex flex-col justify-start  items-start mb-2 flex-grow">
              <label  for="finish-{{i}}" class="text-xs" appFormLabel>Fin</label>
              <input id="finish-{{i}}" formControlName="finish" type="time" appFormInput />
              <span *ngIf="schedule.get('finish')?.touched && schedule.get('finish')?.invalid" appFormError>
                    Hora de fin requerida
                  </span>
            </div>

            <button type="button" (click)="removeSchedule(i)" class="text-red-500 hover:text-red-700 font-bold">
              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
            </button>
          </div>

        </div>-->

      <!--  <button type="button" (click)="addSchedule()"  appNextOutlined class="w-full">Añadir bloqueo</button>-->
    </form>
  </div>

  <div class="flex justify-end items-center mt-4">
    <button (click)="onSubmit()" type="submit" class="bg-green duration-300 text-sm text-white px-4 py-2 rounded-2xl hover:bg-green-700 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-green-400">Guardar</button>
  </div>
</div>
