import {Component, OnInit} from '@angular/core';
import {StepperComponent} from "./components/stepper/stepper.component";
import {FormComponent} from "./components/form/form.component";
import {select, Store} from "@ngrx/store";
import {selectCurrentStep, selectShowSummary} from "../../store/reservationForm/stepper.selector";
import {Observable, Subscription} from "rxjs";
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {AuthService} from "../../core/services/auth/auth.service";
import {AuthStateService} from "../../core/services/auth/auth-state.service";
import {User} from "../../models/User";
import {SummaryComponent} from "./components/summary/summary.component";
import {updateShowSummary} from "../../store/reservationForm/stepper.actions";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-front',
  standalone: true,
  imports: [
    StepperComponent,
    FormComponent,
    AsyncPipe,
    NgIf,
    SummaryComponent,
    NgClass,
    RouterLink
  ],
  templateUrl: './front.component.html',
  styleUrl: './front.component.css'
})
export class FrontComponent{
  currentStep$: Observable<number>;
  currentStep: number = 1;
  user: User|null = null;
  showSummary$: Observable<boolean>;
  showSummary: boolean=false;

  constructor(private store: Store, private authService: AuthStateService,) {
    this.currentStep$ = this.store.pipe(select(selectCurrentStep));
    this.currentStep$.subscribe(step => {
      this.currentStep = step;
    });
    this.showSummary$ = this.store.pipe(select(selectShowSummary));
    this.showSummary$.subscribe(showSummary => {
      this.showSummary = showSummary;
    })


    this.authService.currentUser.subscribe(user => {
      this.user = user;
    })
  }

  public toggleSummary() {
    this.showSummary = !this.showSummary;
    let showSummary = this.showSummary;
    this.store.dispatch(updateShowSummary({showSummary}));
  }
}
