import { Directive } from '@angular/core';

@Directive({
  selector: '[appSecundaryNextOutlined]',
  host: {
    'class': ' border border-primary disabled:bg-darkGray text-primary shadow-sm hover:shadow-xl py-2 px-6 rounded-2xl text-center text-sm font-light flex items-center justify-center flex-row w-96 gap-4 duration-300'
  },
  standalone: true
})
export class SecundaryNextOutlinedButtonDirective {

  constructor() { }

}
