<script src="home-stations.component.ts"></script>
<div class=" flex flex-col gap-y-8 w-full">

  <app-loader  *ngIf="loading" size="large" />

  <a routerLink="/dashboard/estaciones/nueva" *ngIf="!loading && canCreate" appNextGreenButton class="w-fit">CREAR ESTACIÓN</a>

  <div class="w-full flex gap-2">
    <select appFormInputNoFull (change)="onChangeFilters()"  [(ngModel)]="selectedServiceId" *ngIf="!loadingFilters" name="" id="service_id">
      <option disabled [value]="null">Selecciona un servicio</option>
      <option *ngFor="let service of services" [value]="service.id">{{service.name}}</option>
    </select>
    <select appFormInputNoFull (change)="onChangeFilters()" [(ngModel)]="selectedVehicleId" *ngIf="!loadingFilters" name="" id="vehicle_id">
      <option disabled [value]="null">Selecciona un vehiculo</option>
      <option *ngFor="let vehicle of vehicles" [value]="vehicle.id">{{vehicle.name}}</option>
    </select>
    <app-loader *ngIf="loadingFilters" size="small"></app-loader>
  </div>
  <div class="grid grid-cols-3 w-fit gap-3">
    <div (click)="setFilter('active')" class="flex cursor-pointer justify-center border border-lightGray2 items-center py-1 px-5 rounded-2xl shadow-md hover:bg-lightBlue duration-300 {{selectedFilter === 'active' ? 'bg-lightBlue' : ''}}">
      <p class="duration-200 {{selectedFilter === 'active' ? 'text-primary' : 'text-lightGray'}}">ACTIVAS</p>
    </div>
    <div (click)="setFilter('inactive')" class="flex cursor-pointer justify-center border border-lightGray2 items-center py-1 px-5 rounded-2xl shadow-md hover:bg-lightBlue duration-300 {{selectedFilter === 'inactive' ? 'bg-lightBlue' : ''}}">
      <p class="duaration-200 {{selectedFilter === 'inactive' ? 'text-primary' : 'text-lightGray'}}">INACTIVAS</p>
    </div>
    <div (click)="setFilter('all')" class="flex cursor-pointer justify-center border border-lightGray2 items-center py-1 px-5 rounded-2xl shadow-md hover:bg-lightBlue duration-300 {{selectedFilter === 'all' ? 'bg-lightBlue' : ''}}">
      <p class="duration-200 {{selectedFilter === 'all' ? 'text-primary' : 'text-lightGray'}}">TODAS</p>
    </div>
    <div (click)="setType('movil')" class="flex cursor-pointer justify-center border border-lightGray2 items-center py-1 px-5 rounded-2xl shadow-md hover:bg-lightBlue duration-300 {{selectedType === 'movil' ? 'bg-lightBlue' : ''}}">
      <p class="duration-200 {{selectedType === 'movil' ? 'text-primary' : 'text-lightGray'}}">MÓVILES</p>
    </div>
    <div (click)="setType('fija')" class="flex cursor-pointer justify-center border border-lightGray2 items-center py-1 px-5 rounded-2xl shadow-md hover:bg-lightBlue duration-300 {{selectedType === 'fija' ? 'bg-lightBlue' : ''}}">
    <p class="duaration-200 {{selectedType === 'fija' ? 'text-primary' : 'text-lightGray'}}">FIJAS</p>
    </div>
    <div (click)="setType('all')" class="flex cursor-pointer justify-center border border-lightGray2 items-center py-1 px-5 rounded-2xl shadow-md hover:bg-lightBlue duration-300 {{selectedType === 'all' ? 'bg-lightBlue' : ''}}">
    <p class="duration-200 {{selectedType === 'all' ? 'text-primary' : 'text-lightGray'}}">TODAS</p>
    </div>
  </div>
  <div *ngIf="!loading">

    <div *ngFor="let state of states" class="mb-4">
      <div class="w-full flex gap-2 items-center">
        <div class="flex justify-center rounded-md p-1 bg-lightGray2">
          <img class="w-7" [src]="state.logo ? state.logo.original_url : '/assets/img/not-found.png'" [alt]="state.name">
        </div>
        <p class="text-darkGray text-lg">{{state.name }}</p>
      </div>
      <div class="py-4 grid w-full grid-cols-1 xl:grid-cols-2 gap-4 gap-x-8">
        <ng-container  *ngFor="let station of state.stations">
          <div *ngIf="shouldShowStation(station)" class="border-lightGray2 border shadow rounded-2xl h-full p-2 px-8 flex items-center animate-fade-in">

            <div class="w-1/3 flex flex-col gap-y-2 justify-start px-2">
              <p class="text-primary text-lg font-[500]">{{station.name}}</p>
              <a [routerLink]="'/dashboard/estaciones/editar/'+station.id" *ngIf="canEditStations">
                <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5 text-orange"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
              </a>
              <div class="flex justify-start items-center gap-4">
                <p  class="text-darkGray text-sm">{{ station.code }} </p> <span *ngIf="!station.start && !station.end" class="text-xs text-darkGray">{{ station.last_available | date:'dd/MM/YYYY' }}</span> <span *ngIf="station.start && station.end" class="text-xs text-darkGray">{{ station.start | date:'dd/MM/YYYY' }} - {{ station.end | date:'dd/MM/YYYY' }}</span>
              </div>
            </div>
            <div *ngIf="canEditStations"><p-inputSwitch [(ngModel)]="station.show" (onChange)="changeActive(station)"></p-inputSwitch></div>
            <div class="w-[2px] rounded-full shadow-sm h-[70%] bg-lightGray2 mx-4"> </div>
            <div class="w-2/3 flex flex-col gap-2">
              <div class="flex gap-2 justify-end items-center px-2">
                <!--<p class="text-right text-sm text-darkGray">1<sup>er</sup> día con cita libre:  </p>-->
                <div *ngIf="!station.available || station.available.length == 0" class="text-center w-full">
                  <span class="text-darkGray text-sm">No hay información para mostrar</span>
                </div>
                <div class="w-full flex justify-between text-sm" *ngIf="station.available && station.available.length > 0">
                  <!-- [ngClass]="i == 0 ? 'bg-primary !text-white rounded-xl' : 'text-primary'"-->
                  <div  class="text-sm flex flex-col items-center px-2 py-1 cursor-pointer text-primary" *ngFor="let day of station.available; let i = index">
                    <span class="">{{day.date | customDay}}</span>
                    <span [class.text-green]="day.available > 0" [class.text-red-500]="day.available <= 0">{{day.date | date:'dd'}}</span>
                    <!-- <span *ngIf="i%2 != 0" [class.text-red-500]="i%2 != 0" [class.text-white]="i == 0">{{day.date | customDay}}</span> -->
                    <span class="">{{ day.date | customMonth }}</span>
                  </div>
                </div>
              </div>
              <!--<div>
                <select appFormInput name="" id="">
                  <option disabled value="">Selecciona un servicio</option>
                  <option *ngFor="let quadrant of station.quadrants" [value]="quadrant.id">{{quadrant.name}}</option>
                </select>
              </div>-->
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </div>

</div>
