import { Component } from '@angular/core';
import {State} from "../../../../../../models/State";
import {BreadcrumbService} from "../../../../../../core/services/breadcrumb/breadcrumb.service";
import {StationService} from "../../../../../../core/services/stations/station.service";
import {Response} from "../../../../../../models/Response";
import {LoaderComponent} from "../../../../../../shared/components/loader/loader.component";
import {DatePipe, JsonPipe, NgClass, NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {RouterLink} from "@angular/router";
import {FormInputDirective} from "../../../../../../shared/directives/forms/form-input.directive";
import {FormGroup, FormsModule, Validators} from "@angular/forms";
import {ServicesService} from "../../../../../../core/services/services/services.service";
import {VehiclesService} from "../../../../../../core/services/vehicles/vehicles.service";
import {Service} from "../../../../../../models/Service";
import {Vehicle} from "../../../../../../models/Vehicle";
import {combineLatest, forkJoin} from "rxjs";
import {CustomDayPipe} from "../../../../../../shared/pipes/custom-day.pipe";
import {CustomMonthPipe} from "../../../../../../shared/pipes/custom-month.pipe";
import {FormInputNoFullDirective} from "../../../../../../shared/directives/forms/form-input-no-full.directive";
import {NextOutlinedButtonDirective} from "../../../../../../shared/directives/buttons/next-outlined.directive";
import {NextSquareButtonDirective} from "../../../../../../shared/directives/buttons/next-square.directive";
import {NextGreenButtonDirective} from "../../../../../../shared/directives/buttons/next-green.directive";
import {InputSwitchModule} from "primeng/inputswitch";
import {Station} from "../../../../../../models/Station";
import {StationForm} from "../../../../../../models/StationForm/StationForm";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../../../../../core/services/auth/auth.service";
import {AuthStateService} from "../../../../../../core/services/auth/auth-state.service";

@Component({
  selector: 'app-home-stations',
  standalone: true,
  imports: [
    LoaderComponent,
    NgIf,
    NgForOf,
    NgClass,
    RouterLink,
    FormInputDirective,
    FormsModule,
    JsonPipe,
    DatePipe,
    UpperCasePipe,
    CustomDayPipe,
    CustomMonthPipe,
    FormInputNoFullDirective,
    NextOutlinedButtonDirective,
    NextSquareButtonDirective,
    NextGreenButtonDirective,
    InputSwitchModule
  ],
  templateUrl: './home-stations.component.html',
  styleUrl: './home-stations.component.css'
})
export class HomeStationsComponent {
  loading = false;

  loadingFilters: boolean = false;

  today = new Date();

  states: State[] = [];

  services: Service[] = [];
  vehicles: Vehicle[] = [];

  days = [
    26, 27, 28, 29, 30, 31, '01'
  ];

  selectedFilter: 'all' | 'active' | 'inactive' = 'all';
  selectedType: 'fija' | 'movil' | 'all' = 'all';


  selectedServiceId: number|null = null;
  selectedVehicleId: number|null = null;

  canCreate: boolean = false;
  canEditStations = false;

  constructor(private breadcrumbService: BreadcrumbService, private stationService: StationService,
              private serviceService: ServicesService, private vehicleService: VehiclesService, private toastr:ToastrService,
              private authService: AuthStateService,) {
    this.authService.currentUser.subscribe({next: user => {
        this.canCreate = user?.permissions.some(v => v === 'create_stations') ?? false;
        this.canEditStations = user?.permissions.some(v => v === 'update_stations') ?? false;
      }})
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Estaciones',
      items: [{
        name: 'Listado',
      }]
    });
    this.loadStations();
    this.loadFilters();
    this.getFilterAndType()
  }

  getFilterAndType(){
    let storedFilter = localStorage.getItem('itv_stations_filter')
    if(storedFilter){
      this.setFilter(storedFilter as 'all' | 'active' | 'inactive')
    }
    let storedType = localStorage.getItem('itv_stations_type')
    if(storedType){
      this.setType(storedType as 'fija' | 'movil' | 'all')
    }
  }

  setFilter(filter: 'all' | 'active' | 'inactive') {
    this.selectedFilter = filter;
    localStorage.setItem('itv_stations_filter',this.selectedFilter)
  }

  setType(type: 'fija' | 'movil' | 'all') {
    this.selectedType = type;
    localStorage.setItem('itv_stations_type',this.selectedType);
  }

  shouldShowStation(station: Station): boolean {
    if (this.selectedFilter === 'all') {
      return true && this.inTypeSelection(station);
    } else if (this.selectedFilter === 'inactive' && !station.show) {
      return true && this.inTypeSelection(station);
    } else if(this.selectedFilter === 'active' && station.show){
      return true && this.inTypeSelection(station);
    }
    return false && this.inTypeSelection(station);
  }

  inTypeSelection(station: Station) {
    if (this.selectedType === 'all') {
      return true;
    } else if (this.selectedType === 'fija' && !station.start && !station.end) {
      return true;
    } else if(this.selectedType === 'movil' && station.start && station.end){
      return true;
    }
    return false;
  }

  loadStations(){
    this.loading = true;
    this.stationService.listByState(this.selectedServiceId, this.selectedVehicleId).subscribe({
      next: (data: Response<State[]>) => {
        if (data.data) {
          this.states = data.data;
        }
        this.loading = false;
      },
      error: err => {
        this.loading = false;
      },
      complete: () => {}
    })
  }

  loadFilters(){
    this.loadingFilters = true;
    combineLatest([
      this.serviceService.list(),
      this.vehicleService.list()
    ]).subscribe({
      next: ([services, vehicles]) => {
        //console.log('Services:', services);
        //console.log('Vehicles:', vehicles);

        // Maneja los resultados aquí
        this.services = services.data ?? [];
        this.vehicles = vehicles.data ?? [];

        this.loadingFilters = false;
      }, error: err => {
        this.loadingFilters = false;
      }
    });
  }

  changeActive(station:Station){
    let data:any = {
      show: station.show
    }
    this.stationService.updateShow(data, station.id).subscribe({
      next: (data) => {
        if (data.data) {
          // this.toastr.success('')
        }
      },
      error: error => {
        this.toastr.error('Ha habido un error al editar la estación.');
      }
    })
  }

  onChangeFilters(){
    if (this.selectedVehicleId && this.selectedServiceId) {
      this.loadStations();
    }
  }

  protected readonly console = console;
}
