import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {JsonPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {QuadrantSection} from "../../../models/QuadrantSection";
import {formatDate, pad2Start} from "../../../const/functions";
import {QuadrantInfo} from "../../../models/QuadrantInfo";
import {LoaderComponent} from "../loader/loader.component";


interface Day { dayOfMonth: number, dayOfWeek: number, fullDate?: any };
@Component({
  selector: 'app-month-calendar',
  standalone: true,
  imports: [
    NgClass,
    NgForOf,
    NgIf,
    JsonPipe,
    LoaderComponent
  ],
  templateUrl: './month-calendar.component.html',
  styleUrl: './month-calendar.component.css'
})
export class MonthCalendarComponent  implements OnChanges {
  @Input() month: number = new Date().getMonth();
  @Input() year: number = new Date().getFullYear(); // Default to current year
  weekDays: string[] = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
  // weekDays2: string[] = ['lun', 'mar', 'mié', 'j', 'v', 'S', 'D'];
  monthNames: string[] = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
  days: Day[][] = [];
  @Input() sections: QuadrantSection[] = [];


  @Input() selectDaysMode: boolean = false;
  @Input() currentSelection: string[] = [];
  @Input() selectedColor : string = '#168FFB';
  @Input() selectedTextColor : string = '#FFFFFF';
  @Output() onSelectDayChange: EventEmitter<string> = new EventEmitter();

  @Input() blockedDates: string[] = [];
  @Input() blockedDatesColor : string = '#EBECEE';

  @Input() frontMode: boolean = false;
  @Input() changeMonth: boolean = false;

  @Input() quadrantMode: boolean = false;
  @Input() loading: boolean = false;
  @Input() quadrantInfo: QuadrantInfo|undefined;

  @Output() prevMonth: EventEmitter<void> = new EventEmitter();
  @Output() nextMonth: EventEmitter<void> = new EventEmitter();
  @Input() editReservationMode: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['month'] || changes['year']) {
      this.generateCalendar(this.month, this.year);
    }
  }

  generateCalendar(month: number, year: number) {
    const date = new Date(year, month - 1, 1);
    const firstDay = date.getDay() === 0 ? 6 : date.getDay() - 1; // Convert Sunday (0) to 6
    const daysInMonth = new Date(year, month, 0).getDate();
    const calendar:Day[][] = [];
    let week: Day[] = [];

    // Fill initial empty slots in the first week with zeros
    for (let i = 0; i < firstDay; i++) {
      week.push({ dayOfMonth: 0, dayOfWeek: i, fullDate: null });
    }

    // Fill days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      let fullDate = new Date(year, month - 1, day);
      const dayOfWeek = fullDate.getDay();
      week.push({ dayOfMonth: day, dayOfWeek, fullDate: formatDate(fullDate, '-', 'en') });
      if (week.length === 7) {
        calendar.push(week);
        week = [];
      }
    }

    // Fill the remaining days of the last week with zeros
    if (week.length > 0) {
      while (week.length < 7) {
        week.push({ dayOfMonth: 0, dayOfWeek: week.length, fullDate: null });
      }
      calendar.push(week);
    }

    this.days = calendar;
  }

  isDayInSection(day: Day): string | null {
    if (this.blockedDates.includes(day.fullDate)) {
      return this.blockedDatesColor;
    } else {
      for (const section of this.sections) {
        if (section.days.some(d => typeof d === 'string' && d === day.fullDate)) {
          return section.color;
        }
      }

      // Luego verifica las secciones con días de la semana
      for (const section of this.sections) {
        if (section.days.includes(day.dayOfWeek)) {
          return section.color;
        }
      }
    }
    // Primero verifica las secciones con fechas específicas


    return null;
  }

  onSelectDay(date: string){
    if (date) {
      this.onSelectDayChange.emit(date);
    }
  }


  getBooked(date: string){
    if (this.quadrantInfo) {
      let days = this.quadrantInfo.daysInfo;
      let info = days.find(v => v.date === date);
      if (info) {
        return info.booked ? info.booked.total : 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getReduction(date: string){
    if (this.quadrantInfo) {
      let days = this.quadrantInfo.daysInfo;
      let info = days.find(v => v.date === date);
      if (info) {
        return info.state_reduction && info.state_reduction.length > 0;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getFree(date: string) {
    if (this.quadrantInfo) {
      let days = this.quadrantInfo.daysInfo;
      let info = days.find(v => v.date === date);
      if (info) {
        return info.available ?? 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getList(date:string){
    if (this.quadrantInfo) {
      let days = this.quadrantInfo.daysInfo;
      let info = days.find(v => v.date === date);
      if (info) {
        if (info.booked) {
          let data = [];
          for (const key in info.booked) {
            if (key !== 'total') {
              data.push({
                name: key,
                count: info.booked[key]
              })
            }
          }
          return data;
        }
      }
    }

    return [];

  }

  lineColor(day: Day){
    if (this.quadrantInfo) {
      for (const section of this.quadrantInfo.lines) {
        if (section.info.some(d => typeof d === 'string' && d === day.fullDate)) {
          return section.color;
        }
      }

      // Luego verifica las secciones con días de la semana
      for (const section of this.quadrantInfo.lines) {
        if (section.info.includes(day.dayOfWeek)) {
          return section.color;
        }
      }
    }

    return 'transparent';

  }

}
