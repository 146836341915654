import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Quadrant} from "../../../models/Quadrant";
import {Response} from "../../../models/Response";
import {Fuel} from "../../../models/Fuel";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class QuadrantService {

  constructor(private http: HttpClient) { }

  updateServices(quadrantId:number,data:any){
    return this.http.post<Response<Quadrant>>(`${environment.API_URL}/quadrants/update-services/${quadrantId}`,data)
  }

  updateVehicles(quadrantId:number,data:any){
    return this.http.post<Response<Quadrant>>(`${environment.API_URL}/quadrants/update-vehicles/${quadrantId}`,data)
  }

  updateFuels(quadrantId:number,data:any){
    return this.http.post<Response<Quadrant>>(`${environment.API_URL}/quadrants/update-fuels/${quadrantId}`,data)
  }

  updateVehicleVisibility(quadrantId:number,vehicleId:number):Observable<Response<Quadrant>>{
    return this.http.get<Response<Quadrant>>(`${environment.API_URL}/quadrants/update-visible-vehicle/${quadrantId}/${vehicleId}`)
  }

  updateServiceVisibility(quadrantId:number,serviceId:number):Observable<Response<Quadrant>>{
    return this.http.get<Response<Quadrant>>(`${environment.API_URL}/quadrants/update-visible-service/${quadrantId}/${serviceId}`)
  }

  updateFuelVisibility(quadrantId:number,fuelId:number):Observable<Response<Quadrant>>{
    return this.http.get<Response<Quadrant>>(`${environment.API_URL}/quadrants/update-visible-fuel/${quadrantId}/${fuelId}`)
  }
}
