import {CreateQuadrantHour, generateHourList} from "./StationForm/CreateQuadrantHour";
import {ToastrService} from "ngx-toastr";

export interface QuadrantSection {
  name: string,
  days: any[];
  color: string;
  hours: CreateQuadrantHour[]
}


export function exportSectionsToJson(sections: QuadrantSection[]) {
  const jsonData = JSON.stringify(sections, null, 2);
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'lineas-quadrante.json';
  a.click();
  window.URL.revokeObjectURL(url);
}

export function exportToCsv(sections: QuadrantSection[]) {

}

export function importSectionsFromJson(e: any, toastr: ToastrService): any[] {
  const importedData = JSON.parse(e.target.result as string);
  if (Array.isArray(importedData) && importedData.every(item => item.name && item.days !== undefined && item.color && Array.isArray(item.hours) && item.hours.every((item2:any) =>  item2.hour && item2.capacity !== undefined))) {
    toastr.success('Líneas del cuadrante importadas correctamente.');
    return importedData;
  } else {
    console.error('Invalid JSON structure');
    return [];
  }
  /*if (Array.isArray(importedData) && importedData.every(item => item.hour && item.capacity !== undefined)) {
    toastr.success('Horas y capacidad importado correctamente.')
    return importedData;
  } else {
    console.error('Invalid JSON structure');
    return generateHourList();
  }*/
}

export function importFromCsv() {

}
