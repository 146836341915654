import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {Paginator} from "../../../models/Paginator";
import {RoleV2, UserV2} from "../../../models/UserV2";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) { }

  list(): Observable<Response<RoleV2[]>>{
    return this.http.get<Response<RoleV2[]>>(`${environment.API_URL}/roles/list`);
  }
}
