<div class="grid grid-cols-1 lg:grid-cols-4 gap-y-8 bg-gray-50 w-full border-b-2 border-gray-300 px-4 py-6">
  <div class="flex justify-center">
    <img (click)="reset()" src="assets/img/logo.svg" class="w-20 sm:w-20 md:w-24 lg:w-28 cursor-pointer" alt="logo">
  </div>
  <div class="flex flex-row justify-center lg:col-span-2">
    <div *ngFor="let num of [1, 2, 3, 4, 5, 6, 7]" class="flex flex-col items-center justify-center">
      <div class="flex flex-row justify-center items-center">
        <div *ngIf="num != 1"
             class="h-0 w-1 sm:w-7 border {{currentStep == num ? 'border-amber-300' : ''}} {{currentStep < num ? 'border-gray-400':''}} {{ currentStep > num ? 'border-gray-600' : ''}} duration-300"></div>
        <div (click)="goStep(num)"
             class="w-8 h-8 sm:w-8 sm:h-8  flex justify-center {{formPassed ? '' : 'cursor-pointer'}} items-center rounded-full border {{num==1 ? 'ml-7':''}} {{num==7 ? 'mr-8' : ''}} {{currentStep == num ? 'border-amber-300 bg-amber-300 text-gray-800' : ''}} {{ currentStep > num ? 'bg-gray-600 text-gray-50 border-gray-600' : ''}} {{currentStep < num ? 'border-gray-400 text-gray-400':''}} duration-300">{{ num }}
        </div>
        <div *ngIf="num != 7"
             class="h-0 w-1 sm:w-7 border {{currentStep == num ? 'border-amber-300' : ''}} {{currentStep < num ? 'border-gray-400':''}} {{ currentStep > num ? 'border-gray-600' : ''}} duration-300"></div>
      </div>
      <div class="text-[10px] mt-2  hidden sm:block">{{ steps[num - 1].name }}</div>
    </div>
  </div>
  <div class="flex gap-2 items-center justify-center">
    <a href="#" onclick="doGTranslate('es|es');return false;" title="Español" id="español" class="glink nturl notranslate"><img class="w-5" src="/assets/img/es.svg" alt="Español"/></a>
    <a href="#" onclick="doGTranslate('es|ca');return false;" title="Catalá" id="catala" class="glink nturl notranslate"><img class="w-5"  src="/assets/img/ca.png" alt="Catalá"/></a>
  </div>
</div>
