import { Component } from '@angular/core';
import {Paginator} from "../../../../models/Paginator";
import {Vehicle} from "../../../../models/Vehicle";
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {StationService} from "../../../../core/services/stations/station.service";
import {VehiclesService} from "../../../../core/services/vehicles/vehicles.service";
import {ToastrService} from "ngx-toastr";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {SortColumn} from "../../../../models/Table/SortColumn";
import {TableHeader} from "../../../../models/Table/TableHeader";
import {Filter} from "../../../../models/Table/Filter";
import {FuelService} from "../../../../core/services/fuels/fuel.service";
import {Fuel} from "../../../../models/Fuel";
import {GenericTableComponent} from "../../../../shared/components/generic-table/generic-table.component";
import {TableFiltersComponent} from "../../../../shared/components/generic-table/table-filters/table-filters.component";
import {
  TableHeaderWrapperComponent
} from "../../../../shared/components/generic-table/table-header-wrapper/table-header-wrapper.component";

@Component({
  selector: 'app-fuels',
  standalone: true,
  imports: [
    GenericTableComponent,
    TableFiltersComponent,
    TableHeaderWrapperComponent
  ],
  templateUrl: './fuels.component.html',
  styleUrl: './fuels.component.css'
})
export class FuelsComponent {

  loading:boolean=false;
  fuels:Paginator<Fuel>| null = null;
  constructor(private breadcrumbService: BreadcrumbService, private fuelService:FuelService,
              private toastr: ToastrService) {
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Combustibles',
      items: [{
        name: 'Listado',
      }]
    });
  }

  ngOnInit() {
    this.loading=true;
    this.loadFuels();
  }

  loadFuels(){
    this.fuelService.listpPaginate().subscribe(
      {
        next: (response) => {
          if (response.data) {
            this.fuels = response.data;
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  perPage: number = 50;
  loadingList = false;
  loadingFilters = false;

  filters: ActiveFilter[] = [];
  sorts: SortColumn[] = [];

  tableHeaders: TableHeader[] = [
    {
      name: 'ID',
      active: true,
      td_type: 'text',
      td_key: 'id',
      sort: false,
    },
    {
      name: 'NOMBRE',
      active: true,
      td_type: 'text',
      td_key: 'name',
      sort: false,
    },
    {
      name: 'SLUG',
      active: true,
      td_type: 'text',
      td_key: 'slug',
      sort: false,
    },
    {
      name: 'FECHA CREACIÓN',
      active: false,
      td_type: 'date',
      format:'dd/MM/Y HH:mm',
      td_key: 'created_at',
      sort: false,
    },
    {
      name: 'FECHA MODIFICACIÓN',
      active: false,
      td_type: 'date',
      format:'dd/MM/Y HH:mm',
      td_key: 'updated_at',
      sort: false,
    }
  ]

  tableFilters: Filter[] = [
    {
      key: 'name',
      type: 'search',
      operator: 'like',
      placeholder: 'Buscar...'
    }
  ]

  onChangeSorts(sorts: SortColumn[]): void {
    this.sorts = sorts;
    this.loadFuels();
  }

  onChangeFilters(filters: ActiveFilter[]){
    this.filters = filters;
    this.loadFuels();
  }

}
