<div class="flex flex-col gap-y-6 justify-center text-center mx-auto  my-auto duration-300 px-6">
  <app-step1 *ngIf="currentStep == 1" (stepCompleted)="nextStep()"></app-step1>
  <app-step2 *ngIf="currentStep == 2"></app-step2>
  <app-step3 *ngIf="currentStep == 3"></app-step3>
  <app-step4 *ngIf="currentStep == 4" ></app-step4>
<app-step5 *ngIf="currentStep == 5"></app-step5>
  <app-step6 *ngIf="currentStep == 6"></app-step6>
 <app-step7 *ngIf="currentStep == 7"></app-step7>
  <div class="flex flex-row justify-between">
    <!--<button *ngIf="currentStep > 1 && currentStep < 5"
            class="bg-blue-100 text-blue-500 w-fit rounded-full px-4 py-2 hover:bg-blue-200 duration-300"
            (click)="prevStep()">← Atrás
    </button>-->
    <!--<button *ngIf="currentStep == 4"
            class="bg-blue-500 text-gray-50 w-fit rounded-2xl px-4 py-4 hover:bg-blue-600 duration-300"
            (click)="nextStep()">Continuar →
    </button>-->

  </div>


  <!--<div class="grid grid-cols-2 gap-8">
    <pre>
    {{step1 | json}}
  </pre>
    <pre>
    {{step2 | json}}
  </pre>
  </div>-->
</div>
