import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {SortColumn} from "../../../models/Table/SortColumn";
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {Paginator} from "../../../models/Paginator";
import {Reservation} from "../../../models/Reservation";
import {environment} from "../../../../environments/environment";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";
import {completeHttpFiltersAndSorts} from "../../../const/functions";

@Injectable({
  providedIn: 'root'
})
export class PaginatorService {

  constructor(private http: HttpClient) { }

  paginate(url: string, sorts: SortColumn[] = [], filters: ActiveFilter[]): Observable<Response<Paginator<any>>> {
    let params = completeHttpFiltersAndSorts(sorts, filters);
    return this.http.get<Response<Paginator<any>>>(`${url}`, {params});
  }
}
