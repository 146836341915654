import {Component, EventEmitter, Output} from '@angular/core';
import {BookFormService} from "../../../../../../../../core/services/book-form.service";
import {Step1, Step2} from "../../../../../../../../models/Front/ReservationForm";
import {FrontService} from "../../../../../../../../core/services/front/front.service";
import {Service} from "../../../../../../../../models/Service";
import {
  FormInputBlueNoFullDirective
} from "../../../../../../../../shared/directives/forms/form-input-blue-no-full.directive";
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {Vehicle} from "../../../../../../../../models/Vehicle";
import {Fuel} from "../../../../../../../../models/Fuel";
import {updateStep2} from "../../../../../../../../store/reservationForm/stepper.actions";
import {LoaderComponent} from "../../../../../../../../shared/components/loader/loader.component";

@Component({
  selector: 'app-step2',
  standalone: true,
  imports: [
    FormInputBlueNoFullDirective,
    FormsModule,
    NgForOf,
    NgIf,
    LoaderComponent
  ],
  templateUrl: './step2.component.html',
  styleUrl: './step2.component.css'
})
export class Step2Component {

  step1?: Step1;
  step2?: Step2;

  services: Service[] = [];
  selectedService?: Service;

  vehicles: Vehicle [] = [] ;
  selectedVehicle?:Vehicle ;

  fuels?:Fuel[];
  selectedFuel?:Fuel;

  loading = true;
  loadingVehicles = false;
  loadingFuels = false;

  professional:boolean = false;
  professional_id?:number = undefined;

  constructor(private bookFormService: BookFormService, private frontService: FrontService) {
    this.bookFormService.reservationForm$.subscribe({
      next: data => {
        if (data.steps.step1 && !this.step1){
          this.step1 = data.steps.step1;
          this.professional_id = data.professional_id;
          this.professional = data.professional;
          this.loadServices();
        } else {
          if(this.professional_id != data.professional_id){
            this.professional_id = data.professional_id;
            this.selectedService = undefined;
            this.loadServices();
            this.resetNextSteps();
            this.reset();
          }
        }

        this.step2 = data.steps.step2;
      }
    })
  }

  loadServices(){
    this.frontService.listServices(this.professional_id).subscribe(
      {
        next: (response) => {
          if (response.data) {
            this.services = response.data;
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  onSelectService(){
    if (this.selectedService){
      this.selectedVehicle = undefined;
      this.selectedFuel = undefined;
      this.loadVehicles(this.selectedService);

      const step2: Step2 = {
        subStep: 2,
        service: this.selectedService,
        vehicle: undefined,
        fuel: undefined
      };
      this.bookFormService.updateStep2(step2);
      this.resetNextSteps();
    } else {
      this.reset();
    }
  }

  loadVehicles(service: Service) {
    this.loadingVehicles = true;
    this.frontService.listVehicles(service.id, this.professional_id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.vehicles=response.data;
            /*if(this.step2 && this.step2.vehicle){
              this.selectedVehicle = this.vehicles.find(v => v.id === this.step2?.vehicle?.id)
              if(this.selectedVehicle && this.selectedService){
                this.loadFuels(this.selectedVehicle, this.selectedService)
              }
            }*/
          }
          this.loadingVehicles = false;
        },
        error: err => {
          console.log(err);
          this.loadingVehicles = false;
        }
      }
    );
  }

  onSelectVehicle(){
    if (this.selectedService && this.selectedVehicle){
      this.selectedFuel = undefined;

      this.loadFuels(this.selectedVehicle,this.selectedService)

      const step2: Step2 = {
        subStep: 3,
        service: this.selectedService,
        vehicle: this.selectedVehicle,
        fuel: undefined
      };
      this.bookFormService.updateStep2(step2);
      this.resetNextSteps();
    } else {
      this.reset();
    }
  }

  loadFuels(vehicle:Vehicle, service: Service){
    this.loadingFuels = true;
    this.frontService.listFuels(service.id,vehicle.id, this.professional_id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.fuels=response.data;
            /*if(this.step2 && this.step2.fuel){
              this.selectedFuel = this.fuels.find(f => f.id === this.step2?.fuel?.id)
              if(this.selectedFuel && this.selectedVehicle && this.selectedService){
                this.loadStates(this.selectedService,this.selectedVehicle,this.selectedFuel)
              }
            }*/
          }
          this.loadingFuels = false;
        },
        error: err => {
          this.loadingFuels = false;
        }
      }
    );
  }

  onSelectFuel(){
    if (this.selectedService && this.selectedVehicle && this.selectedVehicle) {
      const step2: Step2 = {
        subStep: 4,
        service: this.selectedService,
        vehicle: this.selectedVehicle,
        fuel: this.selectedFuel
      };
      this.bookFormService.setStep2(step2);
      this.resetNextSteps();
    } else {
     this.reset();
    }
  }

  resetNextSteps() {
    this.bookFormService.resetStep3();
    this.bookFormService.resetStep4();
  }

  reset(){
    const step2: Step2 = {
      subStep: 1,
      service: undefined,
      vehicle: undefined,
      fuel: undefined
    };
    this.bookFormService.updateStep2(step2);
  }
}
