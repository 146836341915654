import { Directive } from '@angular/core';

@Directive({
  selector: '[appNextOutlinedRed]',
  host: {
    'class': ' text-center items-center cursor-pointer text-sm rounded-full px-4 py-4 border border-red2 text-red2 hover:bg-red2 hover:text-white duration-200'
  },
  standalone: true
})
export class NextOutlinedRedButtonDirective {

  constructor() { }

}
