import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {NgForOf, NgIf} from "@angular/common";
import {select, Store} from "@ngrx/store";
import {selectConfirmed, selectCurrentStep} from "../../../../store/reservationForm/stepper.selector";
import {
  updateConfirmed,
  updateStep,
  updateStep1,
  updateStep2,
  updateStep3,
  updateStep4, updateStep5, updateStep6
} from "../../../../store/reservationForm/stepper.actions";
import {Step1, Step2, Step3, Step4, Step5, Step6} from "../../../../models/Front/ReservationForm";
import {Router} from "@angular/router";

@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.css'
})
export class StepperComponent implements OnInit{
  @Input() formPassed:boolean=false;
  confirmed$?:Observable<boolean>;
  confirmed? = false;
  currentStep$?: Observable<number>;

  currentStep: number = 1;

  steps = [
    { name: 'MATRÍCULA' },
    { name: 'VEHÍCULO' },
    { name: 'LUGAR' },
    { name: 'FECHA' },
    { name: 'DATOS' },
    { name: 'PAGO' },
    { name: 'CONFIRMACIÓN' },
  ];

  constructor(private store:Store,private cd: ChangeDetectorRef, private router:Router) {
    this.currentStep$ = this.store.pipe(select(selectCurrentStep));
    this.confirmed$ = this.store.pipe(select(selectConfirmed));
  }

  ngOnInit() {
    if(this.formPassed){
      this.currentStep=8
      this.confirmed=true;
    } else {
      this.currentStep$?.subscribe(step => {
        this.currentStep = step;
        this.cd.detectChanges();
      })
      this.confirmed$?.subscribe(confirmed => {
        this.confirmed = confirmed;
      })
    }
  }

  public goStep(step:number) {
    if(step<this.currentStep && !this.confirmed){
      // console.log('Current Step before:', this.currentStep);
      this.currentStep = step;
      this.store.dispatch(updateStep({ step }));
      // console.log('Current Step after:', this.currentStep);
    }
  }

  public resetStep1(){
    let step1:Step1 = {
      registration_number:'',
    }
    this.store.dispatch(updateStep1({step1}))
  }

  public resetStep2(){

    let step2: Step2 = {
      subStep: 1,
    };
    this.store.dispatch(updateStep2({step2}));

  }

  public resetStep3(){
    let step3: Step3 = {
      subStep: 1,
    };
    this.store.dispatch(updateStep3({step3}));
  }

  public resetStep4(){
    let step4:Step4={
      date:undefined,
      hour:undefined,
    }
    this.store.dispatch(updateStep4({
      step4
    }))
  }

  public resetStep5(){
    let step5:Step5={
      phone_number: '',
      email: '',
      name: '',
    }
    this.store.dispatch(updateStep5({
      step5
    }))
  }

  public resetStep6(){
    let step6:Step6={
      payment:undefined,
    }
    this.store.dispatch(updateStep6({step6}))
  }

  public reset(){
    this.resetStep1();
    this.resetStep2();
    this.resetStep3();
    this.resetStep4();
    this.resetStep5();
    this.resetStep6();
    let step = 1;
    this.store.dispatch(updateStep({step}));
    let confirmed = false;
    this.store.dispatch(updateConfirmed({confirmed}));
    this.router.navigate(['']);
  }

}
