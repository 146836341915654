import {Component, OnInit, ViewChild} from '@angular/core';
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {DatePipe, JsonPipe, NgForOf, NgIf} from "@angular/common";
import {StationService} from "../../../../core/services/stations/station.service";
import {combineLatest} from "rxjs";
import {Station} from "../../../../models/Station";
import {FormsModule, NgForm} from "@angular/forms";
import {Quadrant} from "../../../../models/Quadrant";
import {QuadrantInfo} from "../../../../models/QuadrantInfo";
import {CalendarComponent} from "../calendar/calendar.component";
import {MonthCalendarComponent} from "../../../../shared/components/month-calendar/month-calendar.component";
import {FormInputNoFullDirective} from "../../../../shared/directives/forms/form-input-no-full.directive";
import {HoursFormComponent} from "../../../../shared/components/hours-form/hours-form.component";
import {QuadrantSection} from "../../../../models/QuadrantSection";
import {QuadrantLine} from "../../../../models/QuadrantLine";
import {ToastrService} from "ngx-toastr";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {BlockedDatesComponent} from "../../../../shared/components/blocked-dates/blocked-dates.component";
import {
  CommunityBlockedFormComponent
} from "../comunity/components/community-blocked-form/community-blocked-form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NextGreenButtonDirective} from "../../../../shared/directives/buttons/next-green.directive";
import {ButtonDirective} from "primeng/button";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {FormInputDirective} from "../../../../shared/directives/forms/form-input.directive";
import {NextButtonDirective} from "../../../../shared/directives/buttons/next.directive";

@Component({
  selector: 'app-quadrants',
  standalone: true,
  imports: [
    LoaderComponent,
    NgIf,
    FormsModule,
    NgForOf,
    CalendarComponent,
    MonthCalendarComponent,
    FormInputNoFullDirective,
    HoursFormComponent,
    DatePipe,
    OverlayPanelModule,
    BlockedDatesComponent,
    CommunityBlockedFormComponent,
    NextGreenButtonDirective,
    ButtonDirective,
    ConfirmDialogModule,
    JsonPipe,
    FormInputDirective,
    NextButtonDirective
  ],
  templateUrl: './quadrants.component.html',
  styleUrl: './quadrants.component.css'
})
export class QuadrantsComponent implements OnInit {
  loadingFilters: boolean = false;

  selectStation: number|null = null;
  selectQuadrant: number|null = null;

  selectedSection: QuadrantLine|undefined;

  stations: Station[] = [];
  quadrants: Quadrant[] = [];

  quadrantInfo: QuadrantInfo|undefined;
  quadrantLinesBlocked: QuadrantLine[] = [];

  @ViewChild('blockedForm') blockedForm!: CommunityBlockedFormComponent;

  month = new Date().getMonth() + 1;
  year = new Date().getFullYear();

  manualSelectedMode = false;

  selectedDates: string[] = [];
  stationByParam = false;

  @ViewChild('hoursForm') hoursForm!: HoursFormComponent;


  constructor(private breadcrumbService: BreadcrumbService, private stationService: StationService,
              private toastr: ToastrService, private route: ActivatedRoute,private router: Router) {
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Cuadrantes',
      items: [{
        name: 'Listado',
      }]
    });
  }

  ngOnInit(): void {
    const station = this.route.snapshot.paramMap.get('station');
    const quadrant = this.route.snapshot.paramMap.get('quadrant');

    if (station) {
      this.stationByParam = true;
      this.selectStation = +station;
    }

    if (quadrant) {
      this.selectQuadrant = +quadrant;
    }

    this.loadFilters();
    this.loadQuadrants();
    this.loadQuadrantInfo();
  }

  loadFilters(){
    this.loadingFilters = true;
    combineLatest([
      this.stationService.list(),
    ]).subscribe({
      next: ([stations]) => {
        this.stations = stations.data ?? [];

        this.loadingFilters = false;
      }, error: err => {
        this.loadingFilters = false;
      }
    });
  }

  loadQuadrants(reset: boolean = false){
    if (this.selectStation) {
      this.loadingFilters = true;
      //this.selectQuadrant = null;
      this.stationService.quadrants(this.selectStation).subscribe({
        next: (quadrants) => {
          if (quadrants.data) {
            if (reset) {
              this.selectQuadrant = null;
            }
            this.quadrantInfo = undefined;
            this.quadrants = quadrants.data;
          }
          this.loadingFilters = false;
        },
        error: err => {
          this.loadingFilters = false;
        }
      })
    }
  }

  loadQuadrantInfo(){
    if (this.selectQuadrant && this.selectStation) {
      this.loadingFilters = true;
      this.stationService.quadrantInfo(this.selectStation, this.selectQuadrant, this.month, this.year).subscribe({
        next: (quadrantInfo) => {
          if (quadrantInfo.data){
            this.quadrantInfo = quadrantInfo.data;
            this.getQuadrantLinesBlocked();
          }
          this.loadingFilters = false;
        },
        error: err => {
          this.loadingFilters = false;
        }
      })
    }
  }


  nextMonth() {
    // Incrementa el mes actual
    this.month++;

    // Verifica si el mes excede diciembre
    if (this.month > 12) {
      this.month = 1;  // Reinicia el mes a enero
      this.year++;     // Incrementa el año
    }

    this.loadQuadrantInfo();
  }

  /**
   * Retrocede al mes anterior si no se baja del mes 1.
   */
  prevMonth() {
    // Decrementa el mes actual
    this.month--;

    // Verifica si el mes es menor a enero
    if (this.month < 1) {
      this.month = 12; // Ajusta el mes a diciembre
      this.year--;     // Decrementa el año
    }

    this.loadQuadrantInfo();
  }

  onSelectDay(day: string) {
      this.hoursForm.addDate(day);
  }

  selectSection(section: QuadrantLine){
    this.selectedDates = [];
    if (section.type == 'date') {
      this.selectedDates = section.info;
      this.selectedSection = section;
      this.manualSelectedMode = true;
    } else {
      this.selectedSection = section;
      this.manualSelectedMode = false;
    }
  }

  cancelEdit() {
    this.selectedSection = undefined;
    this.manualSelectedMode = false;
    this.selectedDates = [];
    // this.selectedDays = [];
  }

  onUpdateDates(dates: string[]){
    this.selectedDates = dates;
  }

  hasOverlappingDays(newDays: number[] | string[], existingSections: any[]): boolean {
    return existingSections.some(section => {
      // Omite la sección que está siendo editada si estamos en modo edición
      if (this.selectedSection && section.name === this.selectedSection?.name) {
        return false; // Ignora esta sección y sigue verificando las demás
      }

      // Comprobación de superposición de días
      // @ts-ignore
      return section.info.some((day: number | string) => newDays.includes(day));
    });
  }

  onFormSubmit(data: any){
    if (data.info && data.info.length) {
      data.info = data.info.filter((date: string|number|null) => date !== null);
    }

    if (this.selectedSection) {
      if (this.quadrantInfo && this.selectQuadrant){
        data.hours = this.hoursForm.hours;
        this.stationService.editQuadrantLine(this.selectedSection.id, data).subscribe({
          next: (res) => {
            if (res.data && this.quadrantInfo) {
              let indexOf = this.quadrantInfo.lines.findIndex(line => line.name === this.selectedSection!.name);
              if (indexOf > -1) {
                this.quadrantInfo.lines[indexOf] = res.data;
              }
              this.toastr.success('Editada correctamente')
            }
            this.resetAll();
            this.loadQuadrantInfo();
          },
          error: err => {
            this.toastr.error(err.error.message);
          }
        })

       // let indexOf = this.quadrantInfo.lines.findIndex(line => line.name === this.selectedSection!.name);

        /*this.quadrantInfo[indexOf] = data;*/
        // console.log(indexOf);
       // this.quadrantInfo.lines[indexOf] = data;
      }


    } else {
      if (this.quadrantInfo && this.selectQuadrant){
        if (this.hasOverlappingDays(data.info,  this.quadrantInfo.lines ?? [])) {
          this.toastr.error('Ya tienes una configuración para este día.');
        } else {
          data.hours = this.hoursForm.hours;
          this.stationService.addLineToQuadrant(this.selectQuadrant, data).subscribe({
            next: (res) => {
              if (res.data && this.quadrantInfo) {
                this.quadrantInfo.lines.push(res.data);
                this.toastr.success('Añadida correctamente')
              }
              this.resetAll();
              this.loadQuadrantInfo();
            },
            error: err => {
              this.toastr.error(err.error.message);
            }
          })
          // TODO: PETICIÓN PARA CREAR
          /*this.quadrantInfo.lines.push({
            name: data.name,
            color: data.selectedColor,
            hours: [],
            // @ts-ignore
            quadrant_id: this.selectQuadrant,
            info: data.selectedDays,
            type: 'date'
          })*/
        }
      }
    }
  }

  deleteSection(section: QuadrantLine){
    this.stationService.deleteQuadrantLine(section.id).subscribe({
      next: (res) => {
        if (res.success){
          this.loadQuadrantInfo();
          this.toastr.success('Línea eliminada correctamente');
        }
      },
      error: err => {
        this.toastr.error(err.error.message);
      }
    })
  }

  resetAll() {
    this.hoursForm.resetAll();
    this.selectedSection = undefined;
    this.selectedDates = [];
    this.manualSelectedMode = false;
  }

  getQuadrantLinesBlocked() {
    this.quadrantLinesBlocked = [];
    // Recorre cada día en quadrantInfo.daysInfo
    this.quadrantInfo?.daysInfo.forEach(day => {
      // Verifica si el día tiene state_reduction
      if (day.state_reduction) {
        day.state_reduction.forEach((value, index) => {
// Desestructura el objeto state_reduction para obtener el id
          const { id } = value;

          // Verifica si ya existe un objeto con el mismo id en quadrantLinesBlocked
          const exists = this.quadrantLinesBlocked.some(item => item.id === id);

          // Si el objeto con el id no existe en la lista, lo añade
          if (!exists) {
            this.quadrantLinesBlocked.push(value);
          }
        })
      }
    });

    this.quadrantLinesBlocked.sort((a, b) => {
      // Primero coloca los elementos con quadrant_id
      if (a.quadrant_id && !b.quadrant_id) {
        return -1;
      }
      if (!a.quadrant_id && b.quadrant_id) {
        return 1;
      }
      // Si ambos tienen o no tienen quadrant_id, no cambia el orden
      return 0;
    });

    // Retorna la lista actualizada
    return this.quadrantLinesBlocked;
  }

  onCreate(data: any){
    if (this.selectQuadrant){
      this.stationService.addQuadrantReductionTOQuadrant(this.selectQuadrant, data).subscribe({
        next: (data) => {
          if (data.data) {
            this.quadrantLinesBlocked.push(data.data);
            this.blockedForm.reset();
          }
        },
        error: err => {
          console.log(err);
        }
      })
    }
  }

  goToStation(){
    this.router.navigate(['/dashboard/estaciones/editar', this.selectStation]);
  }

  saveNewMessage(){
    if (this.quadrantInfo) {
      this.stationService.updateQuadrantMessage(this.quadrantInfo.quadrant.id, this.quadrantInfo.quadrant.message).subscribe({
        next: (res) => {
          if (res.success) {
            this.toastr.success(res.message);
          }
        },
        error: err => {
          this.toastr.error(err.error.message);
        }
      })
    }
  }

  protected readonly Math = Math;
}
