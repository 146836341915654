<div>
  <div *ngIf="step1 && step1.registration_number" class="flex flex-col gap-y-6 animate-fade-in">

    <div class="grid grid-cols-3 mb-6">
      <h1 class="text-xl text-start col-span-2">Tu próxima cita: </h1>
      <div (click)="toggleSummary()" class="cursor-pointer flex justify-end">
        <div class="flex rounded-full border-lightGray text-lightGray border justify-center w-6 h-6 text-center items-center hover:shadow-lg duration-300">x</div>
      </div>
    </div>

    <div class="text-sm flex flex-col gap-y-6">
      <div (click)="goStep(1)" class="flex flex-row items-center gap-x-2 cursor-pointer">
        <div appStepperNumber>1
        </div>
        <p class="text-darkGray">{{ step1.registration_number }}</p>
      </div>

      <div *ngIf="step2 && step2.fuel" (click)="goStep(2)" class="flex flex-row items-center gap-x-2 animate-fade-in cursor-pointer">
        <div appStepperNumber>2
        </div>
        <div class="text-darkGray">
          <p *ngIf="step2.service">{{ step2.service.name }}</p>
          <p *ngIf="step2.vehicle">{{ step2.vehicle.name }}</p>
        </div>
      </div>

      <div *ngIf="step3 && step3.station" (click)="goStep(3)" class="flex flex-row items-center gap-x-2 animate-fade-in cursor-pointer">
        <div appStepperNumber>3
        </div>
        <div class="text-darkGray">
          <p *ngIf="step3.state">{{ step3.state.name }}</p>
          <p *ngIf="step3.station">{{ step3.station.name }}</p>
        </div>
      </div>

      <div *ngIf="step4 && step4.date" (click)="goStep(4)" class="flex flex-row items-center gap-x-2 animate-fade-in cursor-pointer">
        <div appStepperNumber>4
        </div>
        <div class="text-darkGray">
          <p>{{ step4.date }}</p>
          <p *ngIf="step4.hour">{{ step4.hour.hour }}h</p>
        </div>
      </div>
      <div *ngIf="step5 && step5.phone_number" (click)="goStep(5)" class="flex flex-row items-center gap-x-2 animate-fade-in cursor-pointer">
        <div appStepperNumber>5
        </div>
        <div class="text-darkGray">
          <p>{{step5.name}}</p>
          <p>{{ step5.email }}</p>
          <p>{{ step5.phone_number }}</p>
        </div>
      </div>

    </div>

  </div>

</div>

