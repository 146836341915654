<div class="w-full flex overflow-hidden">

  <app-dashboard-aside></app-dashboard-aside>

  <div class="w-full h-screen overflow-scroll">
    <app-dashboard-navbar />
    <app-dashboard-safe-zone class="relative">
      <ng-content></ng-content>
    </app-dashboard-safe-zone>

  </div>
</div>
