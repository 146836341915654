import {Component, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {
  selectConfirmed,
  selectCurrentStep,
  selectStep1,
  selectStep2,
  selectStep3,
  selectStep4, selectStep5,
  selectStep6
} from "../../../../../store/reservationForm/stepper.selector";
import {Observable} from "rxjs";
import {Step1, Step2, Step3, Step4, Step5, Step6} from "../../../../../models/Front/ReservationForm";
import {DatePipe, NgIf} from "@angular/common";
import {FrontService} from "../../../../../core/services/front/front.service";
import {LoaderComponent} from "../../../../../shared/components/loader/loader.component";
import {NextButtonDirective} from "../../../../../shared/directives/buttons/next.directive";
import {
  updateConfirmed, updateStep,
  updateStep1,
  updateStep2,
  updateStep3, updateStep4, updateStep5, updateStep6
} from "../../../../../store/reservationForm/stepper.actions";
import {Router, RouterLink} from "@angular/router";
import {Reservation} from "../../../../../models/Reservation";

@Component({
  selector: 'app-step7',
  standalone: true,
  imports: [
    NgIf,
    DatePipe,
    LoaderComponent,
    NextButtonDirective,
    RouterLink
  ],
  templateUrl: './step7.component.html',
  styleUrl: './step7.component.css'
})
export class Step7Component implements OnInit {

  step1$: Observable<Step1 | undefined>;
  step1?: Step1;
  step2$: Observable<Step2 | undefined>;
  step2?: Step2;
  step3$: Observable<Step3 | undefined>;
  step3?: Step3;
  step4$: Observable<Step4 | undefined>;
  step4?:Step4;
  step5$:Observable<Step5 | undefined>;
  step5?:Step5;
  step6$:Observable<Step6 | undefined>;
  step6?:Step6;
  confirmed$:Observable<boolean>;
  confirmed:boolean= false;
  existingReservation?:Reservation;


  loading = false;

  constructor(private store: Store, private frontService: FrontService, private router:Router) {
    this.step1$ = this.store.pipe(select(selectStep1));
    this.step2$ = this.store.pipe(select(selectStep2));
    this.step3$ = this.store.pipe(select(selectStep3));
    this.step4$ = this.store.pipe(select(selectStep4));
    this.step5$ = this.store.pipe(select(selectStep5));
    this.step6$ = this.store.pipe(select(selectStep6));
    this.confirmed$ = this.store.pipe(select(selectConfirmed));
  }

  ngOnInit() {
    this.confirmed$.subscribe(confirmed => {
      this.confirmed = confirmed;
    });
    this.step1$.subscribe(step1 => {
      this.step1 = step1;
    });
    this.step2$.subscribe(step2 => {
      this.step2 = step2;
    });
    this.step3$.subscribe(step3 => {
      this.step3 = step3;
    });
    this.step4$.subscribe(step4 => {
      this.step4 = step4;
    });
    this.step5$.subscribe(step5 => {
      this.step5 = step5;
    })
    this.step6$.subscribe( step6 => {
      this.step6 = step6;
      // console.log(step6);
    })

    this.frontService.getPrice().subscribe({
      next: (reservation: Reservation) => {
        console.log(reservation);
      },
      error: (err) => {
        console.log(err);
      }
    })

  }

  public confirm(){
    if(this.step1 && this.step2 && this.step3 && this.step4 && this.step5 && this.step6){
      let data:any = {
        hour_id:this.step4.hour?.id,
        station_id:this.step3.station?.id,
        service_id:this.step2.service?.id,
        vehicle_id:this.step2.vehicle?.id,
        payment_method_id:this.step6.payment?.id,
        fuel_id:this.step2.fuel?.id,
        registration_number:this.step1.registration_number,
        date:this.step4.date,
        phone:this.step5.phone_number,
        email:this.step5.email,
      };
      this.loading = true;
      this.frontService.createReservation(data).subscribe({
        next: (response) => {
          // console.log(response.data)
          this.existingReservation=response.data;
          this.confirmed = true;
          let confirmed= this.confirmed;
          this.store.dispatch(updateConfirmed({confirmed}))
          this.loading = false;
          if(this.existingReservation){
            this.router.navigate(['/confirm', this.existingReservation.id]);
          }
        },
        error: (error) => {
          this.loading = false;
        }
      })
    }
  }

  public resetStep1(){
    let step1:Step1 = {
      registration_number:'',
    }
    this.store.dispatch(updateStep1({step1}))
  }

  public resetStep2(){

    let step2: Step2 = {
      subStep: 1,
    };
    this.store.dispatch(updateStep2({step2}));

  }

  public resetStep3(){
    let step3: Step3 = {
      subStep: 1,
    };
    this.store.dispatch(updateStep3({step3}));
  }

  public resetStep4(){
    let step4:Step4={
      date:undefined,
      hour:undefined,
    }
    this.store.dispatch(updateStep4({
      step4
    }))
  }

  public resetStep5(){
    let step5:Step5={
      phone_number: '',
      email: '',
      name: '',
    }
    this.store.dispatch(updateStep5({
      step5
    }))
  }

  public resetStep6(){
    let step6:Step6={
      payment:undefined,
    }
    this.store.dispatch(updateStep6({step6}))
  }

  public reset(){
    this.resetStep1();
    this.resetStep2();
    this.resetStep3();
    this.resetStep4();
    this.resetStep5();
    this.resetStep6();
    let step = 1;
    this.store.dispatch(updateStep({step}));
    let confirmed = false;
    this.store.dispatch(updateConfirmed({confirmed}));
  }

}
