import { Component } from '@angular/core';
import {CalendarComponent} from "../calendar/calendar.component";
import {Reservation, ReservationCanceled} from "../../../../models/Reservation";
import {TableHeader} from "../../../../models/Table/TableHeader";
import {TableActions} from "../../../../models/Table/TableActions";
import {Filter} from "../../../../models/Table/Filter";
import {Paginator} from "../../../../models/Paginator";
import {ReservationService} from "../../../../core/services/reservations/reservation.service";
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {VehiclesService} from "../../../../core/services/vehicles/vehicles.service";
import {StationService} from "../../../../core/services/stations/station.service";
import {ServicesService} from "../../../../core/services/services/services.service";
import {ToastrService} from "ngx-toastr";
import {ReservationGesService} from "../../../../core/services/reservations/reservation-ges.service";
import {SortColumn} from "../../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {forkJoin} from "rxjs";
import {DialogModule} from "primeng/dialog";
import {
  ExistingReservationCardComponent
} from "../../../front/components/existing-reservation-card/existing-reservation-card.component";
import {GenericTableComponent} from "../../../../shared/components/generic-table/generic-table.component";
import {NgIf} from "@angular/common";
import {TableFiltersComponent} from "../../../../shared/components/generic-table/table-filters/table-filters.component";
import {
  TableHeaderWrapperComponent
} from "../../../../shared/components/generic-table/table-header-wrapper/table-header-wrapper.component";
import {UserService} from "../../../../core/services/users/user.service";

@Component({
  selector: 'app-canceladas',
  standalone: true,
  imports: [
    CalendarComponent,
    DialogModule,
    ExistingReservationCardComponent,
    GenericTableComponent,
    NgIf,
    TableFiltersComponent,
    TableHeaderWrapperComponent
  ],
  templateUrl: './canceladas.component.html',
  styleUrl: './canceladas.component.css'
})
export class CanceladasComponent {
  perPage: number = 50;
  loading = false;
  loadingFilters = false;
  displayEditDialog: boolean = false;
  selectedReservation?:Reservation;
  editReservation:boolean = true;

  tableHeaders: TableHeader[] = [
    {
      name: 'ID',
      active: true,
      td_type: 'text',
      td_key: 'id',
      sort: false,
    },
    {
      name: 'MATRÍCULA',
      active: true,
      td_type: 'text',
      td_key: 'registration_number',
      sort: false,
    },
    {
      name: 'TELÉFONO',
      active: true,
      td_type: 'text',
      td_key: 'phone',
      sort: false,
    },
    {
      name: 'SERVICIO',
      td_type: 'text',
      active: true,
      td_key: 'service',
      table: 'services',
      td_sub_key: 'name',
      sort: false,
    },
    {
      name: 'TIPO VEHÍCULO',
      td_type: 'text',
      td_key: 'vehicle',
      active: true,
      table: 'vehicles',
      td_sub_key: 'name',
      sort: true,
    },
    {
      name: 'FECHA CITA',
      td_type: 'date',
      active: true,
      td_key: 'date',
      format: 'dd/MM/Y',
      sort: true,
    },
    {
      name: 'HORA',
      td_type: 'text',
      td_key: 'hour',
      active: true,
      sort: true,
    },
    /*{
      name: 'ESTADO',
      active: true,
      td_type: 'boolean-click',
      td_key: 'state',
      sort: false,
    },*/
    {
      name: 'FECHA CANCELACIÓN',
      active: true,
      td_type: 'date',
      td_key: 'cancel_at',
      format: 'dd/MM/Y HH:mm',
      sort: true,
    },
    {
      name: 'OPERADOR',
      td_type: 'text',
      td_key: 'user',
      active: true,
      table: 'users',
      td_sub_key: 'name',
      default: 'WEB',
      sort: false,
    },
    {
      name: 'PROFESIONAL',
      td_type: 'text',
      td_key: 'professional',
      active: true,
      table: 'users',
      td_sub_key: 'name',
      default: '-',
      sort: false,
    },
    {
      name: 'CANCELADA POR',
      td_type: 'text',
      td_key: 'canceled_for',
      active: true,
      table: 'users',
      td_sub_key: 'name',
      default: 'WEB',
      sort: false,
    },
    {
      name: 'FECHA CREACIÓN',
      td_type: 'date',
      active: false,
      td_key: 'created_at',
      format: 'dd/MM/Y HH:mm',
      sort: true,
    },
    {
      name: 'FECHA MODIFCACIÓN',
      td_type: 'date',
      active: false,
      td_key: 'updated_at',
      format: 'dd/MM/Y HH:mm',
      sort: true,
    }
  ]

  tableActions: TableActions[] = [
    /*{
      icon: '<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M10 10l4 4m0 -4l-4 4" /></svg>',
      name: 'Cancelar',
      key: 'cancel',
      color: '#FF0000',
      confirm: true,
      confirmTitle: 'Cancelar reserva',
      confirmSubTitle: '¿Estás seguro de que deseas cancelar esta reserva?',
    },
    {
      icon: '<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>',
      name: 'Editar',
      key: 'edit',
      color: '#FF8A4C',
      confirm: false,
    }*/
  ];

  tableFilters: Filter[] = []

  displayNames: { [key: string]: string } = {
    date: 'Fecha',
    registration_number: 'Término',
    station_id: 'Estación',
    service_id: 'Servicio',
    vehicle_id: 'Vehiculo',
    user_id: 'Operadora',
    professional_id: 'Profesional',
    state: 'Estado',
    'registration_number|phone': "Término",
    cancel_for_id: 'Cancelada por'
  };

  today:Date = new Date();

  reservations: Paginator<ReservationCanceled> | null = null;

  constructor(private reservationService: ReservationService, private breadcrumbService: BreadcrumbService,
              private vehiclesService: VehiclesService, private stationService: StationService,
              private serviceService: ServicesService, private toastr:ToastrService,
              private reservationGesService:ReservationGesService, private userService: UserService
              /*private confirmationService: ConfirmationService*/) {

    this.loadFiltersSortsColumnsFromLocalStorage()
    this.loadReservations();
    this.loadElements();
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Agenda Canceladas',
      items: [{
        name: 'Listado',
      }]
    })
  }

  sorts: SortColumn[] = [];
  filters: ActiveFilter[] = [];

  loadFiltersSortsColumnsFromLocalStorage(){

    // Recuperar y procesar filtros
    let storedFilters = localStorage.getItem('itv_canceled_filters');
    if(storedFilters){
      const parsedFilters = JSON.parse(storedFilters) as ActiveFilter[];

      // Convertir cadenas de texto a objetos Date para filtros de fecha
      this.filters = parsedFilters.map(filter => {
        if (filter.column === 'date' && Array.isArray(filter.value)) {
          return {
            ...filter,
            value: filter.value.map((v: any) => new Date(v)) // Convertir cada valor a Date
          };
        }
        return filter;
      });
      if(!this.filters.some(f=>f.parsed ==='date-arr')){
        this.filters.push(
          {
            column:"date",
            format:"dd/MM/Y",
            operator: "between",
            parsed: "date-arr",
            value:[this.today,this.today]
          }
        )
      }
    } else {
      this.filters.push(
        {
          column:"date",
          format:"dd/MM/Y",
          operator: "between",
          parsed: "date-arr",
          value:[this.today,this.today]
        }
      )
    }

    // Recuperar y procesar órdenes de clasificación
    let storedSorts = localStorage.getItem('itv_canceled_sorts');
    if(storedSorts){
      this.sorts = JSON.parse(storedSorts) as SortColumn[];
    }

    let storedColumns = localStorage.getItem('itv_canceled_columns');
    if(storedColumns){
      this.tableHeaders = JSON.parse(storedColumns) as TableHeader[];
    }
  }

  changeColumns(tableHeaders:TableHeader[]){
    localStorage.setItem('itv_canceled_columns',JSON.stringify(tableHeaders))
  }

  loadReservations(){
    this.loading = true;
    this.reservationService.listCanceled(this.sorts, this.filters, this.perPage).subscribe({
      next: data => {
        if (data.data) {
          this.reservations = data.data;
        } else {
          this.reservations = null;
        }
        this.loading = false;
      },
      error: err => {
        this.loading = false;
      },
    })

  }

  loadElements(){
    this.loadingFilters = true;
    forkJoin({
      stations: this.stationService.list(),
      services: this.serviceService.list(),
      vehicles: this.vehiclesService.list(),
      users: this.userService.listOperadoras(),
      professionals: this.userService.listProfessionals()
    }).subscribe({
      next: (results) => {
        this.tableFilters.push({
          key: 'station_id',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: results.stations.data ?? [],
          placeholder: 'Estación',
          parsed:"relationship-arr",
          relation_column: 'name',
          relation_filter_column: 'id'
        });
        this.tableFilters.push({
          key: 'service_id',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: results.services.data ?? [],
          placeholder: 'Servicio',
          parsed:"relationship-arr",
          relation_column: 'name',
          relation_filter_column: 'id'
        });
        this.tableFilters.push({
          key: 'vehicle_id',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: results.vehicles.data ?? [],
          placeholder: 'Vehículo',
          parsed:"relationship-arr",
          relation_column: 'name',
          relation_filter_column: 'id'
        })
        this.tableFilters.push({
          key: 'registration_number|phone',
          type: 'search',
          operator: 'like',
          placeholder: 'Buscar...'
        })
        this.tableFilters.push({
          key: 'date',
          type: 'date-between',
          parsed: 'date-arr',
          operator: 'between',
          placeholder: 'Fecha'
        });

        /*this.tableFilters.push({
          key: 'state',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: [{name: "Pasadas", state: 1}, {name: "No pasadas", state: 0}],
          placeholder: 'Estado',
          parsed: "array",
          relation_column: 'name',
          relation_filter_column: 'state'
        })*/

        this.tableFilters.push({
          key: 'user_id',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: results.users.data ?? [],
          placeholder: 'Operadoras',
          parsed:"relationship-arr",
          relation_column: 'name',
          relation_filter_column: 'id'
        })

        this.tableFilters.push({
          key: 'cancel_for_id',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: results.users.data ?? [],
          placeholder: 'Cancelada por',
          parsed:"relationship-arr",
          relation_column: 'name',
          relation_filter_column: 'id'
        })

        this.tableFilters.push({
          key: 'professional_id',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: results.professionals.data ?? [],
          placeholder: 'Profesionales',
          parsed:"relationship-arr",
          relation_column: 'name',
          relation_filter_column: 'id'
        })

        this.loadingFilters = false;
      },
      error: (error) => {
        this.loadingFilters = false;
        // Manejar errores aquí
        console.error('Error al llamar a los observables:', error);
      }
    });
  }

  onChangeSorts(sorts: SortColumn[]): void {
    this.sorts = sorts;
    localStorage.setItem('itv_canceled_sorts',JSON.stringify(this.sorts))
    this.loadReservations();
  }


  onChangeFilters(filters: ActiveFilter[]){
    this.filters = filters;
    localStorage.setItem('itv_canceled_filters',JSON.stringify(this.filters))
    this.loadReservations();
  }

  onBooleanClick(data: {header: TableHeader, item: Reservation, index: number}) {
    /*    console.log(data.header);
        console.log(data.item);
        console.log(data.index);*/
    this.reservationService.updateState(data.item.id).subscribe({
      next: (data) => {
        if (data.data) {

        }
      },
      error: error => {
        this.toastr.error('Ha habido un error al editar la reserva.');
      }
    });
  }

  // Método para eliminar la reserva del paginador
  removeReservationFromPaginator(reservationId: number): void {
    if (this.reservations && this.reservations.data) {
      this.reservations.data = this.reservations.data.filter(reservation => reservation.id !== reservationId);
    }
  }

  onClickAction(data: {action: TableActions, item: Reservation, index: number}) {
    if(data.action.key === 'cancel'){
      this.reservationGesService.cancel(data.item.id, null).subscribe({
        next:(response) => {
          if(response.success){
            this.removeReservationFromPaginator(data.item.id);
            this.toastr.success(response.message)
          } else {
            this.toastr.error(response.message);
          }
        },
        error: (err)=>{
          this.toastr.error(err.error.message);
        }
      });
    }
    if(data.action.key === 'edit'){
      this.openEditDialog(data.item);
    }
    /*this.confirmationService.confirm({
      target: data.event.target as EventTarget,
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass:"p-button-danger p-button-text",
      rejectButtonStyleClass:"p-button-text p-button-text",
      acceptIcon:"none",
      rejectIcon:"none",

      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted' });
      },
      reject: () => {
        // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });*/
  }

  openEditDialog(reservation: Reservation) {
    this.selectedReservation = reservation; // Asigna la reserva seleccionada
    this.displayEditDialog = true; // Muestra el diálogo
  }


  onEditConfirm() {
    // Lógica para confirmar los cambios de edición
    this.displayEditDialog = false; // Cierra el diálogo después de confirmar
    this.toastr.success('Cambios guardados correctamente.');
  }


  bulkActions = [
    { label: 'Exportar CSV', key: 'csv' }
  ];

// Método para eliminar elementos
  deleteItems(items: Reservation[]) {
    // Implementar la lógica para eliminar todos los elementos pasados como argumento
    console.log('Eliminar elementos:', items);
  }

  bulkActionClick(key: string) {
    if (key == 'csv') {
      this.exportItems();
    }
  }

// Método para exportar elementos
  exportItems() {

    this.reservationService.listForCsvCanceled(this.sorts, this.filters).subscribe({
      next: data => {
        if (data.data) {
          // Define the headers for the CSV file
          const headers = ['ID', 'MATRÍCULA', 'TELÉFONO', 'EMAIL', 'SERVICIO', 'TIPO VEHÍCULO', 'FECHA CITA', 'HORA', 'FECHA CANCELACIÓN', 'OPERADOR', 'FECHA CREACIÓN'];

          // Create an array to hold each line of the CSV
          const csvData = [];

          // Add the headers as the first line
          csvData.push(headers.join(','));


          // Iterate over each reservation to generate the CSV rows
          data.data.forEach(item => {
            const row = [
              item.id,
              item.registration_number,
              item.phone,
              item.email ? item.email : '',
              item.service?.name,
              item.vehicle?.name,
              this.formatDate(item.date), // Assuming you have a method to format dates
              item.hour,
              item.cancel_at,
              item.user ? item.user.name :'',
              item.created_at,
            ];

            // Join each row's data with commas and add to the CSV data array
            csvData.push(row.join(','));
          });

          // Create a single string with line breaks
          const csvContent = csvData.join('\n');

          // Create a Blob from the string and generate a download link
          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'reservas-canceladas.csv';
          document.body.appendChild(a);
          a.click();

          // Clean up the DOM
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }
        this.loading = false;
      },
      error: err => {
        this.loading = false;
      },
    })

  }

// Helper method to format dates to 'dd/MM/Y'
  formatDate(date: Date | string | undefined): string {
    if (!date) return '';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  }
}
