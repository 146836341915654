import { Directive } from '@angular/core';

@Directive({
  selector: '[appStepperNumber]',
  host: {
    'class': 'rounded-full w-7 h-7 min-w-7 min-h-7 flex justify-center border-2 border-primary text-center text-primary items-center'
  },
  standalone: true
})
export class StepperNumberDirective {

  constructor() { }

}
