<section class="bg-bg2 h-screen animate-fade-in">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
    <a href="#" class="flex items-center mb-8 text-2xl font-semibold text-gray-900 animate-fade-in-down">
      <img class="w-30 mr-2" src="/assets/img/logo.svg" alt="logo">
    </a>
    <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-lg xl:p-0 animate-fade-in-up">
      <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <form class="flex flex-col gap-4">
          <div>
            <label for="email" appAuthFormLabel>Email</label>
            <input [(ngModel)]="email" type="email" name="email" id="email" appAuthFormInput placeholder="example@itevebasa.com" required="">
          </div>
          <div>
            <label for="password" appAuthFormLabel>Contraseña</label>
            <input [(ngModel)]="password" type="password" name="password" id="password" placeholder="••••••••" appAuthFormInput required="">
          </div>
          <div class="flex items-center justify-between">
            <a routerLink="/olvidaste-tu-contrasena" class="text-sm font-medium text-primary hover:underline ">¿Olvidaste tu contraseña?</a>
          </div>
          <button [disabled]="loading" (click)="loginClick()" type="submit" class="w-full disabled:bg-darkGray text-white bg-primary hover:opacity-85 focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
            Entrar
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
