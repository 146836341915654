import {Component, OnInit} from '@angular/core';
import {StationsBlockedComponent} from "../stations-blocked/stations-blocked.component";
import {ActivatedRoute, Router} from "@angular/router";
import {State} from "../../../../../../models/State";
import {StatesService} from "../../../../../../core/services/states/states.service";
import {BreadcrumbService} from "../../../../../../core/services/breadcrumb/breadcrumb.service";
import {StationService} from "../../../../../../core/services/stations/station.service";
import {Station} from "../../../../../../models/Station";
import {NgForOf, NgIf} from "@angular/common";
import {LoaderComponent} from "../../../../../../shared/components/loader/loader.component";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {environment} from "../../../../../../../environments/environment";
import {FormErrorDirective} from "../../../../../../shared/directives/forms/form-error.directive";
import {FormInputDirective} from "../../../../../../shared/directives/forms/form-input.directive";
import {FormLabelDirective} from "../../../../../../shared/directives/forms/form-label.directive";
import {MultiSelectModule} from "primeng/multiselect";
import {forkJoin} from "rxjs";
import {PaymentMethod} from "../../../../../../models/PaymentMethod";
import {InputSwitchModule} from "primeng/inputswitch";
import {ToastrService} from "ngx-toastr";
import {
  QuadrantsStationComponent
} from "../../../../../../shared/components/quadrants-station/quadrants-station.component";
import {Quadrant} from "../../../../../../models/Quadrant";
import {DialogModule} from "primeng/dialog";
import {
  ExistingReservationCardComponent
} from "../../../../../front/components/existing-reservation-card/existing-reservation-card.component";
import {Step2StationComponent} from "../new-station/step2-station/step2-station.component";
import {Step3StationComponent} from "../new-station/step3-station/step3-station.component";
import {StationForm} from "../../../../../../models/StationForm/StationForm";
import {NewStationComponent} from "../new-station/new-station.component";
import {Step2Quadrants} from "../../../../../../models/StationForm/Step2Quadrants";
import {PaymentMethodsService} from "../../../../../../core/services/payment-methods/payment-methods.service";

@Component({
  selector: 'app-edit-station',
  standalone: true,
  imports: [
    StationsBlockedComponent,
    NgIf,
    LoaderComponent,
    FormErrorDirective,
    FormInputDirective,
    FormLabelDirective,
    MultiSelectModule,
    NgForOf,
    ReactiveFormsModule,
    InputSwitchModule,
    QuadrantsStationComponent,
    DialogModule,
    ExistingReservationCardComponent,
    Step2StationComponent,
    Step3StationComponent,
    NewStationComponent
  ],
  templateUrl: './edit-station.component.html',
  styleUrl: './edit-station.component.css'
})
export class EditStationComponent implements OnInit {
  id: string = "";
  station: Station|undefined;

  form: FormGroup;

  states: State[] = [];
  quadrants: Quadrant[] = [];

  loadingStates: boolean = false;
  loadingEdit: boolean = false;

  addQuadrantDialog: boolean = false;

  stationForm!: StationForm;

  paymentMethods: PaymentMethod[] = [];


  /*paymentMethods: PaymentMethod[] = [];*/


  constructor(private route: ActivatedRoute, private stationService: StationService,
              private breadcrumbService: BreadcrumbService, private fb: FormBuilder,
              private stateService: StatesService, private toastr: ToastrService, private router: Router,
              private paymentMethodsService: PaymentMethodsService) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id') || "";
      // Aquí puedes utilizar el valor de 'id' según lo que necesites hacer
    });
    this.form = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      state_id: ['', Validators.required],
      show: [false, Validators.required],
      maps_link: [],
      start: [],
      end: [],
      information: [],
      limitations: [],
      paymentMethods: new FormControl<number[] | null>([],[ Validators.required, Validators.minLength(1)])//[],//[null, Validators.required, Validators.minLength(1)],
      /*paymentMethods: new FormControl<number[] | null>([],[ Validators.required, Validators.minLength(1)])//[],//[null, Validators.required, Validators.minLength(1)],*/
    });
    this.loadData();
  }

  ngOnInit() {
    this.loadStateInfo();
  }

  loadStateInfo(){
    this.stationService.getById(this.id).subscribe({
      next: (data) => {
        if(data.data) {
          console.log(data);
          this.station = data.data;
          this.form.patchValue(data.data);
          let paymentIds: number[] = [];
          data.data.payment_methods.forEach(paymentMethod => {
            paymentIds.push(paymentMethod.id);
          });
          this.form.patchValue({
            paymentMethods: paymentIds
          });
          this.completeBreadcrumb(this.station);
        }
      },
      error: error => {
        console.log(error);
      }
    })
  }

  loadData() {
    // Indica que la carga de datos ha comenzado
    this.loadingStates = true;

    // Usa forkJoin para ejecutar ambos observables simultáneamente
    forkJoin({
      states: this.stateService.list(),
      paymentMethods: this.paymentMethodsService.list()
    }).subscribe({
      next: ({ states, paymentMethods }) => {
        if (states.data) {
          this.states = states.data;
        }
        if (paymentMethods.data) {
          this.paymentMethods = paymentMethods.data;
        }
        /*if (paymentMethods.data) {
          this.paymentMethods = paymentMethods.data;
        }*/
        // Indica que la carga de datos ha terminado
        this.loadingStates = false;
      },
      error: error => {
        // Manejo de errores para ambos observables
        this.loadingStates = false;
      }
    });
  }

  update(){
    if (this.form.valid) {
      this.loadingEdit = true;
      this.stationService.update(this.form.value, this.station!.id).subscribe({
        next: (data) => {
          if (data.data) {
            this.station = data.data;
            this.form.patchValue(data.data);
            this.completeBreadcrumb(this.station);
            this.toastr.success('Estación editada correctamente.');
            this.loadingEdit = false;
          }
        },
        error: error => {
          this.toastr.error('Ha habido un error al editar la estación.');
          this.loadingEdit = false;
        }
      })
      //this.stationFormService.setStep1(this.form.value);
    } else {
      this.form.markAllAsTouched();
    }
  }

  completeBreadcrumb(station: Station){
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Estaciones',
      items: [{
        name: 'Listado',
        url: '/dashboard/estaciones'
      },
        {
          name: 'Editar - '+station.name
        }]
    });
  }

  onAddQuadrants(quadrants: Step2Quadrants[]){
    // console.log(quadrants,+this.id);
    this.stationService.addQuadrants(quadrants,+this.id).subscribe({
      next:(response)=>{
        if(response.data){
          this.toastr.success(response.message)
          // this.resetAll()
          response.data.forEach((item) => {
            // console.log(item);
            this.station?.quadrants.push(item);
          })
          this.addQuadrantDialog = false;
        }
      },
      error:(err)=> {
        console.log(err);
        this.toastr.error(err.error.message);
        this.addQuadrantDialog = false;
      }
    })
  }

  onCLickEdit(quadrant: Quadrant){
    // console.log(quadrant, this.id);
    this.router.navigate(['/dashboard/cuadrantes', this.id, quadrant.id]);
  }
  addQuadrant(){

  }
}
