import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {
  selectCurrentStep,
  selectShowSummary,
  selectStep1,
  selectStep2,
  selectStep3,
  selectStep4,
  selectStep5,
  selectStep6
} from "../../../../store/reservationForm/stepper.selector";
import {Observable} from "rxjs";
import {Step1, Step2, Step3, Step4, Step5, Step6} from "../../../../models/Front/ReservationForm";
import {updateShowSummary, updateStep, updateStep1} from "../../../../store/reservationForm/stepper.actions";
import {Step1Component} from "./step1/step1.component";
import {Step2Component} from "./step2/step2.component";
import {JsonPipe, NgIf} from "@angular/common";
import {Step3Component} from "./step3/step3.component";
import {Step4Component} from "./step4/step4.component";
import {Step5Component} from "./step5/step5.component";
import {Step6Component} from "./step6/step6.component";
import {Step7Component} from "./step7/step7.component";

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [
    Step1Component,
    Step2Component,
    NgIf,
    Step3Component,
    JsonPipe,
    Step4Component,
    Step5Component,
    Step6Component,
    Step7Component
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.css'
})
export class FormComponent implements OnInit{

  showSummary$: Observable<boolean>;
  showSummary:boolean=false;
  currentStep$: Observable<number>;
  currentStep: number = 1;
  step1$: Observable<Step1 |undefined>;
  step1?: Step1;
  step2$: Observable<Step2 | undefined>;
  step2?: Step2;
  step3$: Observable<Step3 | undefined>;
  step3?: Step3;
  step4$: Observable<Step4 | undefined>;
  step4?:Step4;
  step5$:Observable<Step5 | undefined>;
  step5?:Step5;
  step6$:Observable<Step6 | undefined>;
  step6?:Step6;

  constructor(private store: Store, private cd:ChangeDetectorRef) {
    this.showSummary$ = this.store.pipe(select(selectShowSummary));
    this.currentStep$ = this.store.pipe(select(selectCurrentStep));
    this.step1$ = this.store.pipe(select(selectStep1));
    this.step2$ = this.store.pipe(select(selectStep2));
    this.step3$ = this.store.pipe(select(selectStep3));
    this.step4$ = this.store.pipe(select(selectStep4));
    this.step5$ = this.store.pipe(select(selectStep5));
    this.step6$ = this.store.pipe(select(selectStep6));
  }

  ngOnInit() {
    this.showSummary$.subscribe(showSummary => {
      this.showSummary = showSummary;
    })
    this.currentStep$.subscribe(step => {
      this.currentStep = step;
      this.cd.detectChanges();
    });
    this.step1$.subscribe(step1 => {
        this.step1 = step1;
    });
    this.step2$.subscribe(step2 => {
      this.step2 = step2;
    });
    this.step3$.subscribe(step3 => {
      this.step3 = step3;
    });
    this.step4$.subscribe(step4 => {
      this.step4 = step4;
    });
    this.step5$.subscribe(step5 => {
      this.step5 = step5;
    })
    this.step6$.subscribe( step6 => {
      this.step6 = step6;
    })
  }

  public nextStep() {
    if (this.currentStep === 1 && this.step1?.registration_number) {
      //this.showSidebar=true;
      // console.log(this.currentStep)
      this.currentStep++;
      // console.log(this.currentStep);
      let step = this.currentStep;
      this.showSummary = true;
      let showSummary = this.showSummary;
      this.store.dispatch(updateStep({ step }));
      this.store.dispatch(updateShowSummary({showSummary}));

    } else if (this.currentStep === 2 && this.step2?.service && this.step2.vehicle && this.step2.fuel) {
      this.currentStep++;
      let step = this.currentStep;
      this.store.dispatch(updateStep({ step }));
    }/* else if (this.currentStep === 3 && this.step3?.community && this.step3?.centre) {
      this.currentStep++
      let step = this.currentStep;
      this.store.dispatch(updateStep({ step }));
    } else if (this.currentStep === 4 && this.step4?.date && this.step4?.hour) {
      this.currentStep++
      let step = this.currentStep;
      this.store.dispatch(updateStep({ step }));
    } else if (this.currentStep === 5 && this.step5?.phone_number) {
      this.currentStep++;
      let step = this.currentStep;
      this.store.dispatch(updateStep({ step }))
    }
    else if (this.currentStep === 6 && this.step6?.payment) {
      this.showSidebar=false;
      this.currentStep++;
      let step = this.currentStep;
      this.store.dispatch(updateStep({ step }))
    }*/
  }


  public prevStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
      let step = this.currentStep;
      this.store.dispatch(updateStep({ step }));
    }
  }

}
