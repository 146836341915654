<div class="w-full mt-4" *ngIf="step2 && step2.subStep >= 3 && step2.fuel">
  <p class="text-darkGray text-sm mb-2">Lugar</p>

  <div class="grid grid-cols-2 gap-x-6 gap-y-4 mt-2">
    <div *ngIf="(!step3 || (step3 && step3.subStep>=1)) && !loading" class="flex flex-col gap-y-2 animate-fade-in">
      <!--<label class="text-darkGray text-lg">Comunidad</label>-->
      <select appFormInputBlueNoFull [(ngModel)]="selectedState" (change)="onSelectState()">
        <option disabled [ngValue]="undefined">Selecciona una comunidad</option>
        <option *ngFor="let state of states" [ngValue]="state"> {{state.name}} </option>
      </select>
    </div>
    <div class="flex items-center flex-col gap-y-2 my-auto" *ngIf="(!step3 || (step3 && step3.subStep>=1)) && loading">
      <app-loader size="small"></app-loader>
    </div>
    <div *ngIf="step3 &&  step3.subStep>=2 && !loadingStations" class="flex flex-col gap-y-2 animate-fade-in" >
      <!--<label class="text-darkGray text-lg">Centro</label>-->
      <select appFormInputBlueNoFull [(ngModel)]="selectedStation" (change)="onSelectStation()">
        <option disabled [ngValue]="undefined">Selecciona una estación</option>
        <option *ngFor="let station of stations" [ngValue]="station">{{station.name}} - {{station.professional ? 'Profesional' : 'General'}}</option>
      </select>
    </div>
    <div class="flex items-center flex-col gap-y-2 my-auto" *ngIf="step3 &&  step3.subStep>=2 && loadingStations">
      <app-loader size="small"></app-loader>
    </div>
  </div>

  <div class="w-full mt-6 bg-lightGray2 text-darkGray p-4 text-xs" *ngIf="selectedStation && (selectedStation.information || selectedStation.limitations)">
    <p>{{selectedStation.information}}</p>
    <p class="mt-2">{{selectedStation.limitations}}</p>
  </div>

</div>



