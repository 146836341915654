<div class="w-full grid grid-cols-2" *ngIf="station">
  <div>
    <form [formGroup]="form" (ngSubmit)="update()" class="mt-8 animate-fade-in-down">
      <div class="w-full max-w-xl mx-auto flex flex-col gap-4">
        <div>
          <label appFormLabel>Nombre de la estación *</label>
          <input appFormInput type="text" formControlName="name">
          <span *ngIf="form.get('name')?.touched && form.get('name')?.invalid" appFormError>
        El nombre de la estación es requerido
      </span>
        </div>
        <div class="grid grid-cols-2 gap-4 items-center">
          <div>
            <label appFormLabel>Código *</label>
            <input appFormInput type="text" formControlName="code">
            <span *ngIf="form.get('code')?.touched && form.get('code')?.invalid" appFormError>
          El código es requerido
        </span>
          </div>
          <div>
            <app-loader *ngIf="loadingStates"></app-loader>
            <div *ngIf="!loadingStates">
              <label appFormLabel>Comunidad *</label>
              <select appFormInput formControlName="state_id">
                <option disabled value="">Selecciona una comunidad</option>
                <option *ngFor="let state of states" [value]="state.id">{{state.name}}</option>
              </select>
              <span *ngIf="form.get('state_id')?.touched && form.get('state_id')?.invalid" appFormError>
          La comunidad es requerida
        </span>
            </div>
          </div>
        </div>
        <!--<div>
          <label appFormLabel>Métodos de pago</label>
          <p-multiSelect
            [options]="paymentMethods"
            formControlName="paymentMethods"
            optionLabel="name"
            optionValue="id"

            placeholder="" />
          <span *ngIf="form.get('paymentMethods')?.touched && form.get('paymentMethods')?.invalid" appFormError>
          Debes de seleccionar al menos un método de pago
        </span>
        </div>-->
        <div>
          <label appFormLabel>Google Maps Link</label>
          <input appFormInput type="text" formControlName="maps_link">
        </div>
        <div>
          <label appFormLabel>Activa *</label>
          <p-inputSwitch formControlName="show" />
        </div>
        <div>
          <label appFormLabel>Información</label>
          <!--<p-editor formControlName="information" [style]="{ height: '320px' }" />-->
          <textarea rows="5" appFormInput formControlName="information"></textarea>
        </div>
        <div>
          <label appFormLabel>Limitaciones</label>
          <!--<p-editor formControlName="information" [style]="{ height: '320px' }" />-->
          <textarea rows="5" appFormInput formControlName="limitations"></textarea>
        </div>
        <div>
          <label appFormLabel>Móvil</label>
          <div class="grid grid-cols-2 gap-4">
            <div>
              <label appFormLabel>Desde</label>
              <input appFormInput class="pb-1" type="date" formControlName="start">
            </div>
            <div>
              <label appFormLabel>Hasta</label>
              <input appFormInput class="pb-1" type="date" formControlName="end">
            </div>
          </div>
        </div>
        <div class="w-full flex justify-end">
          <button [disabled]="loadingEdit" type="submit" class="bg-primary text-white shadow-sm p-2 px-4 rounded-2xl text-sm font-light flex items-center gap-4">
            Editar <app-loader *ngIf="loadingEdit" size="extra-small"></app-loader>
          </button>
        </div>
      </div>


    </form>


  </div>
  <div class="animate-fade-in-down">
    <app-stations-blocked [station]="station"></app-stations-blocked>
    <div class="flex flex-col p-2 my-4 gap-y-2">
      <div class="flex justify-between">
        <p class="text-primary">Cuadrantes</p>
        <p (click)="addQuadrantDialog=true" class="text-sm text-green hover:scale-105 cursor-pointer duration-300">+ Añadir cuadrante</p>
      </div>
      <app-quadrants-station [showEdit]="true" (onEditClick)="onCLickEdit($event)" *ngFor="let quadrant of station.quadrants" [quadrant]="quadrant"></app-quadrants-station>
    </div>
  </div>
</div>
<app-loader *ngIf="!station" size="large"></app-loader>

<p-dialog [style]="{width:'90rem'}" [(visible)]="addQuadrantDialog" [modal]="true" [closable]="true" header="Añadir cuadrante">
  <!--<app-new-station [quadrants]="station?.quadrants" [editStation]="true"></app-new-station>-->
  <app-step2-station  (onAddQuadrants)="onAddQuadrants($event)" [editStation]="true"></app-step2-station>
</p-dialog>
