import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableHeader} from "../../../models/Table/TableHeader";
import {AuthFormInputDirective} from "../../directives/auth/auth-form-input.directive";
import {InputSwitchModule} from "primeng/inputswitch";
import {DatePipe, DecimalPipe, NgForOf, NgIf, NgSwitch, NgSwitchCase} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SortColumn} from "../../../models/Table/SortColumn";
import {Paginator} from "../../../models/Paginator";
import {NgxSpinnerComponent} from "ngx-spinner";
import {PaginatorComponent} from "../paginator/paginator.component";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";
import {ProgressBarModule} from "primeng/progressbar";
import {LoaderComponent} from "../loader/loader.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {TableActions} from "../../../models/Table/TableActions";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmationService} from "primeng/api";
import {Media} from "../../../models/Media";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'app-generic-table',
  standalone: true,
  imports: [
    AuthFormInputDirective,
    InputSwitchModule,
    NgIf,
    ReactiveFormsModule,
    NgForOf,
    NgSwitch,
    NgSwitchCase,
    DatePipe,
    NgxSpinnerComponent,
    PaginatorComponent,
    ProgressBarModule,
    LoaderComponent,
    FormsModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    TooltipModule,
    DecimalPipe
  ],
  providers: [ConfirmationService],
  templateUrl: './generic-table.component.html',
  styleUrl: './generic-table.component.css'
})
export class GenericTableComponent<T> {
  @Input() tableHeaders!: TableHeader[];
  @Input() tableActions: TableActions[] = [];
  @Input() loading: boolean = false;
  /*@Input() loadingProgress: number = 40;*/
  @Input() items: Paginator<any> | null = null;
  @Input() items2: any[] | null = null;
  @Output() changeSorts = new EventEmitter<SortColumn[]>();
  @Output() clickAction = new EventEmitter<{ action: TableActions, item: T , index: number}>();

  @Input() showDirectAction: boolean = false;

  sortColumns: SortColumn[] = [];
  @Input() filters: ActiveFilter[] = [];

  @Output() onBooleanClick: EventEmitter<{header: TableHeader, item: T, index: number}> = new EventEmitter<{header: TableHeader, item: T, index: number}>();

  constructor(private sanitizer: DomSanitizer, private confirmationService: ConfirmationService) {
  }
  safeIconHtml(action: TableActions): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(action.icon);
  }
  // Método para manejar clics en los encabezados de las columnas
  toggleSort(header: TableHeader): void {
    const existingSort = this.sortColumns.find(sc => sc.column === header.td_key);
    const extraValue = (header.td_sub_key && header.table) ? {table: header.table, column: header.td_sub_key} : null;

    if (existingSort) {
       existingSort.relationship = extraValue;
      // Alternar entre ascendente, descendente y null
      if (existingSort.order === 'asc') {
        existingSort.order = 'desc';
      } else if (existingSort.order === 'desc') {
        existingSort.order = null;
      } else {
        existingSort.order = 'asc';
      }
    } else {
      // Si la columna no está en el array, agregarla con orden ascendente
      // @ts-ignore
      this.sortColumns.push({ column: header.td_key, order: 'asc', relationship: extraValue });
    }

    // Filtrar y mantener solo columnas con orden 'asc' o 'desc'
    this.sortColumns = this.sortColumns.filter(sc => sc.order !== null);

    this.changeSorts.emit(this.sortColumns);
  }


  getSortOrder(column: string): 'asc' | 'desc' | null {
    const sortColumn = this.sortColumns.find(sc => sc.column === column);
    return sortColumn ? sortColumn.order : null;
  }


  booleanClick(header: TableHeader, index: number){
    this.onBooleanClick.emit({header, item: this.items!.data[index], index})
  }


  onChangePage(paginator: Paginator<any>) {
    this.loading = false;
    this.items = paginator;
  }

  onClickAction(action: TableActions, item: T , index: number, event: Event) {
    if (action.confirm) {
      this.confirmationService.confirm({
        header: action.confirmTitle,
        message: action.confirmSubTitle,
        accept: () => {
          this.clickAction.emit({
            action, item, index
          })
        }
      });
    } else {
      this.clickAction.emit({
        action, item, index
      })
    }

  }

  setItemAt(pos: number, item: T) {
    // @ts-ignore
    this.items.data[pos] = item;
  }

  getMediaCollection(medias: Media[], collection: string
  ) {
    console.log(collection);
    let image = '/assets/img/not-found.png';
    medias.forEach(media => {
      if (media.collection_name == collection) {
        image = media.original_url;
      }
    })

    return image;

  }
}
