<nav class="w-full sticky top-0 border-b border-gray-600 p-4 flex justify-between items-center px-8 py-4 bg-white z-10">
  <div class="flex gap-4">
    <button class="hidden md:block" (click)="asideService.toggleIconsFullMenu()">
      <svg *ngIf="asideState.type == 'full'"  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevrons-left"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M11 7l-5 5l5 5" /><path d="M17 7l-5 5l5 5" /></svg>
      <svg *ngIf="asideState.type == 'icons'" xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevrons-right"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7l5 5l-5 5" /><path d="M13 7l5 5l-5 5" /></svg>
    </button>
    <button (click)="asideService.openMobileMenu()" class="block md:hidden">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-menu-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 6l16 0" /><path d="M4 12l16 0" /><path d="M4 18l16 0" /></svg>
    </button>
    <div class="flex flex-col justify-start items-start gap-0 animate-fade-in" *ngIf="breadcrumbs">
      <span class="font-[400] text-darkGray">{{ breadcrumbs.display_name }}</span>
      <div>
        <ng-container *ngFor="let item of breadcrumbs.items; let last = last">
          <a *ngIf="item.url" [routerLink]="item.url"   class="text-xs text-lightGray hover:text-darkGray">{{ item.name }}</a>
          <span *ngIf="!item.url"   class="text-xs text-lightGray ">{{ item.name }}</span>
          <span class="text-xs text-lightGray" *ngIf="!last"> - </span>
        </ng-container>

      </div>
    </div>
  </div>

  <div class="flex items-center justify-end gap-4" *ngIf="user">

    <div class="flex gap-2 items-center justify-center">
      <a href="#" onclick="doGTranslate('es|es');return false;" title="Español" id="español" class="glink nturl notranslate"><img class="w-5" src="/assets/img/es.svg" alt="Español"/></a>
      <a href="#" onclick="doGTranslate('es|ca');return false;" title="Catalá" id="catala" class="glink nturl notranslate"><img class="w-5"  src="/assets/img/ca.png" alt="Catalá"/></a>
    </div>


    <span class="hidden md:block text-darkGray text-xs animate-fade-in">{{ user.profile.name }}</span>
    <svg   xmlns="http://www.w3.org/2000/svg"  class="hidden md:block w-6 h-6 stroke-darkGray animate-fade-in"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" /></svg>
    <img [alt]="user.profile.name" ngSrc="https://ui-avatars.com/api/?name={{user.profile.name.replace(' ', '+')}}&background=168FFB&color=fff&size=128" class="object-cover w-10 h-10 rounded-full animate-fade-in"
         height="10" width="10"/>
    <button class="bg-green hover:bg-green2 text-white hover:text-green border hover:border-green p-2 rounded-full" (click)="logOut()">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-logout"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" /><path d="M9 12h12l-3 -3" /><path d="M18 15l3 -3" /></svg>
    </button>
    <!-- Dropdown menu -->
    <!--<div id="dropdownAvatar" class="z-10 hidden bg-white divide-y divide-lightGray2 rounded-lg shadow w-auto">
      <div class="px-4 py-3 text-sm text-darkGray dark:text-white">
        <div>{{ user.profile.name }}</div>
        <div class="font-medium truncate">{{ user.profile.email }}</div>
      </div>
      <div>
        <a (click)="logOut()" class="cursor-pointer rounded-b-lg block px-4 py-2 text-sm text-darkGray hover:bg-lightGray2">Cerrar sesión</a>
      </div>
    </div>-->
  </div>
</nav>
